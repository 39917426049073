import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import FinalizeRegistrationForm from './FinalizeRegistrationForm';
import { useLocation } from 'react-router-dom';
import { finalizeRegistration } from 'store/actions/UserActions';

function FinalizeRegistrationPage() {
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    data => dispatch(finalizeRegistration({ ...data, adminToken })),
    [dispatch]
  );

  const useQuery = () => new URLSearchParams(useLocation().search);

  const adminToken = useQuery().get('token');
  const email = useQuery().get('email');

  return (
    <div>
      <FinalizeRegistrationForm onSubmit={handleSubmit} email={email} />
    </div>
  );
}

export default FinalizeRegistrationPage;
