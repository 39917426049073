import {
  USERS_GET,
  USERS_SET,
  USER_GET,
  USER_SET,
  USER_UPDATE,
  USER_UPDATE_SET,
  USER_DELETE,
  USER_DELETE_SET,
  USER_LOADER_SET,
  USER_CREATE,
  USER_CREATE_SET,
  USER_FINALIZE_REGISTRATION,
  USER_ERROR,
  USER_UNIQUE_EMAIL_ERROR,
  ADMINS_GET,
  ADMINS_SET
} from '../actions/ActionTypes';

export const getUsers = payload => ({
  type: USERS_GET,
  payload
});

export const setUsers = payload => ({
  type: USERS_SET,
  payload
});

export const getAdmins = payload => ({
  type: ADMINS_GET,
  payload
});

export const setAdmins = payload => ({
  type: ADMINS_SET,
  payload
});

export const getUser = payload => ({
  type: USER_GET,
  payload
});

export const setUser = payload => ({
  type: USER_SET,
  payload
});

export const setUserLoader = payload => ({
  type: USER_LOADER_SET,
  payload
});

export const updateUser = payload => ({
  type: USER_UPDATE,
  payload
});

export const setUpdateUser = payload => ({
  type: USER_UPDATE_SET,
  payload
});

export const deleteUser = payload => ({
  type: USER_DELETE,
  payload
});

export const setDeleteUser = payload => ({
  type: USER_DELETE_SET,
  payload
});

export const createUser = payload => ({
  type: USER_CREATE,
  payload
});

export const setCreateUser = payload => ({
  type: USER_CREATE_SET,
  payload
});

export const finalizeRegistration = payload => ({
  type: USER_FINALIZE_REGISTRATION,
  payload
});

export const userError = payload => ({
  type: USER_ERROR,
  payload
});

export const userUniqueEmailError = payload => ({
  type: USER_UNIQUE_EMAIL_ERROR,
  payload
});
