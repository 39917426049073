import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  getMaternityHospitals,
  deleteMaternityHospital,
  activateDeactivateMaternityHospitals,
  setMaternityHospitalsOrderBy
} from 'store/actions/MaternityHospitalActions';
import {
  makeSelectMaternityHospitals,
  makeSelectMaternityHospitalsPagination,
  makeSelectMaternityHospitalsOrderBy,
  makeSelectMaternityHospitalsLoader
} from 'store/selectors/MaternityHospitalSelector';
import MaternityHospitalsTable from 'components/MaternityHospitalsTable';
import messages from 'containers/MaternityHospitalsPage/messages';
import { MATERNITY_HOSPITALS_CREATE } from 'routes';
import { IoIosSearch } from 'react-icons/io';
import ConfirmationModal from 'components/_shared/ConfirmationModal';
import useDebounce from 'utils/useDebounce';
import { DEBOUNCE_TIME } from 'utils/constants';
import { MATERNITY_HOSPITAL_OPTIONS } from 'utils/sortOptions';
import FilterSelect from 'components/_shared/FilterSelect';

const MaternityHospitalsPage = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const maternityHospitals = useSelector(makeSelectMaternityHospitals());
  const pagination = useSelector(makeSelectMaternityHospitalsPagination());
  const orderBy = useSelector(makeSelectMaternityHospitalsOrderBy());
  const loading = useSelector(makeSelectMaternityHospitalsLoader());

  const onConfirm = () => {
    dispatch(
      deleteMaternityHospital({
        id: selectedId,
        page: pagination.currentPage,
        orderBy,
        search: searchValue
      })
    );
    setIsOpen(false);
  };

  const onItemDelete = id => {
    setIsOpen(true);
    setSelectedId(id);
  };

  const onCheck = (id, active) => {
    dispatch(activateDeactivateMaternityHospitals({ id, active }));
  };

  const fetchMaternityHospitals = useCallback(
    ({ page, orderBy, searchValue }) =>
      dispatch(
        getMaternityHospitals({
          page,
          orderBy,
          searchValue
        })
      ),
    [dispatch]
  );

  const handleOrderBy = ({ target }) => {
    dispatch(setMaternityHospitalsOrderBy(target.value));
    document.getElementsByClassName('js-maternity-hospitals')[0].scrollTop = 0;
  };

  const onLoadMore = () =>
    fetchMaternityHospitals({
      page: pagination.currentPage + 1,
      orderBy,
      searchValue
    });

  const onSearchChange = event => setSearchValue(event.target.value);

  const debouncedSearchTerm = useDebounce(searchValue, DEBOUNCE_TIME);

  useEffect(() => {
    fetchMaternityHospitals({
      page: 1,
      orderBy,
      searchValue
    });
  }, [fetchMaternityHospitals, debouncedSearchTerm, orderBy]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section>
      <header className="c-section-header">
        <h1 className="c-section-title">
          {formatMessage(messages.maternityHospitalHeaderTitle)}
        </h1>
        <div className="c-section-actions">
          <FilterSelect
            orderBy={orderBy}
            handleOrderBy={handleOrderBy}
            options={MATERNITY_HOSPITAL_OPTIONS}
          />

          <div className="c-form-group c-search-field c-input-group u-gap-left--sm">
            <input
              type="text"
              className="c-form-control"
              value={searchValue}
              onChange={onSearchChange}
            />
            <span className="c-input-group__addon">
              <IoIosSearch />
            </span>
          </div>

          <Link
            className="c-btn c-btn--primary u-gap-left"
            to={MATERNITY_HOSPITALS_CREATE}
          >
            {formatMessage(messages.createMaternityHospitalButton)}
          </Link>
        </div>
      </header>
      <MaternityHospitalsTable
        maternityHospitals={maternityHospitals}
        onItemDelete={onItemDelete}
        onCheck={onCheck}
        onLoadMore={onLoadMore}
        hasMore={pagination.hasMore}
        total={pagination.total}
        loading={loading}
      />
      <ConfirmationModal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        onConfirm={onConfirm}
      />
    </section>
  );
};

export default MaternityHospitalsPage;
