import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import messages from 'containers/CategoriesPage/messages';
import { CATEGORIES_SINGLE } from 'routes';

const CategoriesTableItem = ({ item, onItemDelete }) => {
  const { formatMessage } = useIntl();

  return (
    <tr>
      <td>
        <Link to={`${CATEGORIES_SINGLE.replace(':id', item.id)}`}>
          {item.title}
        </Link>
      </td>
      <td className="u-text-center">
        <button
          className="c-btn c-btn--sm"
          onClick={() => onItemDelete(item.id)}
        >
          {formatMessage(messages.deleteCategoryButton)}
        </button>
      </td>
    </tr>
  );
};
export default CategoriesTableItem;
