import { defineMessages } from 'react-intl';

export const scope = 'maternity_hospitals_reviews_page';

export default defineMessages({
  headerTitle: {
    id: `${scope}.header.title`,
    defaultMessage: 'Recenzije porodilišta'
  },
  headerAuthor: {
    id: `${scope}.header.author`,
    defaultMessage: 'Autor recenzije'
  },
  headerHospital: {
    id: `${scope}.header.hospital`,
    defaultMessage: 'Porodilište'
  },
  headerDate: {
    id: `${scope}.header.date`,
    defaultMessage: 'Datum kreiranja'
  },
  headerActive: {
    id: `${scope}.header.title`,
    defaultMessage: 'Prikazati'
  },
  selectReviewCategory: {
    id: `${scope}.header.reviewCategory`,
    defaultMessage: 'Odaberi kategoriju recenzije'
  },
  errorFetchingData: {
    id: `${scope}.errorFetchingData`,
    defaultMessage: 'Došlo je do greške u dobavljanju podataka'
  },
  userReviewsError: {
    id: `${scope}.userReviews.error`,
    defaultMessage: 'Došlo je do greške.'
  },
  deleteReviewCategory: {
    id: `${scope}.header.deleteReviewCategory`,
    defaultMessage: 'Obriši'
  },
  actionsReviewCategory: {
    id: `${scope}.header.actions`,
    defaultMessage: 'Akcije'
  },
  deleteReviewCategoryError: {
    id: `${scope}.header.deleteError`,
    defaultMessage:
      'Došlo je do greške prilikom brisanja recenzije. Pokušajte ponovo kasnije.'
  },
  reviewUserNameTitle: {
    id: `${scope}.userReviews.user.title`,
    defaultMessage: 'Korisnik: '
  },
  reviewCategoryTitle: {
    id: `${scope}.userReviews.category.title`,
    defaultMessage: 'Kategorija: '
  },
  reviewCategoryHospital: {
    id: `${scope}.userReviews.hospital.title`,
    defaultMessage: 'Porodilište: '
  },
  reviewCommentTitle: {
    id: `${scope}.userReviews.comment.title`,
    defaultMessage: 'Komentar: '
  },
  reviewNoCommentTitle: {
    id: `${scope}.userReviews.comment.emptyTitle`,
    defaultMessage: 'Nema komentara na ovoj recenziji.'
  },
  reviewLikesTitle: {
    id: `${scope}.userReviews.likes.title`,
    defaultMessage: 'Sviđanja komentara: '
  },
  reviewDislikesTitle: {
    id: `${scope}.userReviews.dislikes.title`,
    defaultMessage: 'Nesviđanja komentara: '
  },
  reviewDateTimeTitle: {
    id: `${scope}.userReviews.dateTime.title`,
    defaultMessage: 'Datum i vreme kreiranja recenzije: '
  },
  reviewPhotoTitle: {
    id: `${scope}.userReviews.photo.title`,
    defaultMessage: 'Slika: '
  },
  reviewNoPhotoTitle: {
    id: `${scope}.userReviews.noPhoto.title`,
    defaultMessage: 'Bez slike'
  },
  reviewGradesTitle: {
    id: `${scope}.userReviews.grades.title`,
    defaultMessage: 'Ocene: '
  },
  reviewCommentMinLengthMessage: {
    id: `${scope}.userReviews.comment.length`,
    defaultMessage: 'Komentar mora sadržati minimalno 5 karaktera.'
  },
  changeCommentBtnTitle: {
    id: `${scope}.userReviews.comment.changeBtn`,
    defaultMessage: 'Promeni'
  },
  deletePhotoBtnTitle: {
    id: `${scope}.userReviews.comment.deletePhotoBtn`,
    defaultMessage: 'Obriši sliku'
  },
  deletePhotoConfirm: {
    id: `${scope}.userReviews.comment.deletePhotoConfirm`,
    defaultMessage: 'Jeste li sigurni da želite da obrišete sliku?'
  },
  reviewCommentApplyBtn: {
    id: `${scope}.userReviews.comment.applyBtn`,
    defaultMessage: 'Primeni'
  },
  reviewUserHeader: {
    id: `${scope}.userReviews.header.userTitle`,
    defaultMessage: 'Recenzije korisnika {userName}'
  },
  backToReviewsBtn: {
    id: `${scope}.userReviews.header.backBtn`,
    defaultMessage: 'Nazad'
  }
});
