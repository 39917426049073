import {
  ARTICLES_GET,
  ARTICLES_SET,
  ARTICLES_ORDER_BY_SET,
  ARTICLES_AT_CURRENT_PAGE_SET,
  ARTICLE_LOADER_SET,
  ARTICLE_DELETE,
  ARTICLE_DELETE_SET,
  ARTICLE_TOGGLE,
  ARTICLE_TOGGLE_SET,
  ARTICLE_CREATE,
  ARTICLE_CREATE_SET,
  ARTICLE_UPDATE,
  ARTICLE_UPDATE_SET,
  ARTICLE_ITEM_GET,
  ARTICLE_ITEM_SET,
  SET_INITIAL_ARTICLE_CATEGORY,
  DISPATCH_SET_INITIAL_ARTICLE_CATEGORY
} from './ActionTypes';

export const getArticles = payload => ({
  type: ARTICLES_GET,
  payload
});

export const setArticles = payload => ({
  type: ARTICLES_SET,
  payload
});

export const setArticleOrderBy = payload => ({
  type: ARTICLES_ORDER_BY_SET,
  payload
});

export const setArticlesAtCurrentPage = payload => ({
  type: ARTICLES_AT_CURRENT_PAGE_SET,
  payload
});

export const setArticleLoader = payload => ({
  type: ARTICLE_LOADER_SET,
  payload
});

export const deleteArticle = payload => ({
  type: ARTICLE_DELETE,
  payload
});

export const setDeleteArticle = payload => ({
  type: ARTICLE_DELETE_SET,
  payload
});

export const toggleArticle = payload => ({
  type: ARTICLE_TOGGLE,
  payload
});

export const setToggleArticle = payload => ({
  type: ARTICLE_TOGGLE_SET,
  payload
});

export const createArticle = payload => ({
  type: ARTICLE_CREATE,
  payload
});

export const setCreateArticle = payload => ({
  type: ARTICLE_CREATE_SET,
  payload
});

export const updateArticle = payload => ({
  type: ARTICLE_UPDATE,
  payload
});

export const setUpdateArticle = payload => ({
  type: ARTICLE_UPDATE_SET,
  payload
});

export const getArticleItem = payload => ({
  type: ARTICLE_ITEM_GET,
  payload
});

export const setArticleItem = payload => ({
  type: ARTICLE_ITEM_SET,
  payload
});

export const setInitialArticleCategory = payload => ({
  type: SET_INITIAL_ARTICLE_CATEGORY,
  payload
});

export const dispatchSetInitialArticleCategory = payload => ({
  type: DISPATCH_SET_INITIAL_ARTICLE_CATEGORY,
  payload
});
