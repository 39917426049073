import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import messages from 'containers/AdminsPage/messages';
import globalMessages from 'messages';

import Loader from 'components/_shared/Loader';
import UsersTableItem from './UsersTableItem';

const UsersTable = ({ users, onItemDelete, loading, mailingListToggled }) => {
  const { formatMessage } = useIntl();
  const location = useLocation();

  const renderItems = () =>
    users.map(user => (
      <UsersTableItem
        mailingListToggled={mailingListToggled}
        key={'user_' + user.id}
        item={user}
        onItemDelete={onItemDelete}
        users={users}
      />
    ));

  return (
    <div className="c-table-scroll js-maternity-hospitals">
      <table className="c-table">
        <thead>
          <tr>
            <th>
              {location.pathname.includes('admins')
                ? formatMessage(messages.tableHeaderAdmins)
                : formatMessage(messages.tableHeaderUsers)}
            </th>

            {mailingListToggled ? (
              <>
                <th>{formatMessage(messages.dateCreatedLabel)}</th>
                <th className="is-short u-text-center">
                  {formatMessage(messages.emailLabel)}
                </th>
              </>
            ) : (
              <th className="is-short u-text-center">
                {formatMessage(messages.tableHeaderActions)}
              </th>
            )}
          </tr>
        </thead>
        <tbody>{renderItems()}</tbody>
      </table>
      <Loader
        active={loading}
        message={formatMessage(globalMessages.tableDataLoading)}
        className="u-gap-vert u-text-center"
      />
    </div>
  );
};

export default UsersTable;
