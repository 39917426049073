import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Link } from 'react-router-dom';
import SwitchField from 'components/_shared/SwitchField';

import messages from 'containers/MaternityHospitalQuestionsPage/messages';
import { MATERNITY_HOSPITAL_UPDATE_QUESTION } from 'routes';

import { useDispatch } from 'react-redux';
import {
  updateMaternityHospitalQuestionActive,
  updateMaternityHospitalQuestionFilter
} from 'store/actions/MaternityHospitalQuestionActions';

const QuestionTableItem = ({ item, onItemDelete }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const handleCheckActive = useCallback(
    ({ id, category, active }) => {
      dispatch(
        updateMaternityHospitalQuestionActive({ id, category, active: !active })
      );
    },
    [item, dispatch]
  );

  const handleCheckFilter = useCallback(
    ({ id, category, is_filter }) => {
      dispatch(
        updateMaternityHospitalQuestionFilter({
          id,
          category,
          is_filter: !is_filter
        })
      );
    },
    [item, dispatch]
  );

  useEffect(() => {
    if (!item.active && item.is_filter) handleCheckFilter(item);
  }, [item.active, item.is_filter]);

  return (
    <tr>
      <td>
        <Link to={MATERNITY_HOSPITAL_UPDATE_QUESTION.replace(':id', item.id)}>
          {item.question}
        </Link>
      </td>
      <td>
        <SwitchField
          id={`${item.id}-active`}
          checked={item.active}
          onChange={() => handleCheckActive(item)}
        />
      </td>
      <td className="u-text-center">
        <button
          className="c-btn c-btn--sm"
          onClick={() => onItemDelete(item.id)}
        >
          {formatMessage(messages.deleteQuestionButton)}
        </button>
      </td>
      {item.answer_type === 'boolean' && (
        <td>
          <SwitchField
            id={`${item.id}-filter`}
            checked={item.is_filter}
            onChange={() => handleCheckFilter(item)}
            disabled={!item.active}
          />
        </td>
      )}
    </tr>
  );
};
export default QuestionTableItem;
