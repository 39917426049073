import { STRING, BOOLEAN } from './constants';

const parseQuestionTypeToInputType = type => {
  switch (type) {
    case STRING:
      return 'text';
    case BOOLEAN:
      return 'checkbox';
    default:
      return 'number';
  }
};

export const parseQuestionTypeToDefaultValue = type =>
  type === BOOLEAN ? true : '';

export default parseQuestionTypeToInputType;
