import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import CreateCategoryForm from './CreateCategoryForm';
import {
  createCategory,
  updateCategory,
  getCategory
} from 'store/actions/CategoryActions';
import { makeSelectCategory } from 'store/selectors/CategorySelectors';

function CreateCategoryPage() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isUpdate = id || false;
  const categoryItem = useSelector(makeSelectCategory());

  const submitCreateCategoryForm = useCallback(
    data => dispatch(id ? updateCategory(data) : createCategory(data)),
    [dispatch, id]
  );

  useEffect(() => {
    if (id) {
      dispatch(getCategory(id));
    }
  }, []);

  return (
    <div>
      <CreateCategoryForm
        onSubmit={submitCreateCategoryForm}
        item={categoryItem}
        isUpdate={isUpdate}
      />
    </div>
  );
}

export default CreateCategoryPage;
