import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import './GoogleComponent.scss';

const STATUS_OK = 'OK';
const STATUS_NOT_OK = 'NOT_OK';
const STATUS_REQUEST_DENIED = 'REQUEST_DENIED';
const MESSAGE_REQUEST_DENIED = 'Request Denied';
const MESSAGE_NO_RESULTS = 'NO Result Found';
const MESSAGE_ERROR = 'NO Result Found';
const MESSAGE_COORDINATES_ERROR = 'Coordinates return false by props';

const GoogleComponent = ({
  language,
  country,
  types,
  placeholder,
  coordinates,
  locationBoxStyle,
  locationListStyle,
  onChange,
  isUpdate,
  existingCity
}) => {
  const [collection, setCollection] = useState(null);
  const [collectionShow, setCollectionShow] = useState(false);
  const [place, setPlace] = useState('');
  const [liStyle, setLiStyle] = useState('');
  const [returnData, setReturnData] = useState({});

  let wrapperRef = useRef(null);

  const initialize = async () => {
    await setLiStyle(locationListStyle || 'style-list');

    document.addEventListener('mousedown', e => handleClickOutside(e));
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    return () => {
      document.removeEventListener('mousedown', e => handleClickOutside(e));
    };
  }, []);

  useEffect(() => {
    if (existingCity && isUpdate) {
      setPlace(existingCity);
    }
  }, [existingCity]);

  const handleClickOutside = event => {
    if (
      event.target.id !== 'googleInputFieldID' &&
      event.target.tagName.toLowerCase() !== 'li' &&
      event.composedPath()[1].id !== 'unorderedListID'
    ) {
      setCollectionShow(false);
    }

    if (wrapperRef && !wrapperRef?.contains?.(event.target)) {
      setCollectionShow(false);
    }
  };
  const getInfo = param => {
    const child = [];

    const displaySuggestions = (predictions, status) => {
      if (status === STATUS_OK && predictions.length > 0) {
        predictions.forEach(prediction =>
          child.push(
            <li
              key={prediction.place_id}
              className={liStyle}
              onClick={() => arrangeList(prediction.description)}
            >
              {prediction.description}
            </li>
          )
        );
      } else if (status === STATUS_REQUEST_DENIED) {
        child.push(<li className={liStyle}>{MESSAGE_REQUEST_DENIED}</li>);
      } else {
        child.push(<li className={liStyle}>{MESSAGE_NO_RESULTS}</li>);
      }
      const coll = (
        <ul className="style-unordered-list" id="unorderedListID">
          {child}
        </ul>
      );
      setCollection(coll);
      setCollectionShow(true);
    };

    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions(
      {
        input: param,
        types: [types],
        componentRestrictions: { country },
        language
      },
      displaySuggestions
    );
  };

  const getCoordinates = address => {
    const service = new window.google.maps.Geocoder();
    return service.geocode({
      address
    });
  };

  const arrangeList = pickedPlace => {
    returnDataFunc(pickedPlace);
    setCollectionShow(false);
  };

  const arrangeValue = item => {
    debouncedGetInfo(item);
    setPlace(item);
    setReturnData({ place: item, coordinates: '' });
  };

  const returnDataFunc = async pickedPlace => {
    setPlace(pickedPlace);

    let location = await getCoordinates(pickedPlace);

    const status = location ? STATUS_OK : STATUS_NOT_OK;
    location.status = status;

    let coords;
    if (coordinates) {
      if (location.status === STATUS_OK) {
        coords = location.results[0].geometry.location;
      } else {
        coords = MESSAGE_ERROR;
      }
    } else {
      coords = MESSAGE_COORDINATES_ERROR;
    }

    setReturnData({ place: pickedPlace, coordinates: coords });
  };

  useEffect(() => {
    onChange(returnData);
  }, [returnData]);

  const debouncedGetInfo = useCallback(
    debounce(item => {
      getInfo(item);
    }, 350),
    []
  );

  return (
    <div
      ref={node => {
        wrapperRef = node;
      }}
      className="location-box-cover"
    >
      <input
        id="googleInputFieldID"
        type="text"
        className={locationBoxStyle || 'location-box'}
        onChange={e => arrangeValue(e.target.value)}
        placeholder={placeholder}
        value={place}
      />
      {!collectionShow ? null : (
        <div className="google-covert">{collection}</div>
      )}
    </div>
  );
};

export default GoogleComponent;

GoogleComponent.propTypes = {
  language: PropTypes.string,
  country: PropTypes.string,
  types: PropTypes.string,
  coordinates: PropTypes.bool,
  placeholder: PropTypes.string,
  locationBoxStyle: PropTypes.string,
  locationListStyle: PropTypes.string,
  onChange: PropTypes.func,
  isUpdate: PropTypes.bool,
  existingCity: PropTypes.string
};
