import {
  REVIEW_CATEGORIES_GET_REQUEST,
  REVIEW_CATEGORIES_GET_FAILURE,
  REVIEW_CATEGORIES_SET,
  REVIEW_CATEGORIES_LOADER,
  REVIEW_CATEGORIES_DELETE_REQUEST,
  REVIEW_CATEGORIES_SET_AFTER_DELETE,
  REVIEW_CATEGORIES_DELETE_FAILURE,
  REVIEW_SUBCATEGORIES_DELETE_REQUEST,
  REVIEW_SUBCATEGORIES_SET_AFTER_DELETE,
  REVIEW_SUBCATEGORIES_DELETE_FAILURE,
  REVIEW_CATEGORY_CREATE,
  REVIEW_CATEGORY_CREATE_ERROR_SET,
  LABOR_TYPES_REQUEST,
  LABOR_TYPES_SET,
  REVIEW_SUBCATEGORY_CREATE_REQUEST,
  REVIEW_SUBCATEGORY_CREATE_SUCCESS,
  REVIEW_SUBCATEGORY_CREATE_FAILURE,
  REVIEW_CATEGORIES_GET_SINGLE,
  REVIEW_CATEGORIES_SET_SINGLE,
  REVIEW_CATEGORIES_EDIT_SINGLE,
  REVIEW_CATEGORIES_CHANGE_ORDER,
  REVIEW_CATEGORIES_UPDATE_ORDER,
  REVIEW_CATEGORIES_UPDATE_ORDER_SET,
  REVIEW_CATEGORIES_CHANGE_ORDER_ERROR_SET,
  REVIEW_CATEGORY_ADD_SUBCATEGORY,
  REVIEW_SUBCATEGORIES_UPDATE_ORDER_SET,
  REVIEW_SUBCATEGORIES_CHANGE_ORDER,
  REVIEW_SUBCATEGORIES_CHANGE_ORDER_ERROR_SET,
  REVIEW_SUBCATEGORY_SINGLE_REQUEST,
  REVIEW_SUBCATEGORY_SINGLE_FAILURE,
  REVIEW_SUBCATEGORY_SINGLE_SET,
  REVIEW_SUBCATEGORY_UPDATE_FAILURE,
  REVIEW_SUBCATEGORY_UPDATE_REQUEST,
  REVIEW_SUBCATEGORY_UPDATE_SUCCESS,
  SET_IS_SUBCATEGORIES_CHANGED,
  SET_IS_CATEGORIES_CHANGED
} from './ActionTypes';

export const getReviewCategoriesRequest = () => ({
  type: REVIEW_CATEGORIES_GET_REQUEST
});

export const getReviewCategoriesFailure = payload => ({
  type: REVIEW_CATEGORIES_GET_FAILURE,
  payload
});

export const setReviewCategories = payload => ({
  type: REVIEW_CATEGORIES_SET,
  payload
});

export const setReviewCategoriesLoader = payload => ({
  type: REVIEW_CATEGORIES_LOADER,
  payload
});

export const deleteRequestReviewCategory = payload => ({
  type: REVIEW_CATEGORIES_DELETE_REQUEST,
  payload
});

export const deleteRequestReviewCategoryFailure = payload => ({
  type: REVIEW_CATEGORIES_DELETE_FAILURE,
  payload
});

export const setReviewCategoriesAfterDelete = payload => ({
  type: REVIEW_CATEGORIES_SET_AFTER_DELETE,
  payload
});

export const deleteRequestReviewSubcategory = payload => ({
  type: REVIEW_SUBCATEGORIES_DELETE_REQUEST,
  payload
});

export const setReviewSubcategoriesAfterDelete = payload => ({
  type: REVIEW_SUBCATEGORIES_SET_AFTER_DELETE,
  payload
});

export const deleteRequestReviewSubcategoryFailure = payload => ({
  type: REVIEW_SUBCATEGORIES_DELETE_FAILURE,
  payload
});

export const createReviewCategory = payload => ({
  type: REVIEW_CATEGORY_CREATE,
  payload
});

export const createReviewCategoryErrorSet = payload => ({
  type: REVIEW_CATEGORY_CREATE_ERROR_SET,
  payload
});

export const laborTypesRequest = payload => ({
  type: LABOR_TYPES_REQUEST,
  payload
});

export const laborTypesSet = payload => ({
  type: LABOR_TYPES_SET,
  payload
});

export const reviewSubcategoryCreateRequest = payload => ({
  type: REVIEW_SUBCATEGORY_CREATE_REQUEST,
  payload
});

export const reviewSubcategoryCreateSuccess = payload => ({
  type: REVIEW_SUBCATEGORY_CREATE_SUCCESS,
  payload
});

export const reviewSubcategoryCreateFailure = payload => ({
  type: REVIEW_SUBCATEGORY_CREATE_FAILURE,
  payload
});

export const getSingleReviewCategory = payload => ({
  type: REVIEW_CATEGORIES_GET_SINGLE,
  payload
});

export const setSingleReviewCategory = payload => ({
  type: REVIEW_CATEGORIES_SET_SINGLE,
  payload
});

export const editSingleReviewCategory = payload => ({
  type: REVIEW_CATEGORIES_EDIT_SINGLE,
  payload
});

export const changeReviewCategoriesOrder = payload => ({
  type: REVIEW_CATEGORIES_CHANGE_ORDER,
  payload
});
export const updateOrderReviewCategories = payload => ({
  type: REVIEW_CATEGORIES_UPDATE_ORDER,
  payload
});

export const setUpdateOrderReviewCategories = payload => ({
  type: REVIEW_CATEGORIES_UPDATE_ORDER_SET,
  payload
});

export const setChangeReviewCategoriesOrderError = payload => ({
  type: REVIEW_CATEGORIES_CHANGE_ORDER_ERROR_SET,
  payload
});

export const setChangeReviewSubcategoriesOrderError = payload => ({
  type: REVIEW_SUBCATEGORIES_CHANGE_ORDER_ERROR_SET,
  payload
});

export const reviewCategoryAddSubcategory = payload => ({
  type: REVIEW_CATEGORY_ADD_SUBCATEGORY,
  payload
});

export const updateOrderReviewSubcategories = payload => ({
  type: REVIEW_SUBCATEGORIES_UPDATE_ORDER_SET,
  payload
});

export const changeReviewSubcategoriesOrder = payload => ({
  type: REVIEW_SUBCATEGORIES_CHANGE_ORDER,
  payload
});

export const reviewSubcategorySingleRequest = payload => ({
  type: REVIEW_SUBCATEGORY_SINGLE_REQUEST,
  payload
});

export const reviewSubcategorySingleSet = payload => ({
  type: REVIEW_SUBCATEGORY_SINGLE_SET,
  payload
});

export const reviewSubcategorySingleFailure = payload => ({
  type: REVIEW_SUBCATEGORY_SINGLE_FAILURE,
  payload
});

export const reviewSubcategoryUpdateRequest = payload => ({
  type: REVIEW_SUBCATEGORY_UPDATE_REQUEST,
  payload
});

export const reviewSubcategoryUpdateSuccess = payload => ({
  type: REVIEW_SUBCATEGORY_UPDATE_SUCCESS,
  payload
});

export const reviewSubcategoryUpdateFailure = payload => ({
  type: REVIEW_SUBCATEGORY_UPDATE_FAILURE,
  payload
});

export const setIsSubcategoryChanged = payload => ({
  type: SET_IS_SUBCATEGORIES_CHANGED,
  payload
});

export const setIsCategoryChanged = payload => ({
  type: SET_IS_CATEGORIES_CHANGED,
  payload
});
