import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import AwardList from './AwardList';
import TextInputField from 'components/_shared/TextInputField';
import messages from '../messages';
import Loader from 'components/_shared/Loader';
import { createNewAwardSchema } from './validators';
import { mhAddAward } from 'store/actions/MaternityHospitalActions';
import { makeSelectMaternityHospitalAwards } from 'store/selectors/MaternityHospitalSelector';
import { awardYearRange } from 'utils/formatDate';

const AwardFormAndList = ({ item }) => {
  const { formatMessage } = useIntl();
  const awardData = useSelector(makeSelectMaternityHospitalAwards());
  const dispatch = useDispatch();
  const awardYears = awardYearRange();
  const [selectedAwardYear, setSelectedAwardYear] = useState({
    value: awardYears[0],
    label: awardYears[0]
  });
  const [isAddNewAwardToggled, setIsAddNewAwardToggled] = useState(false);

  const toggleNewAward = () => setIsAddNewAwardToggled(!isAddNewAwardToggled);

  const handleSelectChange = selectedItem => setSelectedAwardYear(selectedItem);

  const yearDropdownOptions = awardYears.map(year => ({
    label: year,
    value: year
  }));

  const onNewAwardSubmit = values => {
    dispatch(
      mhAddAward({
        title: values.title,
        year: selectedAwardYear.value,
        maternity_hospital_id: item.id
      })
    );
    toggleNewAward();
  };

  return (
    <div>
      <header>
        <h2 className="c-section-subtitle">
          {formatMessage(messages.awardHeadTitle)}
        </h2>
      </header>
      {!isAddNewAwardToggled && (
        <button
          className="c-btn c-btn--primary"
          type="button"
          onClick={toggleNewAward}
        >
          {formatMessage(messages.addNewAwardTitle)}
        </button>
      )}
      {isAddNewAwardToggled && (
        <Formik
          initialValues={{
            title: ''
          }}
          validationSchema={createNewAwardSchema}
          onSubmit={onNewAwardSubmit}
          isInitialValid={false}
        >
          <Form>
            <Field
              name="title"
              formatmessagemame={messages.awardTitle}
              component={TextInputField}
            />
            <label className="c-control-label">
              {formatMessage(messages.awardYear)}
            </label>
            <Select
              name="year"
              value={selectedAwardYear}
              options={yearDropdownOptions}
              onChange={handleSelectChange}
            />
            <button
              className="c-btn c-btn--primary u-gap-right u-gap-vert"
              type="submit"
            >
              {formatMessage(messages.newAwardSubmitBtn)}
            </button>
            <button
              onClick={toggleNewAward}
              className="c-btn c-btn--secondary u-gap-vert"
              type="button"
            >
              {formatMessage(messages.newAwardCancelBtn)}
            </button>
          </Form>
        </Formik>
      )}
      <Loader
        active={awardData.loading}
        message={formatMessage(messages.awardLoadingTitle)}
        className="u-gap-vert u-text-center"
      />
      {awardData.error && (
        <div>
          <h3>{formatMessage(messages.awardsError)}</h3>
        </div>
      )}
      {item.awards && <AwardList awards={item.awards} />}
    </div>
  );
};

export default AwardFormAndList;
