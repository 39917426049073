import React, { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import messages from './messages';
import {
  MATERNITY_HOSPITALS_CATEGORIES_CREATE,
  MATERNITY_HOSPITALS_CATEGORIES_REORDER,
  REVIEW_SUBCATEGORIES_ADD
} from 'routes';
import {
  makeSelectReviewCategories,
  makeSelectReviewCategoriesFetchError,
  makeSelectReviewCategoriesLoader
} from '../../store/selectors/ReviewCategorySelector';
import {
  getReviewCategoriesRequest,
  deleteRequestReviewCategory,
  deleteRequestReviewSubcategory
} from '../../store/actions/ReviewCategoryActions';
import MaternityHospitalsCategoriesList from '../../components/MaternityHospitalsCategories';
import ConfirmationModal from 'components/_shared/ConfirmationModal';

const MaternityHospitalsCategoriesPage = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [
    isReviewCategoryDeleteModalOpen,
    setIsReviewCategoryDeleteModalOpen
  ] = useState(false);
  const [
    isReviewSubcategoryDeleteModalOpen,
    setIsReviewSubcategoryDeleteModalOpen
  ] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [subcategoriesToDelete, setSubcategoriesToDelete] = useState([]);

  const reviewCategories = useSelector(makeSelectReviewCategories());
  const isLoading = useSelector(makeSelectReviewCategoriesLoader());
  const error = useSelector(makeSelectReviewCategoriesFetchError());

  const fetchReviewCategories = useCallback(
    () => dispatch(getReviewCategoriesRequest()),
    [dispatch]
  );

  const deleteCategory = id => {
    setIsReviewCategoryDeleteModalOpen(true);
    setSelectedId(id);
  };

  const onConfirmDeleteReviewCategory = () => {
    dispatch(deleteRequestReviewCategory(selectedId));
    setIsReviewCategoryDeleteModalOpen(false);
  };

  const onSelectChange = useCallback(
    (event, id) => {
      if (event.target.checked) {
        setSubcategoriesToDelete([...subcategoriesToDelete, id]);
      } else {
        setSubcategoriesToDelete(
          subcategoriesToDelete.filter(subcatId => subcatId !== id)
        );
      }
    },
    [subcategoriesToDelete]
  );

  const deleteSubcategories = ids => {
    setIsReviewSubcategoryDeleteModalOpen(true);
    setSubcategoriesToDelete(ids);
  };

  const onConfirmDeleteReviewSubcategory = () => {
    setIsReviewSubcategoryDeleteModalOpen(false);
    dispatch(deleteRequestReviewSubcategory(subcategoriesToDelete));
    setSubcategoriesToDelete([]);
  };

  useEffect(() => {
    fetchReviewCategories();
  }, [fetchReviewCategories]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [reviewCategories]);

  return (
    <div>
      <header className="c-section-header is-sticky">
        <h1 className="c-section-title">
          {formatMessage(messages.headerTitle)}
        </h1>
        <div>
          <Link
            className="c-btn c-btn--primary u-gap-right-sm"
            to={MATERNITY_HOSPITALS_CATEGORIES_CREATE}
          >
            {formatMessage(messages.newReviewCategoryLink)}
          </Link>
          <Link
            className="c-btn c-btn--primary u-gap-right-sm"
            to={MATERNITY_HOSPITALS_CATEGORIES_REORDER}
          >
            {formatMessage(messages.reorderCategoriesBtn)}
          </Link>
          <Link
            className="c-btn c-btn--primary u-gap-right-sm"
            to={REVIEW_SUBCATEGORIES_ADD}
          >
            {formatMessage(messages.newReviewSubcategoryLink)}
          </Link>
          <button
            onClick={() => deleteSubcategories(subcategoriesToDelete)}
            disabled={!subcategoriesToDelete.length}
            type="button"
            className="c-btn c-btn--delete"
          >
            {formatMessage(messages.deleteSelected)}
          </button>
        </div>
      </header>
      <main>
        <MaternityHospitalsCategoriesList
          reviewCategories={reviewCategories}
          isLoading={isLoading}
          error={error}
          onDeleteCategory={deleteCategory}
          onSelectChange={onSelectChange}
          selectedSubcategories={subcategoriesToDelete}
        />
      </main>
      <ConfirmationModal
        isOpen={isReviewCategoryDeleteModalOpen}
        onRequestClose={() => setIsReviewCategoryDeleteModalOpen(false)}
        onConfirm={onConfirmDeleteReviewCategory}
        modalText={formatMessage(messages.deleteCategoryModalMessage)}
      />
      <ConfirmationModal
        isOpen={isReviewSubcategoryDeleteModalOpen}
        onRequestClose={() => setIsReviewSubcategoryDeleteModalOpen(false)}
        onConfirm={onConfirmDeleteReviewSubcategory}
        modalText={formatMessage(messages.deleteSubcategoryModalMessage)}
      />
    </div>
  );
};

export default MaternityHospitalsCategoriesPage;
