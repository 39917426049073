import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  makeSelectCategories,
  makeSelectCategoriesOrderBy,
  makeSelectCategoriesLoader
} from 'store/selectors/CategorySelectors';
import {
  getCategories,
  setCategoriesOrderBy,
  deleteCategory
} from 'store/actions/CategoryActions';
import useDebounce from 'utils/useDebounce';
import { DEBOUNCE_TIME } from 'utils/constants';
import messages from './messages';
import { CATEGORY_CREATE } from 'routes';
import CategoriesTable from 'components/Categories';
import ConfirmationModal from 'components/_shared/ConfirmationModal';

const CategoriesPage = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const categories = useSelector(makeSelectCategories());
  const orderBy = useSelector(makeSelectCategoriesOrderBy());
  const loading = useSelector(makeSelectCategoriesLoader());

  const onConfirm = () => {
    dispatch(deleteCategory({ id: selectedId }));
    setIsDeleteModalOpen(false);
  };

  const onItemDelete = id => {
    setIsDeleteModalOpen(true);
    setSelectedId(id);
  };

  const fetchCategories = useCallback(data => dispatch(getCategories(data)), [
    dispatch
  ]);

  const handleOrderBy = ({ target }) => {
    dispatch(setCategoriesOrderBy(target.value));
    document.getElementsByClassName('js-maternity-hospitals')[0].scrollTop = 0;
  };

  const onSearchChange = ({ target }) => setSearchValue(target.value);

  const debouncedSearchTerm = useDebounce(searchValue, DEBOUNCE_TIME);

  useEffect(() => {
    fetchCategories({
      orderBy,
      searchValue
    });
  }, [fetchCategories, debouncedSearchTerm, orderBy]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section>
      <header className="c-section-header">
        <h1 className="c-section-title">
          {formatMessage(messages.categoriesHeaderTitle)}
        </h1>
        <div className="c-section-actions">
          {/* <select
            className="c-form-control"
            value={orderBy}
            onChange={handleOrderBy}
          >
            <option value={ORDER_BY_TITLE}>
              {formatMessage(messages.sortByTitle)}
            </option>
            <option value={ORDER_BY_TITLE_DESC}>
              {formatMessage(messages.sortByTitleDesc)}
            </option>
            <option value={ORDER_BY_ORDER}>
              {formatMessage(messages.sortByOrder)}
            </option>
            <option value={ORDER_BY_ORDER_DESC}>
              {formatMessage(messages.sortByOrderDesc)}
            </option>
          </select>

          <div className="c-form-group c-search-field c-input-group u-gap-left--sm">
            <input
              type="text"
              className="c-form-control"
              value={searchValue}
              onChange={onSearchChange}
            />
            <span className="c-input-group__addon">
              <IoIosSearch />
            </span>
          </div> */}

          <Link
            className="c-btn c-btn--primary u-gap-left"
            to={{
              pathname: CATEGORY_CREATE,
              isUpdate: false
            }}
          >
            {formatMessage(messages.createCategoryButton)}
          </Link>
        </div>
      </header>
      <CategoriesTable
        categories={categories}
        onItemDelete={onItemDelete}
        loading={loading}
      />
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        onConfirm={onConfirm}
        modalText={formatMessage(messages.modalDialogText)}
      />
    </section>
  );
};

export default CategoriesPage;
