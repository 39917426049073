import React from 'react';

const SwitchField = ({ id, ...props }) => (
  <div className="c-form-group">
    <label className="c-form-switch" htmlFor={id}>
      <input
        id={id}
        className="c-form-switch__input"
        type="checkbox"
        {...props}
      />
      <span className="c-form-switch__toggle"></span>
    </label>
  </div>
);

export default SwitchField;
