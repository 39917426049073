import produce from 'immer';

import {
  ARTICLES_SET,
  ARTICLES_ORDER_BY_SET,
  ARTICLES_AT_CURRENT_PAGE_SET,
  ARTICLE_LOADER_SET,
  ARTICLE_DELETE_SET,
  ARTICLE_TOGGLE_SET,
  ARTICLE_CREATE_SET,
  ARTICLE_UPDATE_SET,
  ARTICLE_ITEM_SET,
  SET_INITIAL_ARTICLE_CATEGORY
} from 'store/actions/ActionTypes';
import { ORDER_BY_CREATED_AT_DESC, ITEMS_PER_PAGE } from 'utils/constants';

export const initialState = {
  articles: [],
  pagination: {
    hasMore: false,
    currentPage: 0,
    total: 0
  },
  orderBy: ORDER_BY_CREATED_AT_DESC,
  articleItem: {
    category: {},
    photos: []
  },
  loader: false,
  initialCategory: {}
};

/* eslint-disable default-case */
const articleReducer = (state = initialState, action) =>
  produce(state, draft => {
    const { type, payload } = action;
    switch (type) {
      case ARTICLES_SET:
        draft.articles =
          payload.currentPage === 1
            ? payload.data
            : [...draft.articles, ...payload.data];
        draft.pagination.hasMore = !!payload.nextPageUrl;
        draft.pagination.currentPage = payload.currentPage;
        draft.pagination.total = payload.total;
        break;
      case ARTICLES_AT_CURRENT_PAGE_SET:
        payload.data.length === ITEMS_PER_PAGE
          ? draft.articles.push(payload.data[payload.data.length - 1])
          : (draft.pagination.hasMore = false);
        break;
      case ARTICLES_ORDER_BY_SET:
        draft.orderBy = payload;
        draft.pagination.currentPage = 0;
        draft.pagination.total = payload.total;
        break;
      case ARTICLE_LOADER_SET:
        draft.loader = payload;
        break;
      case ARTICLE_DELETE_SET:
        draft.articles = draft.articles.filter(({ id }) => id !== payload);
        --draft.pagination.total;
        break;
      case ARTICLE_TOGGLE_SET:
        draft.articles = draft.articles.map(item =>
          item.id !== payload.id ? item : payload
        );
        break;
      case ARTICLE_CREATE_SET:
        draft.articles.unshift(payload);
        ++draft.pagination.total;
        break;
      case ARTICLE_UPDATE_SET:
        draft.articles = draft.articles.map(item =>
          item.id === payload.id ? payload : item
        );
        break;
      case ARTICLE_ITEM_SET:
        draft.articleItem = payload;
        break;
      case SET_INITIAL_ARTICLE_CATEGORY:
        draft.initialCategory = payload;
        break;
    }
  });

export default articleReducer;
