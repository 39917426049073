import produce from 'immer';
import {
  SET_CATEGORIES,
  SET_CATEGORIES_ORDER_BY,
  SET_CATEGORIES_LOADER,
  SET_REORDER_CATEGORIES,
  SET_DELETE_CATEGORY,
  SET_CREATE_CATEGORY,
  UNIQUE_CATEGORY_TITLE_ERROR,
  SET_UPDATE_CATEGORY,
  SET_CATEGORY
} from '../actions/ActionTypes';
import { ORDER_BY_ORDER } from 'utils/constants';

export const initialState = {
  categories: [],
  orderBy: ORDER_BY_ORDER,
  loader: false,
  error: false,
  category: {
    photos: []
  },
  oldTitle: ''
};

/* eslint-disable default-case */
const categoriesReducer = (state = initialState, action) =>
  produce(state, draft => {
    const { type, payload } = action;
    switch (type) {
      case SET_CATEGORIES:
        draft.categories = payload;
        break;
      case SET_CATEGORIES_LOADER:
        draft.loader = payload;
        break;
      case SET_CATEGORIES_ORDER_BY:
        draft.orderBy = payload;
        break;
      case SET_CREATE_CATEGORY:
        draft.categories = [...draft.categories, payload];
        break;
      case UNIQUE_CATEGORY_TITLE_ERROR:
        draft.error = payload;
        break;
      case SET_REORDER_CATEGORIES:
        draft.categories = action.payload;
        break;
      case SET_DELETE_CATEGORY:
        draft.categories = draft.categories.filter(
          category => category.id !== action.payload
        );
        break;
      case SET_UPDATE_CATEGORY:
        draft.categories = draft.categories.map(item =>
          item.id === payload.id ? payload : item
        );
        break;
      case SET_CATEGORY:
        draft.category = payload;
        draft.oldTitle = payload.title;
        break;
    }
  });
export default categoriesReducer;
