export const LOGIN = '[Login]';

export const LOGIN_REQUEST = `${LOGIN} LOGIN_REQUEST`;
export const LOGIN_SUCCESS = `${LOGIN} LOGIN_SUCCESS`;
export const LOGIN_ERROR = `${LOGIN} LOGIN_ERROR`;

export const NOTIFIER = '[Notifier]';

export const ENQUEUE_SNACKBAR = `${NOTIFIER} ENQUEUE_SNACKBAR`;
export const CLOSE_SNACKBAR = `${NOTIFIER} CLOSE_SNACKBAR`;
export const REMOVE_SNACKBAR = `${NOTIFIER} REMOVE_SNACKBAR`;

export const REGISTER = '[Register]';

export const REGISTER_REQUEST = `${REGISTER} REGISTER_REQUEST`;
export const REGISTER_SUCCESS = `${REGISTER} REGISTER_SUCCESS`;
export const REGISTER_ERROR = `${REGISTER} REGISTER_ERROR`;
export const RESET_PASSWORD = '[Reset Password]';

export const RESET_PASSWORD_REQUEST = `${RESET_PASSWORD} RESET_PASSWORD_REQUEST`;
export const RESET_PASSWORD_SUCCESS = `${RESET_PASSWORD} RESET_PASSWORD_SUCCESS`;
export const RESET_PASSWORD_ERROR = `${RESET_PASSWORD} RESET_PASSWORD_ERROR`;
export const USER_PROFILE = '[User Profile]';

export const UPDATE_USER_REQUEST = `${USER_PROFILE} UPDATE_USER_REQUEST`;
export const UPDATE_USER_SUCCESS = `${USER_PROFILE} UPDATE_USER_SUCCESS`;
export const UPDATE_USER_ERROR = `${USER_PROFILE} UPDATE_USER_ERROR`;
export const CHANGE_PASSWORD_REQUEST = `${USER_PROFILE} CHANGE_PASSWORD_REQUEST`;
export const CHANGE_PASSWORD_SUCCESS = `${USER_PROFILE} CHANGE_PASSWORD_SUCCESS`;
export const CHANGE_PASSWORD_ERROR = `${USER_PROFILE} CHANGE_PASSWORD_ERROR`;
export const DASHBAORD = '[Dashboard]';

export const DEFAULT_ACTION = `${DASHBAORD} DEFAULT_ACTION`;
export const FORGOT_PASSWORD = '[Forgot Password]';

export const FORGOT_PASSWORD_REQUEST = `${FORGOT_PASSWORD} FORGOT_PASSWORD_REQUEST`;
export const FORGOT_PASSWORD_SUCCESS = `${FORGOT_PASSWORD} FORGOT_PASSWORD_SUCCESS`;
export const FORGOT_PASSWORD_ERROR = `${FORGOT_PASSWORD} FORGOT_PASSWORD_ERROR`;
export const LANGUAGE = '[Language]';

export const CHANGE_LOCALE = `${LANGUAGE} CHANGE_LOCALE`;

//Maternity Hospitals
export const MATERNITY_HOSPITALS = `[Maternity Hospital]`;
export const GET_MATERNITY_HOSPITALS = `${MATERNITY_HOSPITALS} GET_MATERNITY_HOSPITALS`;
export const SET_MATERNITY_HOSPITALS = `${MATERNITY_HOSPITALS} SET_MATERNITY_HOSPITALS`;
export const GET_MATERNITY_HOSPITALS_BASIC = `${MATERNITY_HOSPITALS} GET_MATERNITY_HOSPITALS_BASIC`;
export const SET_MATERNITY_HOSPITALS_BASIC = `${MATERNITY_HOSPITALS} SET_MATERNITY_HOSPITALS_BASIC`;
export const SET_MATERNITY_HOSPITALS_AT_CURRENT_PAGE = `${MATERNITY_HOSPITALS} SET_MATERNITY_HOSPITALS_AT_CURRENT_PAGE`;
export const GET_MATERNITY_HOSPITAL = `${MATERNITY_HOSPITALS} GET_MATERNITY_HOSPITAL`;
export const SET_MATERNITY_HOSPITAL = `${MATERNITY_HOSPITALS} SET_MATERNITY_HOSPITAL`;
export const CREATE_MATERNITY_HOSPITALS = `${MATERNITY_HOSPITALS} CREATE_MATERNITY_HOSPITALS`;
export const SET_CREATE_MATERNITY_HOSPITALS = `${MATERNITY_HOSPITALS} SET_CREATE_MATERNITY_HOSPITALS`;
export const DELETE_MATERNITY_HOSPITAL = `${MATERNITY_HOSPITALS} DELETE_MATERNITY_HOSPITAL`;
export const SET_DELETE_MATERNITY_HOSPITAL = `${MATERNITY_HOSPITALS} SET_DELETE_MATERNITY_HOSPITAL`;
export const ACTIVATE_DEACTIVATE_MATERNITY_HOSPITAL = `${MATERNITY_HOSPITALS} ACTIVATE_DEACTIVATE_MATERNITY_HOSPITAL`;
export const SET_ACTIVATE_DEACTIVATE_MATERNITY_HOSPITAL = `${MATERNITY_HOSPITALS} SET_ACTIVATE_DEACTIVATE_MATERNITY_HOSPITAL`;
export const SET_MATERNITY_HOSPITALS_ORDER_BY = `${MATERNITY_HOSPITALS} SET_MATERNITY_HOSPITALS_ORDER_BY`;
export const UPDATE_MATERNITY_HOSPITAL = `${MATERNITY_HOSPITALS} UPDATE_MATERNITY_HOSPITAL`;
export const SET_UPDATE_MATERNITY_HOSPITAL = `${MATERNITY_HOSPITALS} SET_UPDATE_MATERNITY_HOSPITAL`;
export const SET_MATERNITY_HOSPITALS_LOADER = `${MATERNITY_HOSPITALS} SET_MATERNITY_HOSPITALS_LOADER`;
export const MATERNITY_HOSPITAL_ADD_AWARD = 'MATERNITY_HOSPITAL_ADD_AWARD';
export const MATERNITY_HOSPITAL_ADD_AWARD_SUCCESS =
  'MATERNITY_HOSPITAL_ADD_AWARD_SUCCESS';
export const MATERNITY_HOSPITAL_ADD_AWARD_LOADING =
  'MATERNITY_HOSPITAL_ADD_AWARD_LOADING';
export const MATERNITY_HOSPITAL_ADD_AWARD_ERROR =
  'MATERNITY_HOSPITAL_ADD_AWARD_ERROR';
export const MATERNITY_HOSPITAL_REMOVE_AWARD =
  'MATERNITY_HOSPITAL_REMOVE_AWARD';
export const MATERNITY_HOSPITAL_REMOVE_AWARD_SUCCESS =
  'MATERNITY_HOSPITAL_REMOVE_AWARD_SUCCESS';
export const MATERNITY_HOSPITAL_REMOVE_AWARD_LOADING =
  'MATERNITY_HOSPITAL_REMOVE_AWARD_LOADING';
export const MATERNITY_HOSPITAL_REMOVE_AWARD_ERROR =
  'MATERNITY_HOSPITAL_REMOVE_AWARD_ERROR';
export const MATERNITY_HOSPITAL_SWITCH_GRADE_REQUEST =
  'MATERNITY_HOSPITAL_SWITCH_GRADE_REQUEST';
export const MATERNITY_HOSPITAL_SWITCH_GRADE_SUCCESS =
  'MATERNITY_HOSPITAL_SWITCH_GRADE_SUCCESS';
export const MATERNITY_HOSPITAL_EDIT_GRADE_REQUEST =
  'MATERNITY_HOSPITAL_EDIT_GRADE_REQUEST';
export const MATERNITY_HOSPITAL_EDIT_GRADE_SUCCESS =
  'MATERNITY_HOSPITAL_EDIT_GRADE_SUCCESS';

export const MATERNITY_HOSPITAL_QUESTION = `[Maternity Hospital Question]`;
export const GET_MATERNITY_HOSPITAL_QUESTIONS = `${MATERNITY_HOSPITAL_QUESTION} GET_MATERNITY_HOSPITAL_QUESTIONS`;
export const SET_MATERNITY_HOSPITAL_QUESTIONS = `${MATERNITY_HOSPITAL_QUESTION} SET_MATERNITY_HOSPITAL_QUESTIONS`;
export const UPDATE_MATERNITY_HOSPITAL_QUESTION_ACTIVE = `${MATERNITY_HOSPITAL_QUESTION} UPDATE_MATERNITY_HOSPITAL_QUESTION_ACTIVE`;
export const SET_UPDATE_MATERNITY_HOSPITAL_QUESTION_ACTIVE = `${MATERNITY_HOSPITAL_QUESTION} SET_UPDATE_MATERNITY_HOSPITAL_QUESTION_ACTIVE`;
export const UPDATE_MATERNITY_HOSPITAL_QUESTION_FILTER = `${MATERNITY_HOSPITAL_QUESTION} UPDATE_MATERNITY_HOSPITAL_QUESTION_FILTER`;
export const SET_UPDATE_MATERNITY_HOSPITAL_QUESTION_FILTER = `${MATERNITY_HOSPITAL_QUESTION} SET_UPDATE_MATERNITY_HOSPITAL_QUESTION_FILTER`;
export const MATERNITY_HOSPITAL_QUESTION_CATEGORIES_GET = `${MATERNITY_HOSPITAL_QUESTION} MATERNNITY_HOSPITAL_QUESTION_CATEGORIES_GET`;
export const MATERNITY_HOSPITAL_QUESTION_CATEGORIES_SET = `${MATERNITY_HOSPITAL_QUESTION} MATERNNITY_HOSPITAL_QUESTION_CATEGORIES_SET`;

export const APP = '[App]';
export const FETCH_AUTHENTICATED_USER_REQUEST = `${APP} FETCH_AUTHENTICATED_USER_REQUEST`;
export const FETCH_AUTHENTICATED_USER_SUCCESS = `${APP} FETCH_AUTHENTICATED_USER_SUCCESS`;
export const LOGOUT_REQUEST = `${APP} LOGOUT_REQUEST`;
export const LOGOUT_SUCCESS = `${APP} LOGOUT_SUCCESS`;
export const SET_TOKEN = `${APP} SET_TOKEN`;
export const SESSION_EXPIRED = `${APP} SESSION_EXPIRED`;

export const ADD_MATERNITY_HOSPITAL_QUESTION = `${APP} ADD_MATERNITY_HOSPITAL_QUESTION`;
export const SET_ADD_MATERNITY_HOSPITAL_QUESTION = `${APP} SET_ADD_MATERNITY_HOSPITAL_QUESTION`;

export const DELETE_MATERNITY_HOSPITAL_QUESTION = `${APP} DELETE_MATERNITY_HOSPITAL_QUESTION`;
export const SET_DELETE_MATERNITY_HOSPITAL_QUESTION = `${APP} SET_DELETE_MATERNITY_HOSPITAL_QUESTION`;

export const UPDATE_MATERNITY_HOSPITAL_QUESTION = `${APP} UPDATE_MATERNITY_HOSPITAL_QUESTION`;
export const SET_UPDATE_MATERNITY_HOSPITAL_QUESTION = `${APP} SET_UPDATE_MATERNITY_HOSPITAL_QUESTION`;
export const ACTIVATE_DEACTIVATE_MATERNITY_HOSPITAL_QUESTION = `${APP} ACTIVATE_DEACTIVATE_MATERNITY_HOSPITAL_QUESTION`;
export const SET_ACTIVATE_DEACTIVATE_MATERNITY_HOSPITAL_QUESTION = `${APP} SET_ACTIVATE_DEACTIVATE_MATERNITY_HOSPITAL_QUESTION`;

export const MATERNITY_HOSPITAL_UPLOAD_PHOTO = `${MATERNITY_HOSPITALS} MATERNITY_HOSPITAL_UPLOAD_PHOTO`;
export const MATERNITY_HOSPITAL_ADD_UPLOADED_PHOTO = `${MATERNITY_HOSPITALS} MATERNITY_HOSPITAL_ADD_UPLOADED_PHOTO`;

export const GET_MATERNITY_HOSPITAL_QUESTION = `${APP} GET_MATERNITY_HOSPITAL_QUESTION`;
export const SET_MATERNITY_HOSPITAL_QUESTION = `${APP} SET_MATERNITY_HOSPITAL_QUESTION`;

export const CATEGORY = `[Category]`;
export const GET_CATEGORIES = `${CATEGORY} GET_CATEGORIES`;
export const SET_CATEGORIES = `${CATEGORY} SET_CATEGORIES`;
export const SET_CATEGORIES_LOADER = `${CATEGORY} SET_CATEGORIES_LOADER`;
export const SET_CATEGORIES_ORDER_BY = `${CATEGORY} SET_CATEGORIES_ORDER_BY`;
export const SET_REORDER_CATEGORIES = `${CATEGORY} SET_REORDER_CATEGORIES`;
export const DELETE_CATEGORY = `${CATEGORY} DELETE_CATEGORY`;
export const SET_DELETE_CATEGORY = `${CATEGORY} SET_DELETE_CATEGORY`;
export const REORDER_CATEGORIES = `${CATEGORY} REORDER_CATEGORIES`;
export const CREATE_CATEGORY = `${CATEGORY} CREATE_CATEGORY`;
export const SET_CREATE_CATEGORY = `${CATEGORY} SET_CREATE_CATEGORY`;
export const UNIQUE_CATEGORY_TITLE_ERROR = `${CATEGORY} UNIQUE_CATEGORY_TITLE_ERROR`;
export const UPDATE_CATEGORY = `${CATEGORY} UPDATE_CATEGORY`;
export const SET_UPDATE_CATEGORY = `${CATEGORY} SET_UPDATE_CATEGORY`;
export const GET_CATEGORY = `${CATEGORY} GET_CATEGORY`;
export const SET_CATEGORY = `${CATEGORY} SET_CATEGORY`;

export const ARTICLE = `[Article]`;
export const ARTICLES_GET = `${ARTICLE} ARTICLES_GET`;
export const ARTICLES_SET = `${ARTICLE} ARTICLES_SET`;
export const ARTICLES_ORDER_BY_SET = `${ARTICLE} ARTICLES_ORDER_BY_SET`;
export const ARTICLES_AT_CURRENT_PAGE_SET = `${ARTICLE} ARTICLES_AT_CURRENT_PAGE_SET`;
export const ARTICLE_LOADER_SET = `${ARTICLE} ARTICLE_LOADER_SET`;
export const ARTICLE_DELETE = `${ARTICLE} ARTICLE_DELETE`;
export const ARTICLE_DELETE_SET = `${ARTICLE} ARTICLE_DELETE_SET`;
export const ARTICLE_TOGGLE = `${ARTICLE} ARTICLE_TOGGLE`;
export const ARTICLE_TOGGLE_SET = `${ARTICLE} ARTICLE_TOGGLE_SET`;
export const ARTICLE_CREATE = `${ARTICLE} ARTICLE_CREATE`;
export const ARTICLE_CREATE_SET = `${ARTICLE} ARTICLE_CREATE_SET`;
export const ARTICLE_UPDATE = `${ARTICLE} ARTICLE_UPDATE`;
export const ARTICLE_UPDATE_SET = `${ARTICLE} ARTICLE_UPDATE_SET`;
export const ARTICLE_ITEM_GET = `${ARTICLE} ARTICLE_ITEM_GET`;
export const ARTICLE_ITEM_SET = `${ARTICLE} ARTICLE_ITEM_SET`;
export const DISPATCH_SET_INITIAL_ARTICLE_CATEGORY =
  'DISPATCH_SET_INITIAL_ARTICLE_CATEGORY';
export const SET_INITIAL_ARTICLE_CATEGORY = 'SET_INITIAL_ARTICLE_CATEGORY';

export const USER = `[User]`;
export const ADMINS_GET = `${USER} ADMINS_GET`;
export const ADMINS_SET = `${USER} ADMINS_SET`;
export const USERS_GET = `${USER} USERS_GET`;
export const USERS_SET = `${USER} USERS_SET`;
export const USER_GET = `${USER} USER_GET`;
export const USER_SET = `${USER} USER_SET`;
export const USER_UPDATE = `${USER} USER_UPDATE`;
export const USER_UPDATE_SET = `${USER} USER_UPDATE_SET`;
export const USER_DELETE = `${USER} USER_DELETE`;
export const USER_DELETE_SET = `${USER} USER_DELETE_SET`;
export const USER_LOADER_SET = `${USER} USER_LOADER_SET`;
export const USER_CREATE = `${USER} USER_CREATE`;
export const USER_CREATE_SET = `${USER} USER_CREATE_SET`;
export const USER_FINALIZE_REGISTRATION = `${USER} USER_FINALIZE_REGISTRATION`;
export const USER_ERROR = `${USER} USER_ERROR`;
export const USER_UNIQUE_EMAIL_ERROR = `${USER} USER_UNIQUE_EMAIL_ERROR`;

//Review Categories
export const REVIEW_CATEGORIES_GET_REQUEST = 'REVIEW_CATEGORIES_GET_REQUEST';
export const REVIEW_CATEGORIES_SET = 'REVIEW_CATEGORIES_SET';
export const REVIEW_CATEGORIES_GET_FAILURE = 'REVIEW_CATEGORIES_GET_FAILURE';
export const REVIEW_CATEGORIES_LOADER = 'REVIEW_CATEGORIES_LOADER';
export const REVIEW_CATEGORIES_DELETE_REQUEST =
  'REVIEW_CATEGORIES_DELETE_REQUEST';
export const REVIEW_CATEGORIES_SET_AFTER_DELETE =
  'REVIEW_CATEGORIES_SET_AFTER_DELETE';
export const REVIEW_CATEGORIES_DELETE_FAILURE =
  'REVIEW_CATEGORIES_DELETE_FAILURE';
export const REVIEW_CATEGORY_ADD_SUBCATEGORY =
  'REVIEW_CATEGORY_ADD_SUBCATEGORY';
export const REVIEW_SUBCATEGORIES_DELETE_REQUEST =
  'REVIEW_SUBCATEGORIES_DELETE_REQUEST';
export const REVIEW_SUBCATEGORIES_SET_AFTER_DELETE =
  'REVIEW_SUBCATEGORIES_SET_AFTER_DELETE';
export const REVIEW_SUBCATEGORIES_DELETE_FAILURE =
  'REVIEW_SUBCATEGORIES_DELETE_FAILURE';
export const REVIEW_SUBCATEGORY_CREATE_REQUEST =
  'REVIEW_SUBCATEGORY_CREATE_REQUEST';
export const REVIEW_SUBCATEGORY_CREATE_SUCCESS =
  'REVIEW_SUBCATEGORY_CREATE_SUCCESS';
export const REVIEW_SUBCATEGORY_CREATE_FAILURE =
  'REVIEW_SUBCATEGORY_CREATE_FAILURE';
export const REVIEW_SUBCATEGORY_SINGLE_REQUEST =
  'REVIEW_SUBCATEGORY_SINGLE_REQUEST';
export const REVIEW_SUBCATEGORY_SINGLE_SET = 'REVIEW_SUBCATEGORY_SINGLE_SET';
export const REVIEW_SUBCATEGORY_SINGLE_FAILURE =
  'REVIEW_SUBCATEGORY_SINGLE_FAILURE';
export const REVIEW_SUBCATEGORY_UPDATE_REQUEST =
  'REVIEW_SUBCATEGORY_UPDATE_REQUEST';
export const REVIEW_SUBCATEGORY_UPDATE_SUCCESS =
  'REVIEW_SUBCATEGORY_UPDATE_SUCCESS';
export const REVIEW_SUBCATEGORY_UPDATE_FAILURE =
  'REVIEW_SUBCATEGORY_UPDATE_FAILURE';

export const REVIEW_CATEGORY_CREATE = 'REVIEW_CATEGORY_CREATE';
export const REVIEW_CATEGORY_CREATE_ERROR_SET =
  'REVIEW_CATEGORY_CREATE_ERROR_SET';

export const LABOR_TYPES_REQUEST = 'LABOR_TYPES_REQUEST';
export const LABOR_TYPES_SET = 'LABOR_TYPES_SET';

export const REVIEW_CATEGORIES_GET_SINGLE = 'REVIEW_CATEGORIES_GET_SINGLE';
export const REVIEW_CATEGORIES_SET_SINGLE = 'REVIEW_CATEGORIES_SET_SINGLE';
export const REVIEW_CATEGORIES_EDIT_SINGLE = 'REVIEW_CATEGORIES_EDIT_SINGLE';
export const REVIEW_CATEGORIES_CHANGE_ORDER = 'REVIEW_CATEGORIES_CHANGE_ORDER';
export const REVIEW_CATEGORIES_UPDATE_ORDER = 'REVIEW_CATEGORIES_UPDATE_ORDER';
export const REVIEW_CATEGORIES_UPDATE_ORDER_SET =
  'REVIEW_CATEGORIES_UPDATE_ORDER_SET';
export const REVIEW_CATEGORIES_CHANGE_ORDER_ERROR_SET =
  'REVIEW_CATEGORIES_CHANGE_ORDER_ERROR_SET';
export const REVIEW_SUBCATEGORIES_UPDATE_ORDER_SET =
  'REVIEW_SUBCATEGORIES_UPDATE_ORDER_SET';
export const REVIEW_SUBCATEGORIES_CHANGE_ORDER =
  'REVIEW_SUBCATEGORIES_CHANGE_ORDER';
export const REVIEW_SUBCATEGORIES_CHANGE_ORDER_ERROR_SET =
  'REVIEW_SUBCATEGORIES_CHANGE_ORDER_ERROR_SET';
export const SET_IS_SUBCATEGORIES_CHANGED = 'SET_IS_SUBCATEGORIES_CHANGED';
export const SET_IS_CATEGORIES_CHANGED = 'SET_IS_CATEGORIES_CHANGED';

//Maternity hospital reviews
export const MH_REVIEWS_FETCH_REQUEST = 'MH_REVIEWS_FETCH_REQUEST';
export const MH_REVIEWS_FETCH_SUCCESS = 'MH_REVIEWS_FETCH_SUCCESS';
export const MH_REVIEWS_FETCH_FAILURE = 'MH_REVIEWS_FETCH_FAILURE';
export const MH_REVIEW_SET_LOADER = 'MH_REVIEW_SET_LOADER';
export const MH_REVIEW_DELETE_REQUEST = 'MH_REVIEW_DELETE_REQUEST';
export const MH_REVIEW_DELETE_SUCCESS = 'MH_REVIEW_DELETE_SUCCESS';
export const MH_REVIEWS_GET_FOR_USER = 'MH_REVIEWS_GET_FOR_USER';
export const MH_REVIEWS_SET_FOR_USER = 'MH_REVIEWS_SET_FOR_USER';
export const MH_REVIEW_SET_USER_FOR_REVIEWS = 'MH_REVIEW_SET_USER_FOR_REVIEWS';
export const MH_REVIEWS_GET_FOR_USER_ERROR_SET =
  'MH_REVIEWS_GET_FOR_USER_ERROR_SET';
export const MH_REVIEW_COMMENT_UPDATE = 'MH_REVIEW_COMMENT_UPDATE';
export const MH_REVIEW_COMMENT_UPDATE_SET = 'MH_REVIEW_COMMENT_UPDATE_SET';
export const MH_REVIEW_COMMENT_UPDATE_ERROR = 'MH_REVIEW_COMMENT_UPDATE_ERROR';
export const MH_REVIEW_COMMENT_DELETE_PHOTO = 'MH_REVIEW_COMMENT_DELETE_PHOTO';
export const MH_REVIEW_COMMENT_DELETE_PHOTO_ERROR =
  'MH_REVIEW_COMMENT_DELETE_PHOTO_ERROR';
export const MH_REVIEW_COMMENT_DELETE_PHOTO_SUCCESS =
  'MH_REVIEW_COMMENT_DELETE_PHOTO_SUCCESS';

export const MH_TROPHY_UPDATE_REQUEST = 'MH_TROPHY_UPDATE_REQUEST';
export const MH_TROPHY_UPDATE_SUCCESS = 'MH_TROPHY_UPDATE_SUCCESS';

export const SET_ACTIVATE_DEACTIVATE_MH_REVIEWS =
  'SET_ACTIVATE_DEACTIVATE_MH_REVIEWS';
export const SET_ACTIVATE_DEACTIVATE_MH_REVIEWS_SUCCESS =
  'SET_ACTIVATE_DEACTIVATE_MH_REVIEWS_SUCCESS';
