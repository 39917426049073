import { defineMessages } from 'react-intl';

export const scope = 'user_profile';

export default defineMessages({
  updateUserHeader: {
    id: `${scope}.text.update_user_header`,
    defaultMessage: 'Izmeni Profile'
  },
  updateButton: {
    id: `${scope}.button.update`,
    defaultMessage: 'Izmeni'
  },
  changePasswordButton: {
    id: `${scope}.button.change_password`,
    defaultMessage: 'Promenite lozinku'
  },
  firstNameInputLabel: {
    id: `${scope}.input_label.first_name`,
    defaultMessage: 'Ime'
  },
  lastNameInputLabel: {
    id: `${scope}.input_label.last_name`,
    defaultMessage: 'Prezime'
  },
  currentPasswordInputLabel: {
    id: `${scope}.input_label.current_password`,
    defaultMessage: 'Trenutna lozinka'
  },
  newPasswordInputLabel: {
    id: `${scope}.input_label.new_password`,
    defaultMessage: 'Nova lozinka'
  },
  newPasswordConfirmationInputLabel: {
    id: `${scope}.input_label.new_password_confirmation`,
    defaultMessage: 'Potvrdite novu lozinku'
  },
  profileUpdated: {
    id: `${scope}.notification.profile_update`,
    defaultMessage: 'Profil je uspešno izmenjen'
  },
  passwordChanged: {
    id: `${scope}.notifications.password_changed`,
    defaultMessage: 'Lozinka je uspešno izmenjena'
  },
  saved: {
    id: `${scope}.notifications.saved`,
    defaultMessage: 'Sačuvano.'
  }
});
