import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useParams, useLocation } from 'react-router-dom';

import ReviewSubcategoryForm from './ReviewSubcategoryForm';
import {
  laborTypesRequest,
  getReviewCategoriesRequest,
  reviewSubcategoryCreateRequest,
  reviewCategoryAddSubcategory,
  reviewSubcategoryCreateFailure,
  reviewSubcategorySingleRequest,
  reviewSubcategoryUpdateRequest,
  reviewSubcategoryUpdateFailure
} from 'store/actions/ReviewCategoryActions';
import {
  makeSelectLaborTypes,
  makeSelectReviewCategories,
  makeSelectReviewSubcategoriesCreateError,
  makeSelectSingleReviewSubcategory,
  makeSelectReviewSubcategoryUpdateError
} from 'store/selectors/ReviewCategorySelector';
import messages from '../messages';
import ConfirmationModal from 'components/_shared/ConfirmationModal';
import { REVIEW_SUBCATEGORIES } from 'routes';

const CreateReviewSubcategory = ({
  isCategoryUpdate,
  categoryId,
  hideForm
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { pathname } = useLocation();
  const isSubcategoryUpdate =
    (id && pathname === `${REVIEW_SUBCATEGORIES}/${id}`) || false;
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const reviewSubcategory = useSelector(makeSelectSingleReviewSubcategory());
  const laborTypes = useSelector(makeSelectLaborTypes());
  const reviewCategories = useSelector(makeSelectReviewCategories());
  const reviewSubcategoryCreateError = useSelector(
    makeSelectReviewSubcategoriesCreateError()
  );
  const reviewSubcategoryUpdateError = useSelector(
    makeSelectReviewSubcategoryUpdateError()
  );

  const fetchLaborTypes = useCallback(() => dispatch(laborTypesRequest()), [
    dispatch
  ]);
  const fetchReviewCategories = useCallback(
    () => dispatch(getReviewCategoriesRequest()),
    [dispatch]
  );
  const fetchSingleReviewSubcategory = useCallback(
    id => dispatch(reviewSubcategorySingleRequest(id)),
    [dispatch]
  );

  const closeErrorModal = () => {
    reviewSubcategoryCreateError &&
      dispatch(reviewSubcategoryCreateFailure(false));
    reviewSubcategoryUpdateError &&
      dispatch(reviewSubcategoryUpdateFailure(false));
    setIsErrorModalOpen(false);
  };

  useEffect(() => {
    fetchLaborTypes();
    fetchReviewCategories();
  }, []);

  useEffect(() => {
    if (isSubcategoryUpdate) {
      fetchSingleReviewSubcategory(id);
    }
  }, [id, fetchSingleReviewSubcategory, isSubcategoryUpdate]);

  useEffect(() => {
    setIsErrorModalOpen(
      !!reviewSubcategoryCreateError && !!reviewSubcategoryUpdateError
    );
  }, [reviewSubcategoryCreateError, reviewSubcategoryUpdateError]);

  const onCreateSubcategorySubmit = useCallback(
    values => {
      if (isSubcategoryUpdate) {
        const payload = {
          id,
          values
        };
        dispatch(reviewSubcategoryUpdateRequest(payload));
        return;
      }
      isCategoryUpdate
        ? dispatch(reviewCategoryAddSubcategory(values))
        : dispatch(reviewSubcategoryCreateRequest(values));
    },
    [dispatch, isCategoryUpdate, id, isSubcategoryUpdate]
  );
  return (
    <div>
      {isCategoryUpdate ? (
        <h1>{formatMessage(messages.addSubcategory)}</h1>
      ) : (
        <header className="c-section-header">
          <h1 className="c-section-title">
            {isSubcategoryUpdate
              ? formatMessage(messages.editReviewSubcategory)
              : formatMessage(messages.createNewReviewSubcategory)}
          </h1>
        </header>
      )}

      <ReviewSubcategoryForm
        laborTypes={laborTypes}
        reviewCategories={reviewCategories}
        onSubmit={onCreateSubcategorySubmit}
        isCategoryUpdate={isCategoryUpdate}
        categoryId={categoryId}
        isSubcategoryUpdate={isSubcategoryUpdate}
        subcategory={reviewSubcategory}
        hideForm={hideForm}
      />
      <ConfirmationModal
        isOpen={isErrorModalOpen}
        onRequestClose={closeErrorModal}
        modalText={
          reviewSubcategoryCreateError
            ? formatMessage(messages.createNewReviewSubcategoryError)
            : formatMessage(messages.editReviewSubcategoryError)
        }
      />
    </div>
  );
};

export default CreateReviewSubcategory;
