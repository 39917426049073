import { call, put, select } from 'redux-saga/effects';

import request from 'utils/request';
import history from 'utils/history';

import {
  getReviewCategoriesFailure,
  setReviewCategories,
  setReviewCategoriesLoader,
  setReviewCategoriesAfterDelete,
  setReviewSubcategoriesAfterDelete,
  deleteRequestReviewCategoryFailure,
  deleteRequestReviewSubcategoryFailure,
  createReviewCategoryErrorSet,
  setUpdateOrderReviewCategories,
  setChangeReviewCategoriesOrderError,
  laborTypesSet,
  reviewSubcategoryCreateFailure,
  reviewSubcategoryCreateSuccess,
  setSingleReviewCategory,
  setChangeReviewSubcategoriesOrderError,
  reviewSubcategorySingleFailure,
  reviewSubcategorySingleSet,
  reviewSubcategoryUpdateSuccess,
  reviewSubcategoryUpdateFailure,
  setIsSubcategoryChanged,
  setIsCategoryChanged
} from 'store/actions/ReviewCategoryActions';
import parseObjectToArray from 'utils/parseObjectToArray';
import {
  REVIEW_CATEGORIES,
  REVIEW_SUBCATEGORIES,
  MATERNITY_HOSPITALS_CATEGORIES,
  LABOR_TYPES
} from 'routes';
import {
  makeSelectReviewCategories,
  makeSelectSingleReviewCategory
} from '../selectors/ReviewCategorySelector';

export function* getReviewCategories() {
  try {
    yield put(getReviewCategoriesFailure(false));
    yield put(setReviewCategoriesLoader(true));
    const response = yield call(request, {
      url: REVIEW_CATEGORIES,
      method: 'get'
    });
    yield put(setReviewCategories(parseObjectToArray(response)));
  } catch (error) {
    yield put(getReviewCategoriesFailure(true));
  } finally {
    yield put(setReviewCategoriesLoader(false));
  }
}

export function* getSingleReviewCategory({ payload }) {
  try {
    const response = yield call(request, {
      url: `${REVIEW_CATEGORIES}/${payload}`,
      method: 'get'
    });
    yield put(setSingleReviewCategory(response));
  } catch (error) {
    console.log(error);
  }
}

export function* editSingleReviewCategory({ payload }) {
  try {
    yield call(request, {
      url: `${REVIEW_CATEGORIES}/${payload.id}`,
      method: 'patch',
      data: payload.values
    });
    history.push(MATERNITY_HOSPITALS_CATEGORIES);
  } catch (error) {
    yield put(
      createReviewCategoryErrorSet({
        active: true,
        message: error.toString()
      })
    );
  }
}

export function* deleteReviewCategory({ payload }) {
  try {
    yield call(request, {
      url: `${REVIEW_CATEGORIES}/${payload}`,
      method: 'delete'
    });
    let reviewCategories = yield select(makeSelectReviewCategories());
    yield put(
      setReviewCategoriesAfterDelete(
        reviewCategories.filter(category => category.id !== payload)
      )
    );
  } catch (error) {
    yield put(deleteRequestReviewCategoryFailure(true));
  }
}

export function* deleteReviewSubcategories({ payload }) {
  try {
    yield call(request, {
      url: `${REVIEW_SUBCATEGORIES}/delete-multiple`,
      method: 'post',
      data: {
        reviewSubcategoryIds: payload
      }
    });
    yield call(getReviewCategories);
    let reviewCategories = yield select(makeSelectReviewCategories());
    yield put(setReviewSubcategoriesAfterDelete(reviewCategories));
  } catch (error) {
    yield put(deleteRequestReviewSubcategoryFailure(true));
  }
}

export function* reviewCategoryCreate({ payload }) {
  try {
    yield call(request, {
      url: REVIEW_CATEGORIES,
      method: 'post',
      data: payload
    });
    history.push(MATERNITY_HOSPITALS_CATEGORIES);
  } catch (error) {
    yield put(
      createReviewCategoryErrorSet({
        active: true,
        message: error.toString()
      })
    );
  }
}

export function* changeReviewCategoriesOrder({ payload }) {
  try {
    yield call(request, {
      url: `${REVIEW_CATEGORIES}/reorder`,
      method: 'post',
      data: payload
    });
    yield put(setIsCategoryChanged(false));
  } catch (error) {
    yield put(setChangeReviewCategoriesOrderError(true));
  }
}

export function* changeReviewSubcategoriesOrder({ payload }) {
  try {
    yield put(setReviewCategoriesLoader(true));
    yield call(request, {
      url: `${REVIEW_SUBCATEGORIES}/reorder`,
      method: 'post',
      data: payload
    });
    yield put(setIsSubcategoryChanged(false));
  } catch (error) {
    yield put(setChangeReviewSubcategoriesOrderError(true));
  } finally {
    yield put(setReviewCategoriesLoader(false));
  }
}

export function* updateCategoriesOrder({ payload }) {
  yield put(setUpdateOrderReviewCategories(payload));
}

export function* getLaborTypes() {
  try {
    const response = yield call(request, {
      url: LABOR_TYPES,
      method: 'get'
    });
    yield put(laborTypesSet(parseObjectToArray(response)));
  } catch (error) {
    console.log(error);
  }
}

export function* createReviewSubcategory({ payload }) {
  try {
    yield put(reviewSubcategoryCreateFailure(false));
    yield call(request, {
      url: REVIEW_SUBCATEGORIES,
      method: 'post',
      data: payload
    });
    yield call(getReviewCategories);
    const reviewCategories = yield select(makeSelectReviewCategories());
    yield put(reviewSubcategoryCreateSuccess([...reviewCategories]));
    history.push(MATERNITY_HOSPITALS_CATEGORIES);
  } catch (error) {
    yield put(reviewSubcategoryCreateFailure(true));
  }
}

export function* addReviewSubcategoryToCategory({ payload }) {
  try {
    yield put(reviewSubcategoryCreateFailure(false));
    yield call(request, {
      url: REVIEW_SUBCATEGORIES,
      method: 'post',
      data: payload
    });
    yield call(getSingleReviewCategory, {
      payload: payload.review_category_id
    });
    const reviewCategory = yield select(makeSelectSingleReviewCategory());
    yield put(setSingleReviewCategory(reviewCategory));
  } catch (error) {
    yield put(reviewSubcategoryCreateFailure(true));
  }
}

export function* getSingleReviewSubcategory({ payload }) {
  try {
    yield put(reviewSubcategorySingleFailure(false));
    const response = yield call(request, {
      url: `${REVIEW_SUBCATEGORIES}/${payload}`,
      method: 'get'
    });
    yield put(reviewSubcategorySingleSet(response));
  } catch (error) {
    yield put(reviewSubcategorySingleFailure(true));
  }
}

export function* updateReviewSubcategory({ payload }) {
  try {
    yield put(reviewSubcategoryUpdateFailure(false));
    const response = yield call(request, {
      url: `${REVIEW_SUBCATEGORIES}/${payload.id}`,
      method: 'put',
      data: payload.values
    });
    yield put(reviewSubcategoryUpdateSuccess(response));
    history.push(MATERNITY_HOSPITALS_CATEGORIES);
  } catch (error) {
    yield put(reviewSubcategoryUpdateFailure(true));
  }
}
