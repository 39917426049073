import produce from 'immer';
import {
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
  CLOSE_SNACKBAR
} from '../actions/ActionTypes';

export const initialState = {
  all: []
};

/* eslint-disable default-case */
const notifierReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ENQUEUE_SNACKBAR:
        draft.all.push({
          key: action.notification.key,
          ...action.notification
        });
        break;
      case CLOSE_SNACKBAR:
        draft.all = draft.all.map(notification => {
          if (action.dismissAll || notification.key === action.key) {
            notification.dismissed = true;
          }
          return notification;
        });
        break;
      case REMOVE_SNACKBAR:
        draft.all = draft.all.filter(
          notification => notification.key !== action.key
        );
        break;
    }
  });

export default notifierReducer;
