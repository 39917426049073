import { call, put } from 'redux-saga/effects';

import request from 'utils/request';
import {
  setMaternityHospitalQuestions,
  setDeleteMaternityHospitalQuestion,
  setUpdateMaternityHospitalQuestion,
  setMaternityHospitalQuestion,
  setMaternityHospitalQuestionCategories,
  setUpdateMaternityHospitalQuestionActive,
  setUpdateMaternityHospitalQuestionFilter
} from 'store/actions/MaternityHospitalQuestionActions';
import { camelCaseToSnakeCaseObject } from 'utils/parseObjectToArray';
import { MATERNITY_HOSPITAL_QUESTIONS } from 'routes';
import history from 'utils/history';
import kebabCase from 'lodash/kebabCase';
import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';
import { enqueueSnackbar } from 'store/actions/NotifierActions';
import messages from 'containers/MaternityHospitalQuestionsPage/messages';

export function* maternityHospitalQuestionsGet() {
  try {
    const questions = yield call(request, {
      url: '/questions',
      method: 'get'
    });
    yield put(setMaternityHospitalQuestions({ ...questions }));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.questionsGetError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* maternityHospitalQuestionCreate({ payload }) {
  try {
    yield call(request, {
      url: '/questions',
      method: 'post',
      data: payload
    });

    history.push(MATERNITY_HOSPITAL_QUESTIONS);
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.questionsCreateError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* maternityHospitalQuestionDelete({ payload }) {
  try {
    yield call(request, {
      url: '/questions/' + payload.id,
      method: 'delete',
      data: payload
    });
    yield put(setDeleteMaternityHospitalQuestion(payload));
    yield call(maternityHospitalQuestionsGet);
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.questionsDeleteError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* maternityHospitalQuestionUpdate({ payload }) {
  try {
    const response = yield call(request, {
      url: '/questions/' + payload.id,
      method: 'put',
      data: payload
    });
    const category = camelCase(response.category.name);
    yield put(
      setUpdateMaternityHospitalQuestion({
        item: camelCaseToSnakeCaseObject(response),
        category
      })
    );
    history.push(MATERNITY_HOSPITAL_QUESTIONS);
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.questionsUpdateError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* maternityHospitalQuestionGet({ payload }) {
  try {
    const response = yield call(request, {
      url: '/questions/' + payload.id,
      method: 'get'
    });
    yield put(
      setMaternityHospitalQuestion(camelCaseToSnakeCaseObject(response))
    );
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.questionsGetError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* getMaternityHospitalQuestionCategories() {
  try {
    const response = yield call(request, {
      url: '/questions/categories',
      method: 'get'
    });
    const payload = mapKeys(response, (_, key) => kebabCase(key));
    yield put(setMaternityHospitalQuestionCategories(payload));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.questionsGetError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* updateMaternityHospitalQuestionActive({ payload }) {
  const category = camelCase(payload.category.name);
  const data = { ...payload, category };

  try {
    yield put(setUpdateMaternityHospitalQuestionActive(data));

    yield call(request, {
      url: '/questions/' + data.id,
      method: 'put',
      data: { active: data.active }
    });
  } catch (error) {
    yield put(
      setUpdateMaternityHospitalQuestionActive({
        ...data,
        active: !data.active
      })
    );
    yield put(
      enqueueSnackbar({
        message: messages.questionsGetError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* updateMaternityHospitalQuestionFilter({ payload }) {
  const category = camelCase(payload.category.name);
  const data = { ...payload, category };

  try {
    yield put(setUpdateMaternityHospitalQuestionFilter(data));

    yield call(request, {
      url: '/questions/' + data.id,
      method: 'put',
      data: { is_filter: data.is_filter }
    });
  } catch (error) {
    yield put(
      setUpdateMaternityHospitalQuestionFilter({
        ...data,
        is_filter: !data.is_filter
      })
    );
    yield put(
      enqueueSnackbar({
        message: messages.questionsGetError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}
