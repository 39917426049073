import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import ReviewSubcategoriesList from './ReviewSubcategories';
import globalMessages from 'messages';
import Loader from 'components/_shared/Loader';
import messages from '../../containers/MaternityHospitalsCategoriesPage/messages';
import { MATERNITY_HOSPITALS_CATEGORIES_SINGLE } from 'routes';

const MaternityHospitalsCategoriesList = ({
  reviewCategories,
  isLoading,
  error,
  onDeleteCategory,
  selectedSubcategories,
  onSelectChange
}) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <div>
        {reviewCategories.map(category => (
          <div key={category.id}>
            <h3 className="c-categories-list--title">
              <Link
                className="u-gap-right-sm"
                to={MATERNITY_HOSPITALS_CATEGORIES_SINGLE.replace(
                  ':id',
                  category.id
                )}
              >
                {category.title.toUpperCase()}
              </Link>
              <button
                className="c-btn c-btn--primary c-btn--sm"
                onClick={() => onDeleteCategory(category.id)}
              >
                {formatMessage(messages.deleteCategory)}
              </button>
            </h3>
            {category.review_subcategories && (
              <ReviewSubcategoriesList
                subcategories={category.review_subcategories}
                selectedSubcategories={selectedSubcategories}
                onSelectChange={onSelectChange}
              />
            )}
            <hr />
          </div>
        ))}
        <Loader
          active={isLoading}
          message={formatMessage(globalMessages.tableDataLoading)}
          className="u-gap-vert u-text-center"
        />
        {error && <h1>{formatMessage(globalMessages.errorFetchingData)}</h1>}
      </div>
    </div>
  );
};

export default MaternityHospitalsCategoriesList;

MaternityHospitalsCategoriesList.propTypes = {
  reviewCategories: PropTypes.array
};
