import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import Select from 'react-select';
import { useDispatch } from 'react-redux';

import messages from '../messages';
import { mhTrophyUpdateRequest } from 'store/actions/MaternityHospitalActions';

const selectValues = categories =>
  categories.map(({ id, title }) => ({
    label: title,
    value: id
  }));

const MaternityHospitalTrophies = ({ item, reviewCategories, isUpdate }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleSelectedItems = selected => {
    setSelectedCategories(selected || []);
  };

  useEffect(() => {
    setSelectedCategories(isUpdate ? selectValues(item.trophies) : []);
  }, [isUpdate, item.trophies]);

  const submitTrophies = categories => {
    const payload = {
      id: item.id,
      trophies: categories.map(category => {
        return {
          hospital_id: item.id,
          review_category_id: category.value
        };
      })
    };
    dispatch(mhTrophyUpdateRequest(payload));
  };

  return (
    <Formik
      initialValues={{ selectedCategories: [] }}
      onSubmit={() => {
        submitTrophies(selectedCategories);
      }}
    >
      {() => (
        <Form>
          <h2 className="c-section-subtitle">
            {formatMessage(messages.trophiesTitle)}
          </h2>
          <Select
            value={selectedCategories}
            options={selectValues(reviewCategories)}
            onChange={selectedItems => handleSelectedItems(selectedItems)}
            isMulti
          />
          <br />
          <div className="c-form-group u-text-right">
            <button
              type="submit"
              className="c-btn c-btn--primary"
              disabled={selectedCategories.length === item.trophies.length}
            >
              {formatMessage(messages.trophiesSave)}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default MaternityHospitalTrophies;

MaternityHospitalTrophies.propTypes = {
  item: PropTypes.object,
  reviewCategories: PropTypes.array,
  isUpdate: PropTypes.bool
};
