import React from 'react';
import { useIntl } from 'react-intl';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';

import MaternityHospitalsTableItem from './MaternityHospitalsTableItem';
import messages from 'containers/MaternityHospitalsPage/messages';
import globalMessages from 'messages';
import Loader from 'components/_shared/Loader';

const MaternityHospitalTable = ({
  maternityHospitals,
  onCheck,
  onItemDelete,
  onLoadMore,
  hasMore,
  total,
  loading
}) => {
  const { formatMessage } = useIntl();

  const renderItems = () =>
    maternityHospitals.map(maternityHospital => (
      <MaternityHospitalsTableItem
        key={maternityHospital.id}
        item={maternityHospital}
        onItemDelete={onItemDelete}
        onCheck={onCheck}
      ></MaternityHospitalsTableItem>
    ));

  const infiniteRef = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore,
    scrollContainer: 'parent'
  });

  return (
    <div className="c-table-scroll js-maternity-hospitals">
      <table className="c-table" ref={infiniteRef}>
        <thead>
          <tr>
            <th>
              {formatMessage(messages.tableHeaderMaternityHospital)} (
              {formatMessage(globalMessages.totalLabel, { total })})
            </th>
            <th className="is-short u-text-center">
              {formatMessage(messages.tableHeaderVisible)}
            </th>
            <th className="is-short u-text-center">
              {formatMessage(messages.tableHeaderActions)}
            </th>
          </tr>
        </thead>
        <tbody>{renderItems()}</tbody>
      </table>
      <Loader
        active={loading}
        message={formatMessage(globalMessages.tableDataLoading)}
        className="u-gap-vert u-text-center"
      />
    </div>
  );
};

export default MaternityHospitalTable;
