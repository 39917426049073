import moment from 'moment';

import { CREATED_AT_FORMAT } from './constants';
import { YEARS_RANGE_START } from 'utils/constants';

export const formatDate = (datetime, format = CREATED_AT_FORMAT) =>
  moment(datetime).format(format);

export const awardYearRange = () => {
  var years = [];
  for (let i = YEARS_RANGE_START; i <= new Date().getFullYear(); i++) {
    years.push(i);
  }
  return years.reverse();
};
