import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import messages from './messages';
import {
  getMaternityHospitalQuestions,
  deleteMaternityHospitalQuestion
} from 'store/actions/MaternityHospitalQuestionActions';
import { makeSelectMaternityHospitalQuestions } from 'store/selectors/MaternityHospitalQuestionsSelector';
import MaternityHospitalQuestionsTable from 'components/MaternityHospitalQuestionsTable';
import { MATERNITY_HOSPITAL_ADD_QUESTION } from 'routes';
import ConfirmationModal from 'components/_shared/ConfirmationModal';
import { makeSelectError } from 'store/selectors/AppSelectors';

const MaternityHospitalQuestionsPage = () => {
  const questions = useSelector(makeSelectMaternityHospitalQuestions());
  const error = useSelector(makeSelectError());

  const dispatch = useDispatch();

  const { formatMessage } = useIntl();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const onConfirm = () => {
    dispatch(deleteMaternityHospitalQuestion(selectedItem));
    setIsOpen(false);
  };

  const onItemDelete = category => id => {
    setIsOpen(true);
    setSelectedItem({ id, category });
  };

  const renderCategory = useCallback(() => {
    return Object.entries(questions)?.map((key, index) =>
      !!key[1].length ? (
        <section key={`${key}-category-questions-${index}`}>
          <h1 className="c-section-title">{key[1][0].category.name}</h1>
          <MaternityHospitalQuestionsTable
            questions={key[1]}
            onItemDelete={onItemDelete(`${key[0]}`)}
          />
          <br />
        </section>
      ) : (
        <></>
      )
    );
  }, [questions]);

  useEffect(() => {
    dispatch(getMaternityHospitalQuestions());
  }, [dispatch, error]);

  return (
    <section>
      <header className="c-section-header">
        <h1 className="c-section-title">
          {formatMessage(messages.questionHeaderTitle)}
        </h1>
        <div className="c-section-actions">
          <Link
            className="c-btn c-btn--primary"
            to={{
              pathname: MATERNITY_HOSPITAL_ADD_QUESTION,
              isUpdate: false
            }}
          >
            {formatMessage(messages.createQuestionButton)}
          </Link>
        </div>
      </header>
      <ConfirmationModal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        onConfirm={onConfirm}
      />
      {questions && renderCategory(questions)}
    </section>
  );
};

export default MaternityHospitalQuestionsPage;
