const initializeForm = (item, isUpdate) =>
  isUpdate
    ? {
        first_name: item.first_name || '',
        last_name: item.last_name || '',
        email: item.email || '',
        password: item.password || '',
        delivery_date: item.delivery_date || ''
      }
    : {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        delivery_date: ''
      };

export default initializeForm;
