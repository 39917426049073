import React from 'react';
import { useIntl } from 'react-intl';

import parseQuestionTypeToInputType from 'utils/parseQuestionTypeToInputType';
import { BOOLEAN } from 'utils/constants';
import globalMessages from 'messages';

const QuestionField = ({ field, type, label, changeHandle, ...props }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="c-question-item">
      <div className="c-question-item__label">{label}</div>

      <div className="c-question-item__answer">
        <div className="c-form-group">
          <label className={type === BOOLEAN ? 'c-form-switch' : ''}>
            {type === BOOLEAN && <>{formatMessage(globalMessages.no)}</>}
            <input
              id={field.name}
              className={
                type === BOOLEAN ? 'c-form-switch__input' : 'c-form-control'
              }
              type={parseQuestionTypeToInputType(type)}
              name={field.name}
              {...props}
              {...field}
              checked={field.value}
              {...changeHandle}
            />
            {type === BOOLEAN && (
              <>
                <span className="c-form-switch__toggle"></span>
                {formatMessage(globalMessages.yes)}
              </>
            )}
          </label>
        </div>
      </div>
    </div>
  );
};

export default QuestionField;
