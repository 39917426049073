import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { mhRemoveAward } from 'store/actions/MaternityHospitalActions';
import { makeSelectMaternityHospitalAwards } from 'store/selectors/MaternityHospitalSelector';
import messages from '../messages';
import Award from './Award';
import Loader from 'components/_shared/Loader';

const AwardList = ({ awards }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const handleAwardDelete = useCallback(id => dispatch(mhRemoveAward({ id })));
  const awardData = useSelector(makeSelectMaternityHospitalAwards());

  const renderAwards = () =>
    awards.map(award => (
      <Award key={award.id} award={award} onDelete={handleAwardDelete} />
    ));

  return (
    <div>
      <Loader
        active={awardData.removeLoading}
        message={formatMessage(messages.awardLoadingTitle)}
        className="u-gap-vert u-text-center"
      />
      {awardData.removeError && (
        <div>
          <h3>{formatMessage(messages.awardsError)}</h3>
        </div>
      )}
      {!!awards.length && <ul className="c-boxed-list">{renderAwards()}</ul>}
    </div>
  );
};

export default AwardList;
