import { all, takeLatest } from 'redux-saga/effects';

import {
  GET_MATERNITY_HOSPITAL_QUESTIONS,
  FORGOT_PASSWORD_REQUEST,
  LOGIN_REQUEST,
  REGISTER_REQUEST,
  RESET_PASSWORD_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  UPDATE_USER_REQUEST,
  FETCH_AUTHENTICATED_USER_REQUEST,
  LOGOUT_REQUEST,
  GET_MATERNITY_HOSPITALS,
  CREATE_MATERNITY_HOSPITALS,
  ADD_MATERNITY_HOSPITAL_QUESTION,
  DELETE_MATERNITY_HOSPITAL_QUESTION,
  UPDATE_MATERNITY_HOSPITAL_QUESTION,
  DELETE_MATERNITY_HOSPITAL,
  MATERNITY_HOSPITAL_UPLOAD_PHOTO,
  ACTIVATE_DEACTIVATE_MATERNITY_HOSPITAL,
  UPDATE_MATERNITY_HOSPITAL,
  GET_MATERNITY_HOSPITAL_QUESTION,
  MATERNITY_HOSPITAL_QUESTION_CATEGORIES_GET,
  GET_MATERNITY_HOSPITAL,
  GET_MATERNITY_HOSPITALS_BASIC,
  GET_CATEGORIES,
  DELETE_CATEGORY,
  REORDER_CATEGORIES,
  CREATE_CATEGORY,
  ARTICLES_GET,
  ARTICLE_DELETE,
  ARTICLE_TOGGLE,
  UPDATE_CATEGORY,
  GET_CATEGORY,
  ARTICLE_CREATE,
  ARTICLE_UPDATE,
  ARTICLE_ITEM_GET,
  ADMINS_GET,
  USERS_GET,
  USER_DELETE,
  USER_CREATE,
  USER_GET,
  USER_UPDATE,
  USER_FINALIZE_REGISTRATION,
  REVIEW_CATEGORIES_GET_REQUEST,
  REVIEW_CATEGORIES_DELETE_REQUEST,
  REVIEW_SUBCATEGORIES_DELETE_REQUEST,
  REVIEW_CATEGORY_CREATE,
  LABOR_TYPES_REQUEST,
  REVIEW_SUBCATEGORY_CREATE_REQUEST,
  REVIEW_CATEGORIES_GET_SINGLE,
  REVIEW_CATEGORIES_EDIT_SINGLE,
  REVIEW_CATEGORIES_CHANGE_ORDER,
  REVIEW_CATEGORIES_UPDATE_ORDER,
  REVIEW_CATEGORY_ADD_SUBCATEGORY,
  REVIEW_SUBCATEGORIES_CHANGE_ORDER,
  REVIEW_SUBCATEGORY_SINGLE_REQUEST,
  REVIEW_SUBCATEGORY_UPDATE_REQUEST,
  MH_REVIEWS_FETCH_REQUEST,
  MH_REVIEW_DELETE_REQUEST,
  MH_REVIEWS_GET_FOR_USER,
  MH_REVIEW_COMMENT_UPDATE,
  MH_REVIEW_COMMENT_DELETE_PHOTO,
  MATERNITY_HOSPITAL_ADD_AWARD,
  MATERNITY_HOSPITAL_REMOVE_AWARD,
  MH_TROPHY_UPDATE_REQUEST,
  DISPATCH_SET_INITIAL_ARTICLE_CATEGORY,
  MATERNITY_HOSPITAL_SWITCH_GRADE_REQUEST,
  MATERNITY_HOSPITAL_EDIT_GRADE_REQUEST,
  SET_ACTIVATE_DEACTIVATE_MH_REVIEWS,
  UPDATE_MATERNITY_HOSPITAL_QUESTION_ACTIVE,
  UPDATE_MATERNITY_HOSPITAL_QUESTION_FILTER
} from '../actions/ActionTypes';
import {
  maternityHospitalQuestionsGet,
  maternityHospitalQuestionCreate,
  maternityHospitalQuestionDelete,
  maternityHospitalQuestionUpdate,
  maternityHospitalQuestionGet,
  getMaternityHospitalQuestionCategories,
  updateMaternityHospitalQuestionActive,
  updateMaternityHospitalQuestionFilter
} from '../sagas/MaternityHospitalQuestionSaga';
import forgotPassword from '../sagas/ForgotPasswordSaga';
import { authorize } from '../sagas/LoginSaga';
import { register } from '../sagas/RegisterSaga';
import resetPassword from '../sagas/ResetPasswordSaga';
import { updateUser, changePassword } from '../sagas/UserProfileChangeSaga';
import { fetchUser, logout } from './AppSaga';
import {
  maternityHospitalsGet,
  maternityHospitalsCreate,
  maternityHospitalDelete,
  maternityHospitalActivateDeactivate,
  maternityHospitalUploadPhoto,
  maternityHospitalUpdate,
  maternityHospitalGet,
  maternityHospitalsBasicGet,
  addNewAward,
  removeAward,
  updateHospitalTrophies,
  switchHospitalGrade,
  editHospitalGrade
} from './MaternityHospitalSaga';
import {
  categoriesGet,
  categoryDelete,
  categoriesReorder,
  categoryCreate,
  categoryUpdate,
  categoryGet
} from './CategorySaga';
import {
  articlesGet,
  articleDelete,
  articleToggle,
  articleCreate,
  articleUpdate,
  articleItemGet,
  initialArticleCategorySet
} from './ArticleSaga';
import {
  adminsGet,
  usersGet,
  userDelete,
  userGet,
  userUpdate,
  userCreate,
  userFinalizeRegistration
} from './UserSaga';
import {
  getReviewCategories,
  deleteReviewCategory,
  deleteReviewSubcategories,
  reviewCategoryCreate,
  getLaborTypes,
  createReviewSubcategory,
  getSingleReviewCategory,
  editSingleReviewCategory,
  changeReviewCategoriesOrder,
  updateCategoriesOrder,
  addReviewSubcategoryToCategory,
  changeReviewSubcategoriesOrder,
  getSingleReviewSubcategory,
  updateReviewSubcategory
} from './ReviewCategorySaga';
import {
  getHospitalReviews,
  deleteHospitalReview,
  getHospitalReviewsForUser,
  reviewCommentUpdate,
  reviewCommentDeletePhoto,
  maternityHospitalReviewActivateDeactivate
} from './MaternityHospitalReviewSaga';

export default function* rootSaga() {
  yield all([
    takeLatest(GET_MATERNITY_HOSPITAL_QUESTIONS, maternityHospitalQuestionsGet),
    takeLatest(GET_MATERNITY_HOSPITALS, maternityHospitalsGet),
    takeLatest(GET_MATERNITY_HOSPITAL, maternityHospitalGet),
    takeLatest(CREATE_MATERNITY_HOSPITALS, maternityHospitalsCreate),
    takeLatest(UPDATE_MATERNITY_HOSPITAL, maternityHospitalUpdate),
    takeLatest(DELETE_MATERNITY_HOSPITAL, maternityHospitalDelete),
    takeLatest(FORGOT_PASSWORD_REQUEST, forgotPassword),
    takeLatest(LOGIN_REQUEST, authorize),
    takeLatest(REGISTER_REQUEST, register),
    takeLatest(RESET_PASSWORD_REQUEST, resetPassword),
    takeLatest(UPDATE_USER_REQUEST, updateUser),
    takeLatest(CHANGE_PASSWORD_REQUEST, changePassword),
    takeLatest(FETCH_AUTHENTICATED_USER_REQUEST, fetchUser),
    takeLatest(LOGOUT_REQUEST, logout),
    takeLatest(
      ADD_MATERNITY_HOSPITAL_QUESTION,
      maternityHospitalQuestionCreate
    ),
    takeLatest(
      DELETE_MATERNITY_HOSPITAL_QUESTION,
      maternityHospitalQuestionDelete
    ),
    takeLatest(
      UPDATE_MATERNITY_HOSPITAL_QUESTION,
      maternityHospitalQuestionUpdate
    ),
    takeLatest(
      MATERNITY_HOSPITAL_QUESTION_CATEGORIES_GET,
      getMaternityHospitalQuestionCategories
    ),
    takeLatest(
      ACTIVATE_DEACTIVATE_MATERNITY_HOSPITAL,
      maternityHospitalActivateDeactivate
    ),
    takeLatest(MATERNITY_HOSPITAL_UPLOAD_PHOTO, maternityHospitalUploadPhoto),
    takeLatest(GET_MATERNITY_HOSPITAL_QUESTION, maternityHospitalQuestionGet),
    takeLatest(GET_MATERNITY_HOSPITALS_BASIC, maternityHospitalsBasicGet),
    takeLatest(GET_CATEGORIES, categoriesGet),
    takeLatest(DELETE_CATEGORY, categoryDelete),
    takeLatest(REORDER_CATEGORIES, categoriesReorder),
    takeLatest(CREATE_CATEGORY, categoryCreate),
    takeLatest(UPDATE_CATEGORY, categoryUpdate),
    takeLatest(GET_CATEGORY, categoryGet),
    takeLatest(ARTICLES_GET, articlesGet),
    takeLatest(ARTICLE_DELETE, articleDelete),
    takeLatest(ARTICLE_TOGGLE, articleToggle),
    takeLatest(ARTICLE_CREATE, articleCreate),
    takeLatest(ARTICLE_UPDATE, articleUpdate),
    takeLatest(ARTICLE_ITEM_GET, articleItemGet),
    takeLatest(USERS_GET, usersGet),
    takeLatest(ADMINS_GET, adminsGet),
    takeLatest(USER_DELETE, userDelete),
    takeLatest(USER_GET, userGet),
    takeLatest(USER_UPDATE, userUpdate),
    takeLatest(USER_CREATE, userCreate),
    takeLatest(USER_FINALIZE_REGISTRATION, userFinalizeRegistration),
    takeLatest(REVIEW_CATEGORIES_GET_REQUEST, getReviewCategories),
    takeLatest(REVIEW_CATEGORIES_DELETE_REQUEST, deleteReviewCategory),
    takeLatest(REVIEW_SUBCATEGORIES_DELETE_REQUEST, deleteReviewSubcategories),
    takeLatest(REVIEW_CATEGORY_CREATE, reviewCategoryCreate),
    takeLatest(LABOR_TYPES_REQUEST, getLaborTypes),
    takeLatest(REVIEW_SUBCATEGORY_CREATE_REQUEST, createReviewSubcategory),
    takeLatest(REVIEW_CATEGORIES_GET_SINGLE, getSingleReviewCategory),
    takeLatest(REVIEW_CATEGORIES_EDIT_SINGLE, editSingleReviewCategory),
    takeLatest(REVIEW_CATEGORIES_CHANGE_ORDER, changeReviewCategoriesOrder),
    takeLatest(REVIEW_CATEGORIES_UPDATE_ORDER, updateCategoriesOrder),
    takeLatest(REVIEW_CATEGORY_ADD_SUBCATEGORY, addReviewSubcategoryToCategory),
    takeLatest(
      REVIEW_SUBCATEGORIES_CHANGE_ORDER,
      changeReviewSubcategoriesOrder
    ),
    takeLatest(REVIEW_SUBCATEGORY_SINGLE_REQUEST, getSingleReviewSubcategory),
    takeLatest(REVIEW_SUBCATEGORY_UPDATE_REQUEST, updateReviewSubcategory),
    takeLatest(MH_REVIEWS_FETCH_REQUEST, getHospitalReviews),
    takeLatest(MH_REVIEW_DELETE_REQUEST, deleteHospitalReview),
    takeLatest(MH_REVIEWS_GET_FOR_USER, getHospitalReviewsForUser),
    takeLatest(MH_REVIEW_COMMENT_UPDATE, reviewCommentUpdate),
    takeLatest(MH_REVIEW_COMMENT_DELETE_PHOTO, reviewCommentDeletePhoto),
    takeLatest(MATERNITY_HOSPITAL_ADD_AWARD, addNewAward),
    takeLatest(MATERNITY_HOSPITAL_REMOVE_AWARD, removeAward),
    takeLatest(MH_TROPHY_UPDATE_REQUEST, updateHospitalTrophies),
    takeLatest(
      DISPATCH_SET_INITIAL_ARTICLE_CATEGORY,
      initialArticleCategorySet
    ),
    takeLatest(MATERNITY_HOSPITAL_SWITCH_GRADE_REQUEST, switchHospitalGrade),
    takeLatest(MATERNITY_HOSPITAL_EDIT_GRADE_REQUEST, editHospitalGrade),
    takeLatest(
      SET_ACTIVATE_DEACTIVATE_MH_REVIEWS,
      maternityHospitalReviewActivateDeactivate
    ),
    takeLatest(
      UPDATE_MATERNITY_HOSPITAL_QUESTION_ACTIVE,
      updateMaternityHospitalQuestionActive
    ),
    takeLatest(
      UPDATE_MATERNITY_HOSPITAL_QUESTION_FILTER,
      updateMaternityHospitalQuestionFilter
    )
  ]);
}
