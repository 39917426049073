import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { mhReviewGetForUser } from 'store/actions/MaternityHospitalReviewActions';
import {
  makeSelectMaternityHospitalUserReviews,
  makeSelectMaternityHospitalUserReviewsError,
  makeSelectMaternityHospitalReviewsLoader,
  makeSelectUserReviewsPhotoDeleteError,
  makeSelectUserReviewsCommentUpdateError,
  makeSelectMaternityHospitalUserReviewsUser
} from 'store/selectors/MaternityHospitalReviewSelector';
import Loader from 'components/_shared/Loader';
import globalMessages from 'messages';
import messages from '../messages';
import { MATERNITY_HOSPITAL_REVIEWS } from 'routes';
import ReviewsList from './ReviewsList';

const UserReviewsPage = props => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { userId } = useParams();
  const category =
    props.location.state && props.location.state.category
      ? props.location.state.category
      : {};
  const reviewsUser = useSelector(makeSelectMaternityHospitalUserReviewsUser());
  const reviews = useSelector(makeSelectMaternityHospitalUserReviews());
  const isLoaderActive = useSelector(
    makeSelectMaternityHospitalReviewsLoader()
  );
  const reviewGetError = useSelector(
    makeSelectMaternityHospitalUserReviewsError()
  );
  const photoDeleteError = useSelector(makeSelectUserReviewsPhotoDeleteError());
  const commentUpdateError = useSelector(
    makeSelectUserReviewsCommentUpdateError()
  );
  const [error, setError] = useState(false);

  const shouldRender = () => {
    if (reviews.length === 0 || !!reviewsUser === false || error === true) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (reviewGetError || photoDeleteError || commentUpdateError) {
      setError(true);
    }
  }, [reviewGetError, photoDeleteError, commentUpdateError]);

  useEffect(() => {
    if (userId) {
      dispatch(mhReviewGetForUser({ userId }));
    }
  }, [userId]);

  return (
    <div>
      <Loader
        active={isLoaderActive}
        message={formatMessage(globalMessages.tableDataLoading)}
        className="u-gap-vert u-text-center"
      />
      {error && <h1>{formatMessage(messages.userReviewsError)}</h1>}
      {shouldRender() && (
        <div>
          <header className="c-section-header is-sticky">
            <h1 className="c-section-title">
              {formatMessage(messages.reviewUserHeader, {
                userName: `${reviewsUser.first_name} ${reviewsUser.last_name}`
              })}
            </h1>
            <Link
              className="c-btn c-btn--primary"
              to={{
                pathname: MATERNITY_HOSPITAL_REVIEWS,
                state: { category }
              }}
            >
              {formatMessage(messages.backToReviewsBtn)}
            </Link>
          </header>
          <ReviewsList reviews={reviews} />
        </div>
      )}
    </div>
  );
};

export default UserReviewsPage;
