import { defineMessages } from 'react-intl';

export const scope = 'finalize_registration';

export default defineMessages({
  finalizeRegistrationHeaderLabel: {
    id: `${scope}.tableHeader.title`,
    defaultMessage: 'Unesite podatke kako biste završili kreiranje naloga'
  },
  emailInputLabel: {
    id: `${scope}.input_label.email`,
    defaultMessage: 'E-mail adresa'
  },
  passwordInputLabel: {
    id: `${scope}.input_label.password`,
    defaultMessage: 'Lozinka'
  },
  confirmPasswordInputLabel: {
    id: `${scope}.input_label.confirm_password`,
    defaultMessage: 'Potvrdi Lozinku'
  },
  submitButtonLabel: {
    id: `${scope}.button.submit`,
    defaultMessage: 'Sačuvaj'
  },
  passwordMatchLabel: {
    id: `${scope}.password_match.label`,
    defaultMessage: 'Lozinke se moraju poklapati'
  }
});
