import React from 'react';
import { useIntl } from 'react-intl';

import Grade from './Grade';
import messages from 'containers/MaternityHospitalReviews/messages';

const GradesList = ({ subcategoryReviews }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="u-gap-vert">
      <h3 className="c-categories-list--title">
        {formatMessage(messages.reviewGradesTitle)}
      </h3>
      <ul className="c-cagegories-list">
        {subcategoryReviews.map(review => (
          <Grade key={review.id} review={review} />
        ))}
      </ul>
    </div>
  );
};

export default GradesList;
