import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Formik, Form, Field } from 'formik';

import messages from './messages';
import globalMessages from 'messages';
import { STRING, BOOLEAN, NUMBER } from 'utils/constants';
import history from 'utils/history';
import { createMaternityHospitalQuestionSchema } from './validations';
import TextAreaField from 'components/_shared/TextAreaField';

export default function CreateQuestionForm({
  onSubmit,
  isUpdate,
  item,
  categories
}) {
  const { formatMessage } = useIntl();

  const selectCategoryOptions = useMemo(() => {
    let selectCategories = [];
    Object.keys(categories).forEach(function(key) {
      selectCategories.push(
        <option value={categories[key].id} key={categories[key].id}>
          {categories[key].name}
        </option>
      );
    });
    return selectCategories;
  }, [categories]);

  return (
    <Formik
      initialValues={
        isUpdate
          ? {
              id: item.id,
              question: item.question,
              filter_question: item.filter_question || '',
              answer_type: item.answer_type,
              question_category_id: item.question_category_id
            }
          : {
              question: '',
              filter_question: '',
              answer_type: STRING,
              question_category_id: categories[0].id
            }
      }
      enableReinitialize
      validationSchema={createMaternityHospitalQuestionSchema}
      onSubmit={onSubmit}
      isInitialValid={false}
    >
      {({ isValid }) => (
        <Form>
          <section>
            <header className="c-section-header">
              <h2 className="c-section-title">
                {isUpdate
                  ? formatMessage(messages.updateQuestion)
                  : formatMessage(messages.createQuestion)}
              </h2>
            </header>
            <div className="c-form-group c-form-group--required">
              <span>*</span>
              <Field
                className="c-form-control"
                type="question"
                name="question"
                component={TextAreaField}
                rows="5"
                required
                formatmessagemame={messages.questionTitleInputLabel}
              />
            </div>
            <div className="c-form-group c-form-group--required">
              <Field
                className="c-form-control"
                type="filter_question"
                name="filter_question"
                component={TextAreaField}
                rows="5"
                formatmessagemame={messages.filterQuestionTitleInputLabel}
              />
            </div>
            <div className="c-form-group">
              <div>
                <label className="c-control-label" htmlFor="answerType">
                  {formatMessage(messages.answerTypeInputLabel)}
                </label>
                <span>*</span>
              </div>
              <Field className="c-form-control" as="select" name="answer_type">
                <option value={STRING}>
                  {formatMessage(messages.selectOptionString)}
                </option>
                <option value={BOOLEAN}>
                  {formatMessage(messages.selectOptionBoolean)}
                </option>
                <option value={NUMBER}>
                  {formatMessage(messages.selectOpitonNumber)}
                </option>
              </Field>
            </div>

            <div className="c-form-group">
              <div>
                <label
                  className="c-control-label"
                  htmlFor="question_category_id"
                >
                  {formatMessage(messages.questionCategoryInputLabel)}
                </label>
                <span>*</span>
              </div>
              <Field
                className="c-form-control"
                as="select"
                name="question_category_id"
              >
                {selectCategoryOptions}
              </Field>
            </div>

            <div className="c-form-group u-text-right">
              <button
                type="button"
                className="c-btn c-btn--primary u-gap-right"
                onClick={history.goBack}
              >
                {formatMessage(globalMessages.backButton)}
              </button>

              <button
                className="c-btn c-btn--primary"
                type="submit"
                disabled={!isValid}
              >
                {formatMessage(messages.questionButtonSave)}
              </button>
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
}

CreateQuestionForm.propTypes = {
  onSubmit: PropTypes.func,
  isUpdate: PropTypes.bool
};
