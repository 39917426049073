import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  MATERNITY_HOSPITAL_QUESTIONS,
  MATERNITY_HOSPITALS,
  CATEGORIES,
  ARTICLES,
  USERS,
  ADMINS,
  MATERNITY_HOSPITALS_CATEGORIES,
  MATERNITY_HOSPITAL_REVIEWS
} from 'routes';
import { useIntl } from 'react-intl';
import {
  MdQuestionAnswer,
  MdLocationCity,
  MdBook,
  MdStar,
  MdLink,
  MdPerson,
  MdPeople,
  MdTextsms,
  MdBubbleChart,
  MdSpeakerPhone
} from 'react-icons/md';
import { IoIosLogOut } from 'react-icons/io';
import messages from 'messages';

const AppSideBar = ({ onLogout }) => {
  const { formatMessage } = useIntl();
  // const [isTextHidden, setIsTextHidden] = useState(true);

  // const switchTextState = () => setIsTextHidden(!isTextHidden);

  return (
    <nav
      // onMouseEnter={switchTextState}
      // onMouseLeave={switchTextState}
      // onClick={switchTextState}
      className="c-main-nav--open"
    >
      <div>
        <Link
          to={MATERNITY_HOSPITALS}
          className="c-nav-item"
          title={formatMessage(messages.hospitalHeaderTitle)}
        >
          <MdLocationCity />
        </Link>
        <span>
          <Link to={MATERNITY_HOSPITALS} className="c-nav-link">
            {formatMessage(messages.hospitalHeaderTitle)}
          </Link>
        </span>
      </div>
      <div>
        <Link
          to={MATERNITY_HOSPITAL_QUESTIONS}
          className="c-nav-item"
          title={formatMessage(messages.questionHeaderTitle)}
        >
          <MdQuestionAnswer />
        </Link>
        <span>
          <Link to={MATERNITY_HOSPITAL_QUESTIONS} className="c-nav-link">
            {formatMessage(messages.questionHeaderTitle)}
          </Link>
        </span>
      </div>
      <div>
        <Link
          to={MATERNITY_HOSPITALS_CATEGORIES}
          className="c-nav-item"
          title={formatMessage(messages.maternityHospitalsCategoryTitle)}
        >
          <MdBubbleChart />
        </Link>
        <span>
          <Link to={MATERNITY_HOSPITALS_CATEGORIES} className="c-nav-link">
            {formatMessage(messages.maternityHospitalsCategoryTitle)}
          </Link>
        </span>
      </div>
      <div>
        <Link
          to={{
            pathname: MATERNITY_HOSPITAL_REVIEWS,
            state: { category: {} }
          }}
          className="c-nav-item"
          title={formatMessage(messages.maternityHospitalsReviewsTitle)}
        >
          <MdSpeakerPhone />
        </Link>
        <span>
          <Link
            to={{
              pathname: MATERNITY_HOSPITAL_REVIEWS,
              state: { category: {} }
            }}
            className="c-nav-link"
          >
            {formatMessage(messages.maternityHospitalsReviewsTitle)}
          </Link>
        </span>
      </div>
      <div>
        <Link
          to={CATEGORIES}
          className="c-nav-item"
          title={formatMessage(messages.categoriesHeaderTitle)}
        >
          <MdStar />
        </Link>
        <span>
          <Link to={CATEGORIES} className="c-nav-link">
            {formatMessage(messages.categoriesHeaderTitle)}
          </Link>
        </span>
      </div>
      <div>
        <Link
          to={ARTICLES}
          className="c-nav-item"
          title={formatMessage(messages.articlesHeaderTitle)}
        >
          <MdLink />
        </Link>
        <span>
          <Link to={ARTICLES} className="c-nav-link">
            {formatMessage(messages.articlesHeaderTitle)}
          </Link>
        </span>
      </div>
      <div>
        <Link
          to={USERS}
          className="c-nav-item"
          title={formatMessage(messages.usersHeaderTitle)}
        >
          <MdPeople />
        </Link>
        <span>
          <Link to={USERS} className="c-nav-link">
            {formatMessage(messages.usersHeaderTitle)}
          </Link>
        </span>
      </div>
      <div>
        <Link
          to={ADMINS}
          className="c-nav-item"
          title={formatMessage(messages.adminsHeaderTitle)}
        >
          <MdPerson />
        </Link>
        <span>
          <Link to={ADMINS} className="c-nav-link">
            {formatMessage(messages.adminsHeaderTitle)}
          </Link>
        </span>
      </div>
      <div>
        <button className="c-nav-item" onClick={onLogout}>
          <IoIosLogOut />
        </button>
        <span className="c-nav-link" onClick={onLogout}>
          {formatMessage(messages.sideBarLogoutBtn)}
        </span>
      </div>
    </nav>
  );
};

AppSideBar.propTypes = {
  onLogout: PropTypes.func.isRequired
};

export default AppSideBar;
