import React from 'react';

import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { USER_PROFILE, MATERNITY_HOSPITALS } from 'routes';
import messages from './messages';
import LogoImage from 'assets/images/porodilista-logo.png';
import config from 'config';
import { useSelector } from 'react-redux';
import { makeSelectUser } from 'store/selectors/AppSelectors';

function AppBar() {
  const { formatMessage } = useIntl();
  const user = useSelector(makeSelectUser());
  return (
    <header className="l-app-header">
      <Link to={MATERNITY_HOSPITALS}>
        <img className="c-app-logo" src={LogoImage} alt="Porodilista logo" />
      </Link>
      <nav className="c-app-nav">
        <a
          href={
            window.location.protocol === 'https:'
              ? config.frontend.url.https
              : config.frontend.url.http
          }
          target="_blank"
        >
          {formatMessage(messages.goToSite)}
        </a>
        {user.firstName && user.lastName && user.avatar && (
          <Link to={USER_PROFILE}>
            <img src={config.api.publicUrl + user.avatar} />
            {`${user.firstName} ${user.lastName}`}
          </Link>
        )}
      </nav>
    </header>
  );
}

export default AppBar;
