import { defineMessages } from 'react-intl';

export const scope = 'maternity_hospital_questions_page';

export default defineMessages({
  deleteQuestionButton: {
    id: `${scope}.button.delete`,
    defaultMessage: 'Obriši'
  },
  createQuestionButton: {
    id: `${scope}.button.create`,
    defaultMessage: 'Dodaj'
  },
  questionHeaderTitle: {
    id: `${scope}.header.question.title`,
    defaultMessage: 'Pitanja'
  },
  tableHeaderQuestion: {
    id: `${scope}.table.header.question`,
    defaultMessage: 'Pitanje'
  },
  tableHeaderActive: {
    id: `${scope}.table.header.active`,
    defaultMessage: 'Aktivno'
  },
  tableHeaderFilter: {
    id: `${scope}.table.header.filter`,
    defaultMessage: 'Filter'
  },
  tableHeaderActions: {
    id: `${scope}.table.header.actions`,
    defaultMessage: 'Akcije'
  },
  hospitalHeaderTitle: {
    id: `${scope}.header.hospital.title`,
    defaultMessage: 'Porodilišta'
  },
  generalInfoHeader: {
    id: `${scope}.table.header.categoryGeneralInfo`,
    defaultMessage: 'Opšte informacije'
  },
  hospitalFeaturesHeader: {
    id: `${scope}.table.header.categoryHospitalFeatures`,
    defaultMessage: 'Mogućnosti u porodilištu'
  },
  hospitalStayHeader: {
    id: `${scope}.table.header.categoryHospitalStay`,
    defaultMessage: 'Boravak u porodilištu'
  },
  questionsGetError: {
    id: `${scope}.fetch.errorMessage`,
    defaultMessage: 'Došlo je do greške u dobavljanju podataka'
  },
  questionsDeleteError: {
    id: `${scope}.delete.errorMessage`,
    defaultMessage: 'Došlo je do greške prilikom brisanja pitanja'
  },
  questionsCreateError: {
    id: `${scope}.create.errorMessage`,
    defaultMessage: 'Došlo je do greške prilikom kreiranja pitanja'
  },
  questionsUpdateError: {
    id: `${scope}.fetch.errorMessage`,
    defaultMessage: 'Došlo je do greške prilikom izmene pitanja'
  }
});
