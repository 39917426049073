import { defineMessages } from 'react-intl';

export const scope = 'maternity_hospitals_categories_page';

export default defineMessages({
  headerTitle: {
    id: `${scope}.header.title`,
    defaultMessage: 'Kategorije recenzija porodilišta'
  },
  deleteCategory: {
    id: `${scope}.button.delete`,
    defaultMessage: 'Obriši'
  },
  deleteCategoryModalMessage: {
    id: `${scope}.modal.deleteCategory`,
    defaultMessage:
      'Brisanjem kategorije biće obrisane i sve njoj pripadajuće potkategorije. Brisanje je nepovratno.'
  },
  deleteSubcategoryModalMessage: {
    id: `${scope}.modal.deleteSubcategory`,
    defaultMessage: 'Brisanje potkategorija je nepovratno.'
  },
  newReviewCategoryHeaderTitle: {
    id: `${scope}.newCategoryHeader.title`,
    defaultMessage: 'Kreiraj novu kategoriju'
  },
  editReviewCategoryHeaderTitle: {
    id: `${scope}.editCategoryHeader.title`,
    defaultMessage: 'Izmeni kategoriju'
  },
  createReviewCategoryTitle: {
    id: `${scope}.createReviewCategory.title`,
    defaultMessage: 'Ime kategorije'
  },
  createReviewCategorySubmitBtn: {
    id: `${scope}.createReviewCategory.submitBtn`,
    defaultMessage: 'Sačuvaj'
  },
  createReviewCategoryCancelBtn: {
    id: `${scope}.createReviewCategory.cancelBtn`,
    defaultMessage: 'Odustani'
  },
  newReviewCategoryLink: {
    id: `${scope}.createReviewCategory.linkBtn`,
    defaultMessage: 'Nova kategorija'
  },
  reorderCategoriesBtn: {
    id: `${scope}.header.reviewCategoriesOrderBtn`,
    defaultMessage: 'Izmeni redosled kategorija'
  },
  reorderCategoriesRevertBtn: {
    id: `${scope}.reorderCategories.RevertOrderBtn`,
    defaultMessage: 'Resetuj redosled'
  },
  reorderCategoriesSaveBtn: {
    id: `${scope}.reorderCategories.SaveOrderBtn`,
    defaultMessage: 'Primeni'
  },
  reorderCategoriesError: {
    id: `${scope}.reorderCategories.eror`,
    defaultMessage: 'Doslo je do greške.'
  },

  newReviewSubcategoryLink: {
    id: `${scope}.createReviewSubcategory.linkBtn`,
    defaultMessage: 'Nova potkategorija'
  },
  laborType: {
    id: `${scope}.laborTypes`,
    defaultMessage: 'Tip porođaja'
  },
  laborTypeSelectPlaceholder: {
    id: `${scope}.select.laborTypes`,
    defaultMessage: 'Morate odabrati najmanje jedan:'
  },
  selectAllLaborTypes: {
    id: `${scope}.laborTypes.selectall`,
    defaultMessage: 'Odaberi sve tipove porođaja'
  },
  unselectAllLaborTypes: {
    id: `${scope}.laborTypes.unselectall`,
    defaultMessage: 'Ukloni sve tipove porođaja'
  },
  selectReviewCategory: {
    id: `${scope}.select.reviewCategory`,
    defaultMessage: 'Odaberi kategoriju'
  },
  reviewSubcategoryTitle: {
    id: `${scope}.header.reviewSubcategory`,
    defaultMessage: 'Naslov'
  },
  createNewReviewSubcategory: {
    id: `${scope}.header.newReviewSubcategory`,
    defaultMessage: 'Kreiraj novu potkategoriju'
  },
  createNewReviewSubcategoryError: {
    id: `${scope}.error.newReviewSubcategory`,
    defaultMessage:
      'Došlo je do greške prilikom kreiranja potkategorije. Proverite da li su sva polja u formularu popunjena.'
  },
  titleCharacterLimitLabel: {
    id: `${scope}.title.length`,
    defaultMessage: 'Naslov ne sme biti duži od 150 karaktera.'
  },
  addSubcategory: {
    id: `${scope}.title.addSubcategory`,
    defaultMessage: 'Dodaj potkategoriju'
  },
  add: {
    id: `${scope}.button.add`,
    defaultMessage: 'Dodaj'
  },
  updateReviewCategorySubmitBtn: {
    id: `${scope}.updateReviewCategory.submitBtn`,
    defaultMessage: 'Sačuvaj sve izmene i izađi'
  },
  subcategoriesTitle: {
    id: `${scope}.title.subcategories`,
    defaultMessage: 'Potkategorije:'
  },
  editReviewSubcategory: {
    id: `${scope}.header.newReviewSubcategory`,
    defaultMessage: 'Izmeni potkategoriju'
  },
  editReviewSubcategoryError: {
    id: `${scope}.error.newReviewSubcategory`,
    defaultMessage:
      'Došlo je do greške prilikom izmene potkategorije. Proverite da li su sva polja u formularu popunjena.'
  },
  reorderGoBackBtn: {
    id: `${scope}.reorderCategories.goBackBtn`,
    defaultMessage: 'Nazad na kategorije'
  },
  deleteSelected: {
    id: `${scope}.header.deleteSelectedButton`,
    defaultMessage: 'Obriši označeno'
  }
});
