import Yup from 'utils/validations';
import messages from '../messages';
import { NAME_MAX_LENGTH } from '../../../utils/constants';

export const createCategorySchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required()
    .max(NAME_MAX_LENGTH, messages.titleLengthLimitLabel),
  description: Yup.string()
    .trim()
    .required()
    .max(NAME_MAX_LENGTH, messages.descriptionLengthLimitLabel)
});
