import { call, put } from 'redux-saga/effects';
import request from 'utils/request';
import { enqueueSnackbar } from 'store/actions/NotifierActions';
import {
  forgotPasswordSuccess,
  forgotPasswordError
} from '../actions/ForgotPasswordActions';
import messages from 'containers/ForgotPasswordPage/messages';

export default function* forgotPassword({ email, meta: { setErrors } }) {
  try {
    yield call(request, {
      url: '/user/forgot-password',
      method: 'post',
      data: { email, is_from_admin: true }
    });
    yield put(forgotPasswordSuccess());
    yield put(
      enqueueSnackbar({
        message: messages.resetLinkSent
      })
    );
  } catch (error) {
    if (error.status === 422) {
      yield put(
        enqueueSnackbar({
          message: messages.emailNotFound
        })
      );
    } else if (error.status === 404) {
      yield put(
        enqueueSnackbar({
          message: messages.adminNotFound
        })
      );
    } else {
      yield put(
        enqueueSnackbar({
          message: messages.errorOccured
        })
      );
    }
    yield put(forgotPasswordError());
  }
}
