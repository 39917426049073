import { createSelector } from 'reselect';
import { initialState } from '../reducers/CategoryReducer';

const selectDomain = state => state.categories || initialState;

const makeSelectCategories = () =>
  createSelector(selectDomain, substate => substate.categories);

const makeSelectCategoriesOrderBy = () =>
  createSelector(selectDomain, substate => substate.orderBy);

const makeSelectCategoriesLoader = () =>
  createSelector(selectDomain, substate => substate.loader);

const makeUniqueCategoryTitleError = () =>
  createSelector(selectDomain, substate => substate.error);

const makeSelectCategory = () =>
  createSelector(selectDomain, substate => substate.category);

const makeSelectOldTitle = () =>
  createSelector(selectDomain, substate => substate.oldTitle);

export {
  makeSelectCategories,
  makeSelectCategoriesOrderBy,
  makeSelectCategoriesLoader,
  makeUniqueCategoryTitleError,
  makeSelectCategory,
  makeSelectOldTitle
};
