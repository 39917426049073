import {
  GET_MATERNITY_HOSPITAL_QUESTIONS,
  SET_MATERNITY_HOSPITAL_QUESTIONS,
  ADD_MATERNITY_HOSPITAL_QUESTION,
  SET_ADD_MATERNITY_HOSPITAL_QUESTION,
  DELETE_MATERNITY_HOSPITAL_QUESTION,
  SET_DELETE_MATERNITY_HOSPITAL_QUESTION,
  UPDATE_MATERNITY_HOSPITAL_QUESTION,
  SET_UPDATE_MATERNITY_HOSPITAL_QUESTION,
  GET_MATERNITY_HOSPITAL_QUESTION,
  SET_MATERNITY_HOSPITAL_QUESTION,
  MATERNITY_HOSPITAL_QUESTION_CATEGORIES_GET,
  MATERNITY_HOSPITAL_QUESTION_CATEGORIES_SET,
  UPDATE_MATERNITY_HOSPITAL_QUESTION_ACTIVE,
  SET_UPDATE_MATERNITY_HOSPITAL_QUESTION_ACTIVE,
  UPDATE_MATERNITY_HOSPITAL_QUESTION_FILTER,
  SET_UPDATE_MATERNITY_HOSPITAL_QUESTION_FILTER
} from './ActionTypes';

export const getMaternityHospitalQuestions = payload => ({
  type: GET_MATERNITY_HOSPITAL_QUESTIONS,
  payload
});

export const addMaternityHospitalQuestion = payload => ({
  type: ADD_MATERNITY_HOSPITAL_QUESTION,
  payload
});

export const setAddMaternityHospitalQuestion = payload => ({
  type: SET_ADD_MATERNITY_HOSPITAL_QUESTION,
  payload
});
export const setMaternityHospitalQuestions = payload => ({
  type: SET_MATERNITY_HOSPITAL_QUESTIONS,
  payload
});
export const deleteMaternityHospitalQuestion = payload => ({
  type: DELETE_MATERNITY_HOSPITAL_QUESTION,
  payload
});
export const setDeleteMaternityHospitalQuestion = payload => ({
  type: SET_DELETE_MATERNITY_HOSPITAL_QUESTION,
  payload
});
export const updateMaternityHospitalQuestion = payload => ({
  type: UPDATE_MATERNITY_HOSPITAL_QUESTION,
  payload
});
export const setUpdateMaternityHospitalQuestion = payload => ({
  type: SET_UPDATE_MATERNITY_HOSPITAL_QUESTION,
  payload
});

export const getMaternityHospitalQuestion = payload => ({
  type: GET_MATERNITY_HOSPITAL_QUESTION,
  payload
});

export const setMaternityHospitalQuestion = payload => ({
  type: SET_MATERNITY_HOSPITAL_QUESTION,
  payload
});

export const getMaternityHospitalQuestionCategories = () => ({
  type: MATERNITY_HOSPITAL_QUESTION_CATEGORIES_GET
});

export const setMaternityHospitalQuestionCategories = payload => ({
  type: MATERNITY_HOSPITAL_QUESTION_CATEGORIES_SET,
  payload
});

export const updateMaternityHospitalQuestionActive = payload => ({
  type: UPDATE_MATERNITY_HOSPITAL_QUESTION_ACTIVE,
  payload
});

export const setUpdateMaternityHospitalQuestionActive = payload => ({
  type: SET_UPDATE_MATERNITY_HOSPITAL_QUESTION_ACTIVE,
  payload
});

export const updateMaternityHospitalQuestionFilter = payload => ({
  type: UPDATE_MATERNITY_HOSPITAL_QUESTION_FILTER,
  payload
});

export const setUpdateMaternityHospitalQuestionFilter = payload => ({
  type: SET_UPDATE_MATERNITY_HOSPITAL_QUESTION_FILTER,
  payload
});
