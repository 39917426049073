import { defineMessages } from 'react-intl';

export const scope = 'articles';

export default defineMessages({
  articlesHeaderTitle: {
    id: `${scope}.articlesHeaderTitle`,
    defaultMessage: 'Članci'
  },
  tableHeaderArticles: {
    id: `${scope}.articles.tableHeaderTitle`,
    defaultMessage: 'Članci'
  },
  tableHeaderVisible: {
    id: `${scope}.table.header.visible`,
    defaultMessage: 'Prikazati'
  },
  tableHeaderActions: {
    id: `${scope}.table.header.actions`,
    defaultMessage: 'Akcije'
  },
  deleteArticleButton: {
    id: `${scope}.table.deleteArticlesButton`,
    defaultMessage: 'Obriši'
  },
  createArticleButton: {
    id: `${scope}.table.createArticlesButton`,
    defaultMessage: 'Kreiraj Članak'
  },
  saveArticleButton: {
    id: `${scope}.button.articles.save`,
    defaultMessage: 'Sačuvaj'
  },
  infoTitle: {
    id: `${scope}.info.title`,
    defaultMessage: 'Kreiranje članka'
  },
  updateInfoTitle: {
    id: `${scope}.info.updateTitle`,
    defaultMessage: 'Izmena članka'
  },
  bodyLabel: {
    id: `${scope}.body.label`,
    defaultMessage: 'Tekst'
  },
  excerptLabel: {
    id: `${scope}.excerpt.label`,
    defaultMessage: 'Sažet tekst'
  },
  titleLabel: {
    id: `${scope}.title.label`,
    defaultMessage: 'Naslov'
  },
  authorLabel: {
    id: `${scope}.author.label`,
    defaultMessage: 'Autor'
  },
  galleryDescriptionLabel: {
    id: `${scope}.gallery.label`,
    defaultMessage: 'Opis galerije'
  },
  categorySelectLabel: {
    id: `${scope}.hospital.label`,
    defaultMessage: 'Kategorija'
  },
  selectAllHospitals: {
    id: `${scope}.hospitals.all`,
    defaultMessage: 'Veži za sva porodilišta'
  },
  unselectAllHospitals: {
    id: `${scope}.hospitals.none`,
    defaultMessage: 'Ukloni sva porodilišta'
  },
  urlLabel: {
    id: `${scope}.url.article.label`,
    defaultMessage: 'URL članka'
  },
  titleLengthLimitLabel: {
    id: `${scope}.form.label.titleLength`,
    defaultMessage: 'Naslov mora biti kraći od 150 karaktera'
  },
  categorySelectPlaceholder: {
    id: `${scope}.form.label.category`,
    defaultMessage: 'Obavezno polje'
  },
  categoryTableHeader: {
    id: `${scope}.hospital.label`,
    defaultMessage: 'Kategorija'
  },
  articlesGetError: {
    id: `${scope}.fetch.errorMessage`,
    defaultMessage: 'Došlo je do greške u dobavljanju podataka'
  },
  articlesDeleteError: {
    id: `${scope}.delete.errorMessage`,
    defaultMessage: 'Došlo je do greške prilikom brisanja članka'
  },
  articlesCreateError: {
    id: `${scope}.create.errorMessage`,
    defaultMessage: 'Došlo je do greške prilikom kreiranja članka'
  },
  articlesUpdateError: {
    id: `${scope}.fetch.errorMessage`,
    defaultMessage: 'Došlo je do greške prilikom izmene članka'
  }
});
