import Yup from 'utils/validations';
import { NAME_MAX_LENGTH } from '../../../utils/constants';
import messages from '../messages';

export const createArticleSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required()
    .max(NAME_MAX_LENGTH, messages.titleLengthLimitLabel),
  excerpt: Yup.string()
    .trim()
    .required(),
  url: Yup.string()
    .url()
    .trim()
    .required()
});
