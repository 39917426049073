import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import SwitchField from 'components/_shared/SwitchField';
import { formatDate } from '../../../utils/formatDate';
import messages from 'containers/MaternityHospitalReviews/messages';
import { Link } from 'react-router-dom';
import { MATERNITY_HOSPITAL_USER_REVIEWS } from 'routes';

const MaternityHospitalReviewTableItem = ({
  item,
  onItemDelete,
  selectedCategory,
  onCheck
}) => {
  const [isActive, setIsActive] = useState(item.active);
  const { formatMessage } = useIntl();

  const toggleCheck = () => {
    onCheck(item.id, { active: !isActive });
    setIsActive(!isActive);
  };

  return (
    <tr>
      <td>
        <Link
          to={{
            pathname: MATERNITY_HOSPITAL_USER_REVIEWS.replace(
              ':userId',
              item.user.id
            ),
            state: { category: selectedCategory }
          }}
        >{`${item.user.first_name} ${item.user.last_name}`}</Link>
      </td>
      <td>{item.maternity_hospital.name}</td>
      <td>{formatDate(item.created_at)}</td>
      <td>
        <SwitchField id={item.id} checked={isActive} onChange={toggleCheck} />
      </td>
      <td className="u-text-center">
        <button
          className="c-btn c-btn--sm"
          onClick={() => onItemDelete(item.id)}
        >
          {formatMessage(messages.deleteReviewCategory)}
        </button>
      </td>
    </tr>
  );
};

export default MaternityHospitalReviewTableItem;
