const camelCaseToSnakeCase = key =>
  key.replace(/([A-Z])/g, '_$1').toLowerCase();

export const camelCaseToSnakeCaseObject = object => {
  let newObject = {};
  for (let camel in object) {
    newObject[camelCaseToSnakeCase(camel)] = object[camel];
  }
  return newObject;
};
export default object => Object.keys(object).map(element => object[element]);
