import Yup from 'utils/validations';
import messages from '../messages';
import {
  NAME_MAX_LENGTH,
  AWARD_TITLE_MIN,
  AWARD_TITLE_MAX,
  MIN_GRADE,
  MAX_GRADE
} from '../../../utils/constants';

export const createMaternityHospitalSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required()
    .max(NAME_MAX_LENGTH, messages.nameCharacterLimitLabel),
  address: Yup.string().trim(),
  zip: Yup.string().matches(/^[0-9]{5}$/, {
    message: messages.zipError,
    excludeEmptyString: true
  }),
  city: Yup.string().trim(),
  phone_number: Yup.string().trim(),
  website: Yup.string().trim(),
  email: Yup.string()
    .email()
    .trim(),
  number_of_pictures: Yup.number().required(),
  data_validity_contact_person: Yup.string()
    .trim()
    .required(),
  complaint_email: Yup.string()
    .email()
    .trim()
    .required(),
  director: Yup.string()
    .trim()
    .max(NAME_MAX_LENGTH, messages.nameCharacterLimitLabel)
});

export const createNewAwardSchema = Yup.object().shape({
  title: Yup.string()
    .required()
    .min(AWARD_TITLE_MIN, messages.awardTitleMin)
    .max(AWARD_TITLE_MAX, messages.awardTitleMax)
});

export const changeGradeSchema = Yup.object().shape({
  fake_grade: Yup.number()
    .min(MIN_GRADE, messages.minGrade)
    .max(MAX_GRADE, messages.maxGrade)
});
