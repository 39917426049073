import React from 'react';
import { Field, Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { finalizeRegistrationSchema } from './validators';
import messages from './messages';
import TextInputField from 'components/_shared/TextInputField';

const FinalizeRegistrationForm = ({ onSubmit, email }) => {
  const { formatMessage } = useIntl();

  const renderHeader = isValid => (
    <header className="c-section-header is-sticky">
      <h1 className="c-section-title">
        {formatMessage(messages.finalizeRegistrationHeaderLabel)}
      </h1>
    </header>
  );

  return (
    <Formik
      initialValues={{
        email: email || '',
        password: '',
        passwordConfirmation: ''
      }}
      validationSchema={finalizeRegistrationSchema}
      onSubmit={onSubmit}
      isInitialValid={false}
    >
      {({ isValid }) => (
        <Form>
          {renderHeader(isValid)}
          <div className="c-form-group">
            <Field
              className="c-form-control"
              type="email"
              name="email"
              required
              component={TextInputField}
              formatmessagemame={messages.emailInputLabel}
            />
          </div>
          <div className="c-form-group">
            <Field
              className="c-form-control"
              type="password"
              name="password"
              required
              component={TextInputField}
              formatmessagemame={messages.passwordInputLabel}
            />
          </div>
          <div className="c-form-group">
            <Field
              className="c-form-control"
              type="password"
              name="passwordConfirmation"
              required
              component={TextInputField}
              formatmessagemame={messages.confirmPasswordInputLabel}
            />
          </div>
          <div className="c-section-actions">
            <button
              className="c-btn c-btn--primary"
              type="submit"
              disabled={!isValid}
            >
              {formatMessage(messages.submitButtonLabel)}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FinalizeRegistrationForm;

FinalizeRegistrationForm.propTypes = {
  onSubmit: PropTypes.func,
  email: PropTypes.string
};
