import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import messages from 'containers/AdminsPage/messages';
import { USER } from 'routes';
import { makeSelectUser } from 'store/selectors/AppSelectors';

const UsersTableItem = ({ item, onItemDelete, users, mailingListToggled }) => {
  const { formatMessage } = useIntl();

  const currentUser = useSelector(makeSelectUser());
  const isDeleteDisabled = users.length < 2 || item.id === currentUser.id;

  return (
    <tr>
      <td>
        <Link to={USER.replace(':id', item.id)}>
          {item.first_name} {item.last_name}
        </Link>
      </td>
      {mailingListToggled && <td>{item.created_at}</td>}
      <td className="u-text-center">
        {mailingListToggled ? (
          item.email
        ) : (
          <button
            className="c-btn c-btn--sm"
            onClick={() => onItemDelete(item.id)}
            disabled={isDeleteDisabled}
          >
            {formatMessage(messages.deleteUserButton)}
          </button>
        )}
      </td>
    </tr>
  );
};

export default UsersTableItem;
