export const STRING = 'string';
export const BOOLEAN = 'boolean';
export const NUMBER = 'number';
export const MAX_ARTICLE_PHOTOS = 1;
export const MAX_HOSPITAL_PHOTOS = 7;
export const MAX_FEATURED_COUNT = 12;
export const ITEMS_PER_PAGE = 20;
export const DEBOUNCE_TIME = 750;
export const ORDER_BY_NAME = 'name|asc';
export const ORDER_BY_NAME_DESC = 'name|desc';
export const ORDER_BY_ACTIVE = 'active|asc';
export const ORDER_BY_ACTIVE_DESC = 'active|desc';
export const ORDER_BY_TITLE = 'title|asc';
export const ORDER_BY_TITLE_DESC = 'title|desc';
export const ORDER_BY_CREATED_AT = 'created_at|asc';
export const ORDER_BY_CREATED_AT_DESC = 'created_at|desc';
export const ORDER_BY_ORDER = 'order|asc';
export const ORDER_BY_ORDER_DESC = 'order|desc';
export const PASSWORD_MIN_LENGTH = 6;
export const COMMENT_MAX_LENGTH = 60;
export const CREATED_AT_FORMAT = 'DD.MM.YYYY. HH:mm';
export const NAME_MAX_LENGTH = 150;
export const GENERAL_IMPRESSION = 'Opšti utisak';
export const COMMENT_MIN_LENGTH = 5;
export const YEARS_RANGE_START = 1700;
export const AWARD_TITLE_MIN = 5;
export const AWARD_TITLE_MAX = 50;
export const DEFAULT_NUMBER_OF_PICTURES = 25;
export const MAX_GRADE = 5;
export const MIN_GRADE = 1;
export const BIRTH_COUNT_QUESTION = 'Broj porođaja godišnje';
export const HOSPITAL_SMALL = 1000;
export const HOSPITAL_LARGE = 3000;
export const MOTHER_TOTAL_QUESTION = 'Broj mama u sobi';
export const MOTHER_TOTAL_QUESTION_FROM_TO = 'od - do';
