import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import messages from './messages';
import { useIntl } from 'react-intl';
import useDebounce from 'utils/useDebounce';
import { IoIosSearch } from 'react-icons/io';
import ConfirmationModal from 'components/_shared/ConfirmationModal';
import { Link } from 'react-router-dom';
import { DEBOUNCE_TIME } from 'utils/constants';
import { ARTICLE_ADD } from 'routes';
import {
  makeSelectArticles,
  makeSelectArticlesPagination,
  makeSelectArticlesOrderBy,
  makeSelectArticlesLoader
} from 'store/selectors/ArticleSelectors';
import {
  getArticles,
  setArticleOrderBy,
  deleteArticle,
  toggleArticle
} from 'store/actions/ArticleActions';
import ArticlesTable from 'components/Articles';
import FilterSelect from 'components/_shared/FilterSelect';
import { NEWS_OPTIONS } from 'utils/sortOptions';

const ArticlesPage = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const articles = useSelector(makeSelectArticles());
  const pagination = useSelector(makeSelectArticlesPagination());
  const orderBy = useSelector(makeSelectArticlesOrderBy());
  const loading = useSelector(makeSelectArticlesLoader());

  const onConfirm = () => {
    dispatch(
      deleteArticle({
        id: selectedId,
        page: pagination.currentPage,
        orderBy,
        searchValue
      })
    );
    setIsDeleteModalOpen(false);
  };

  const onItemDelete = id => {
    setIsDeleteModalOpen(true);
    setSelectedId(id);
  };

  const onCheck = (id, action) => {
    dispatch(toggleArticle({ id, action }));
  };

  const fetchArticles = useCallback(data => dispatch(getArticles(data)), [
    dispatch
  ]);

  const handleOrderBy = ({ target }) => {
    dispatch(setArticleOrderBy(target.value));
    document.getElementsByClassName('js-articles')[0].scrollTop = 0;
  };

  const onLoadMore = () =>
    fetchArticles({
      page: pagination.currentPage + 1,
      orderBy,
      searchValue
    });

  const onSearchChange = ({ target }) => setSearchValue(target.value);

  const debouncedSearchTerm = useDebounce(searchValue, DEBOUNCE_TIME);

  useEffect(() => {
    fetchArticles({
      page: 1,
      orderBy,
      searchValue
    });
  }, [fetchArticles, debouncedSearchTerm, orderBy]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section>
      <header className="c-section-header">
        <h1 className="c-section-title">
          {formatMessage(messages.articlesHeaderTitle)}
        </h1>
        <div className="c-section-actions">
          <FilterSelect
            orderBy={orderBy}
            handleOrderBy={handleOrderBy}
            options={NEWS_OPTIONS}
          />

          <div className="c-form-group c-search-field c-input-group u-gap-left--sm">
            <input
              type="text"
              className="c-form-control"
              value={searchValue}
              onChange={onSearchChange}
            />
            <span className="c-input-group__addon">
              <IoIosSearch />
            </span>
          </div>

          <Link className="c-btn c-btn--primary u-gap-left" to={ARTICLE_ADD}>
            {formatMessage(messages.createArticleButton)}
          </Link>
        </div>
      </header>
      <ArticlesTable
        articles={articles}
        onItemDelete={onItemDelete}
        onCheck={onCheck}
        onLoadMore={onLoadMore}
        hasMore={pagination.hasMore}
        total={pagination.total}
        loading={loading}
      />
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        onConfirm={onConfirm}
      />
    </section>
  );
};
export default ArticlesPage;
