import React from 'react';
import PropType from 'prop-types';

import { Link } from 'react-router-dom';
import { REVIEW_SUBCATEGORIES_UPDATE } from 'routes';

const ReviewSubcategoriesList = ({
  subcategories,
  selectedSubcategories,
  onSelectChange
}) => {
  return (
    <ul className="c-cagegories-list">
      {subcategories.map(subcategory => (
        <li key={subcategory.id}>
          <input
            checked={selectedSubcategories.includes(subcategory.id)}
            onChange={e => onSelectChange(e, subcategory.id)}
            type="checkbox"
          />
          <Link to={REVIEW_SUBCATEGORIES_UPDATE.replace(':id', subcategory.id)}>
            {subcategory.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default ReviewSubcategoriesList;

ReviewSubcategoriesList.propType = {
  subcategories: PropType.array
};
