import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { useIntl } from 'react-intl';
import Select from 'react-select';

import TextInputField from 'components/_shared/TextInputField';
import messages from '../messages';
import history from 'utils/history';
import { createReviewSubcategorySchema } from './validation';

const laborTypesSelectValues = laborTypes =>
  laborTypes.map(({ id, title }) => ({
    label: title,
    value: id
  }));

const ReviewSubcategoryForm = ({
  laborTypes,
  reviewCategories,
  onSubmit,
  isCategoryUpdate,
  categoryId,
  isSubcategoryUpdate,
  subcategory,
  hideForm
}) => {
  const { formatMessage } = useIntl();
  const [selectedLaborTypes, setSelectedLaborTypes] = useState([]);

  const handleSelectedItems = selected => {
    setSelectedLaborTypes(selected || []);
  };

  const handleSelectAll = () => {
    setSelectedLaborTypes(
      selectedLaborTypes.length === laborTypes.length
        ? []
        : laborTypesSelectValues(laborTypes)
    );
  };

  const selectReviewCategories = reviewCategories.map(category => (
    <option key={category.id} value={category.id}>
      {category.title}
    </option>
  ));

  const initialValues = isSubcategoryUpdate
    ? {
        title: subcategory.title,
        labor_types: [],
        review_category_id: subcategory.reviewCategoryId
      }
    : {
        title: '',
        labor_types: [],
        review_category_id: reviewCategories[0].id
      };

  const setFormValues = values => {
    values.labor_types = selectedLaborTypes.map(({ value }) => value);
    values.review_category_id = isCategoryUpdate
      ? categoryId
      : Number(values.review_category_id);
  };

  const submitForm = async (values, { resetForm }) => {
    setFormValues(values);
    await onSubmit(values);
    if (isCategoryUpdate) {
      resetForm();
      setSelectedLaborTypes([]);
    }
  };

  useEffect(() => {
    setSelectedLaborTypes(
      isSubcategoryUpdate && subcategory.laborTypes
        ? laborTypesSelectValues(subcategory.laborTypes)
        : []
    );
  }, [isSubcategoryUpdate, subcategory.laborTypes]);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        validationSchema={createReviewSubcategorySchema}
        isInitialValid={isSubcategoryUpdate}
        enableReinitialize
      >
        {({ isValid }) => (
          <Form>
            <div className="c-category-required__field-2 u-gap-bottom">
              <span>*</span>
              <Field
                name="title"
                type="text"
                required
                component={TextInputField}
                formatmessagemame={messages.reviewSubcategoryTitle}
              />
            </div>
            <div className="c-form-group">
              <div className="c-form-group--required">
                <span>*</span>
                <label className="c-control-label">
                  {formatMessage(messages.laborType)}
                </label>
              </div>
              <button
                className="c-btn c-btn--primary u-gap-bottom--sm"
                type="button"
                onClick={handleSelectAll}
              >
                {formatMessage(
                  selectedLaborTypes.length === laborTypes.length
                    ? messages.unselectAllLaborTypes
                    : messages.selectAllLaborTypes
                )}
              </button>
              <Select
                value={selectedLaborTypes}
                options={laborTypesSelectValues(laborTypes)}
                onChange={selectedItems => handleSelectedItems(selectedItems)}
                isMulti
                placeholder={formatMessage(messages.laborTypeSelectPlaceholder)}
              />
            </div>
            {!isCategoryUpdate && !isSubcategoryUpdate && (
              <div className="c-form-group">
                <div className="required-field__wrap-1">
                  <span>*</span>
                  <label
                    className="c-control-label"
                    htmlFor="review_category_id"
                  >
                    {formatMessage(messages.selectReviewCategory)}
                  </label>
                </div>
                <Field
                  className="c-form-control"
                  as="select"
                  name="review_category_id"
                >
                  {selectReviewCategories}
                </Field>
              </div>
            )}
            <div className="c-form-group u-text-right">
              <button
                type="button"
                className="c-btn c-btn--primary u-gap-right"
                onClick={isCategoryUpdate ? hideForm : history.goBack}
              >
                {formatMessage(messages.createReviewCategoryCancelBtn)}
              </button>

              <button
                className="c-btn c-btn--primary c-btn--wide"
                disabled={!isValid || !selectedLaborTypes.length}
                type="submit"
              >
                {isCategoryUpdate
                  ? formatMessage(messages.add)
                  : formatMessage(messages.createReviewCategorySubmitBtn)}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ReviewSubcategoryForm;
