import React from 'react';
import Select from 'react-select';
import { useIntl } from 'react-intl';

import messages from '../../containers/MaternityHospitalReviews/messages';

const ReviewCategoryDropdown = ({
  categories,
  onCategoryChange,
  selectedCategory
}) => {
  const { formatMessage } = useIntl();

  const categoriesSelectValues = list =>
    list.map(({ id, title }) => ({
      label: title,
      value: id
    }));

  return (
    <Select
      value={selectedCategory}
      options={categoriesSelectValues(categories)}
      onChange={onCategoryChange}
      placeholder={formatMessage(messages.selectReviewCategory)}
    />
  );
};

export default ReviewCategoryDropdown;
