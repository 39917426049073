import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Formik, Form, Field } from 'formik';
import TextInputField from 'components/_shared/TextInputField';
import { MATERNITY_HOSPITALS_CATEGORIES } from 'routes';
import { createReviewCategorySchema } from 'containers/MaternityHospitalsCategoriesPage/CreateReviewCategory/validation';
import messages from 'containers/MaternityHospitalsCategoriesPage/messages';

const CreateOrUpdateCategoryForm = ({ isEditPage, onSubmit, category }) => {
  const { formatMessage } = useIntl();

  const makeInitialValues = () => ({
    title: (isEditPage && category.title) || ''
  });

  const renderButtons = () => (
    <div className="c-form-group u-text-right u-gap-vert">
      <Link
        className="c-btn c-btn--secondary"
        to={MATERNITY_HOSPITALS_CATEGORIES}
      >
        {formatMessage(messages.createReviewCategoryCancelBtn)}
      </Link>
      <button className="c-btn c-btn--primary u-gap-left" type="submit">
        {isEditPage
          ? formatMessage(messages.updateReviewCategorySubmitBtn)
          : formatMessage(messages.createReviewCategorySubmitBtn)}
      </button>
    </div>
  );

  return (
    <Formik
      initialValues={makeInitialValues()}
      onSubmit={onSubmit}
      validationSchema={createReviewCategorySchema}
      enableReinitialize
    >
      <Form>
        {isEditPage && renderButtons()}
        <div className="c-category-required__field-1">
          <span>*</span>
          <Field
            name="title"
            formatmessagemame={messages.createReviewCategoryTitle}
            component={TextInputField}
          />
        </div>
        {!isEditPage && renderButtons()}
      </Form>
    </Formik>
  );
};

export default CreateOrUpdateCategoryForm;
