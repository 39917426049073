import produce from 'immer';
import {
  USERS_SET,
  USER_SET,
  USER_UPDATE_SET,
  USER_DELETE_SET,
  USER_LOADER_SET,
  USER_CREATE_SET,
  USER_ERROR,
  USER_UNIQUE_EMAIL_ERROR,
  ADMINS_SET
} from '../actions/ActionTypes';

export const initialState = {
  users: [],
  admins: [],
  loader: false,
  error: false,
  user: {},
  uniqueEmailError: false
};

const usersReducer = (state = initialState, action) =>
  produce(state, draft => {
    const { type, payload } = action;
    switch (type) {
      case USERS_SET:
        draft.users = payload;
        break;
      case USER_SET:
        draft.user = payload;
        break;
      case ADMINS_SET:
        draft.admins = payload;
        break;
      case USER_UPDATE_SET:
        draft.users = draft.users.map(user =>
          user.id === payload.id ? payload : user
        );
        break;
      case USER_DELETE_SET:
        draft.users = draft.users.filter(user => user.id !== payload);
        break;
      case USER_LOADER_SET:
        draft.loader = payload;
        break;
      case USER_CREATE_SET:
        draft.users = [...draft.users, payload];
        break;
      case USER_ERROR:
        draft.error = payload;
        break;
      case USER_UNIQUE_EMAIL_ERROR:
        draft.uniqueEmailError = payload;
        break;
    }
  });

export default usersReducer;
