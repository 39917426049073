import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { CSVLink } from 'react-csv';
import moment from 'moment';

import {
  makeSelectUsers,
  makeSelectUsersLoader
} from 'store/selectors/UserSelectors';
import { getUsers, deleteUser } from 'store/actions/UserActions';
import messages from './messages';
import ConfirmationModal from 'components/_shared/ConfirmationModal';
import UsersTable from 'components/Users';

const UsersPage = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [mailingListToggled, setMailingListToggled] = useState(false);

  const users = useSelector(makeSelectUsers());
  const loading = useSelector(makeSelectUsersLoader());

  const onConfirm = () => {
    dispatch(deleteUser({ id: selectedId }));
    setIsDeleteModalOpen(false);
  };

  const onItemDelete = id => {
    setIsDeleteModalOpen(true);
    setSelectedId(id);
  };

  const fetchUsers = () => dispatch(getUsers());

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const parseCsvData = () => {
    const data = [['E-mail', 'Datum kreiranja naloga']];
    users.forEach(user =>
      data.push([
        user.email,
        moment(user.created_at).format('DD.MM.YYYY HH:mm')
      ])
    );
    return data;
  };

  return (
    <section>
      <header className="c-section-header">
        <h1 className="c-section-title">
          {formatMessage(messages.usersHeaderTitle)}
        </h1>
        <div>
          {!!users.length && (
            <CSVLink
              className="c-btn c-btn--primary u-gap-right"
              data={parseCsvData()}
              filename={`bebac-user-emails-${moment().format(
                'YYYY_MM_DD_HH_mm_ss'
              )}`}
            >
              {formatMessage(messages.exportCsvBtnLabel)}
            </CSVLink>
          )}
          <button
            className="c-btn c-btn--primary"
            onClick={() => setMailingListToggled(!mailingListToggled)}
            type="button"
          >
            {!mailingListToggled
              ? formatMessage(messages.mailingListBtnLabel)
              : formatMessage(messages.userActionsBtnLabel)}
          </button>
        </div>
      </header>
      <div></div>
      <UsersTable
        users={users}
        onItemDelete={onItemDelete}
        loading={loading}
        mailingListToggled={mailingListToggled}
      />
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        onConfirm={onConfirm}
        modalText={formatMessage(messages.modalDialogText)}
      />
    </section>
  );
};

export default UsersPage;
