import { createSelector } from 'reselect';
import { initialState } from '../reducers/ArticleReduces';

const selectDomain = state => state.articles || initialState;

const makeSelectArticles = () =>
  createSelector(selectDomain, substate => substate.articles);

const makeSelectArticlesItem = () =>
  createSelector(selectDomain, substate => substate.articleItem);

const makeSelectArticlesPagination = () =>
  createSelector(selectDomain, substate => substate.pagination);

const makeSelectArticlesOrderBy = () =>
  createSelector(selectDomain, substate => substate.orderBy);

const makeSelectArticlesLoader = () =>
  createSelector(selectDomain, substate => substate.loader);

const makeSelectArticlesInitialCategory = () =>
  createSelector(selectDomain, substate => substate.initialCategory);

export {
  makeSelectArticles,
  makeSelectArticlesItem,
  makeSelectArticlesPagination,
  makeSelectArticlesOrderBy,
  makeSelectArticlesLoader,
  makeSelectArticlesInitialCategory
};
