import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { createCategorySchema } from './validations';
import messages from '../messages';
import globalMessages from 'messages';
import history from 'utils/history';
import PhotoUpload from 'components/_shared/PhotoUpload';
import { MAX_ARTICLE_PHOTOS } from 'utils/constants';
import { uploadMaternityHospitalPhoto } from 'store/actions/MaternityHospitalActions';
import { useSelector } from 'react-redux';
import {
  makeUniqueCategoryTitleError,
  makeSelectOldTitle
} from 'store/selectors/CategorySelectors';
import initializeForm from './init';
import TextInputField from 'components/_shared/TextInputField';
import TextAreaField from 'components/_shared/TextAreaField';

export default function CreateCategoryForm({ onSubmit, item, isUpdate }) {
  const { formatMessage } = useIntl();
  const [isPendingPhoto, setIsPendingPhoto] = useState(false);
  const oldTitle = useSelector(makeSelectOldTitle());
  const error = useSelector(makeUniqueCategoryTitleError());

  const addPhotoUrl = (values, setFieldValue) => url => {
    setFieldValue('photoArray', [...values.photoArray, ...url]);
    setIsPendingPhoto(false);
    const fileElem = document.getElementById('file')
    if (fileElem) {
      fileElem.value = ''
    }
  };
  const onDelete = (values, setFieldValue) => url => {
    setFieldValue(
      'photoArray',
      values.photoArray.filter(photo => photo !== url)
    );
    setIsPendingPhoto(false);
    if (values.photoArray.length < MAX_ARTICLE_PHOTOS)
      document.getElementById('file').value = '';
  };

  const disableSubmit = isValid => isPendingPhoto || !isValid;

  const handleSubmit = values => {
    onSubmit({ ...values, oldTitle });
  };

  return (
    <Formik
      initialValues={initializeForm(item, isUpdate)}
      enableReinitialize
      validationSchema={createCategorySchema}
      onSubmit={handleSubmit}
      isInitialValid={false}
    >
      {({ isValid, values, setFieldValue }) => {
        return (
          <Form>
            <section>
              <header className="c-section-header">
                <h2 className="c-section-title">
                  {isUpdate
                    ? formatMessage(messages.updateCategory)
                    : formatMessage(messages.createCategoriesHeaderTitle)}
                </h2>
              </header>

              <div className="c-form-group">
                <div className="c-category-required__field-3">
                  <span>*</span>
                  <Field
                    className="c-form-control"
                    type="text"
                    name="title"
                    component={TextInputField}
                    required
                    formatmessagemame={messages.titleLabel}
                  />
                </div>
                {error && (
                  <div className="u-text-error">
                    {formatMessage(messages.uniqueCategoryTitleError)}
                  </div>
                )}
              </div>

              <div className="c-form-group">
                <div className="c-category-required__field-4">
                  <span>*</span>
                  <Field
                    className="c-form-control"
                    type="text"
                    name="description"
                    component={TextAreaField}
                    rows="3"
                    required
                    formatmessagemame={messages.descriptionLabel}
                  />
                </div>
              </div>
              <section>
                <header>
                  <h2 className="c-section-subtitle">
                    {formatMessage(globalMessages.photos)} (
                    {values.photoArray.length}/{1})
                  </h2>
                </header>
                <PhotoUpload
                  photos={values.photoArray}
                  limit={MAX_ARTICLE_PHOTOS}
                  action={uploadMaternityHospitalPhoto}
                  onDelete={onDelete(values, setFieldValue)}
                  photoUrls={values.photoArray}
                  addPhotoUrl={addPhotoUrl(values, setFieldValue)}
                  setIsPendingPhoto={setIsPendingPhoto}
                />
              </section>
              <div className="c-form-group u-text-right">
                <button
                  type="button"
                  className="c-btn c-btn--primary u-gap-right"
                  onClick={history.goBack}
                >
                  {formatMessage(globalMessages.backButton)}
                </button>

                <button
                  className="c-btn c-btn--primary"
                  type="submit"
                  disabled={disableSubmit(isValid)}
                >
                  {formatMessage(messages.saveCategoriesButton)}
                </button>
              </div>
            </section>
          </Form>
        );
      }}
    </Formik>
  );
}

CreateCategoryForm.propTypes = {
  onSubmit: PropTypes.func,
  item: PropTypes.object
};
