import { defineMessages } from 'react-intl';

export const scope = 'app';

export default defineMessages({
  logoAltImage: {
    id: `${scope}.logo.alt_image`,
    defaultMessage: 'Logo'
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Porodilišta | Admin Panel'
  },
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Učitavanje...'
  }
});
