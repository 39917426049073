import React from 'react';
import { useIntl } from 'react-intl';
import { Field, ErrorMessage } from 'formik';

export default function TextInputField({
  field,
  form,
  formatmessagemame,
  autoComplete,
  ...props
}) {
  const { formatMessage } = useIntl();

  return (
    <div className="c-form-group">
      <label className="c-control-label" htmlFor={field.name}>
        {formatMessage(formatmessagemame)}
      </label>
      <Field
        className="c-form-control"
        name={field.name}
        {...props}
        autoComplete={autoComplete}
      />
      {form.errors[field.name] && (
        <p className="u-text-error">
          <ErrorMessage name={field.name}>
            {msg =>
              formatMessage(msg, {
                label: formatMessage(formatmessagemame)
              })
            }
          </ErrorMessage>
        </p>
      )}
    </div>
  );
}
