const initializeForm = (item, isUpdate) =>
  isUpdate
    ? {
        id: item.id || '',
        title: item.title || '',
        description: item.description || '',
        photoArray: (item.photos && item.photos.map(({ url }) => url)) || []
      }
    : {
        id: '',
        title: '',
        description: '',
        photoArray: []
      };

export default initializeForm;
