import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'utils/history';
import appReducer from 'store/reducers/AppReducer';
import languageProviderReducer from './LanguageProviderReducer';
import maternityHospitalQuestionReducer from './MaternityHospitalQuestionReducer';
import dashboardReducer from './DashboardReducer';
import forgotPasswordReducer from './ForgotPasswordReducer';
import maternityHospitalReducer from './MaternityHospitalReducer';
import notifierReducer from './NotifierReducer';
import categoriesReducer from './CategoryReducer';
import articleReducer from './ArticleReduces';
import usersReducer from './UserReducer';
import reviewCategoriesReducer from './ReviewCategoryReducer';
import mhReviewReducer from './MaternityHospitalReviewReducer';

export default combineReducers({
  app: appReducer,
  language: languageProviderReducer,
  router: connectRouter(history),
  maternityHospitalQuestions: maternityHospitalQuestionReducer,
  maternityHospitals: maternityHospitalReducer,
  dashboard: dashboardReducer,
  forgotPassword: forgotPasswordReducer,
  notifications: notifierReducer,
  categories: categoriesReducer,
  articles: articleReducer,
  users: usersReducer,
  reviewCategories: reviewCategoriesReducer,
  maternityHospitalReviews: mhReviewReducer
});
