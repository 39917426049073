import { call, put, select } from 'redux-saga/effects';

import {
  mhReviewsFetchSuccess,
  mhReviewSetLoader,
  mhReviewsFetchFailure,
  mhDeleteSuccess,
  mhReviewSetForUser,
  mhReviewGetForUserError,
  mhReviewUpdateCommentSet,
  mhReviewDeletePhotoSuccess,
  mhReviewUpdateCommentError,
  mhReviewDeletePhotoError,
  mhReviewSetSingleUser,
  mhReviewSetActiveDeactive,
  mhReviewSetActiveDeactiveSuccess
} from 'store/actions/MaternityHospitalReviewActions';
import { enqueueSnackbar } from 'store/actions/NotifierActions';
import { makeSelectMaternityHospitalReviews } from '../selectors/MaternityHospitalReviewSelector';
import messages from '../../containers/MaternityHospitalReviews/messages';
import request from 'utils/request';
import { camelCaseToSnakeCaseObject } from 'utils/parseObjectToArray';

const ENDPOINTS = {
  SINGLE_REVIEW: '/maternity-hospitals/categories/reviews/:id',
  USER_REVIEWS: 'maternity-hospitals/categories/reviews/user/:userId'
};

export function* getHospitalReviews({ payload }) {
  try {
    yield put(mhReviewsFetchFailure(false));
    yield put(mhReviewSetLoader(true));
    const response = yield call(request, {
      url: `/maternity-hospitals/categories/reviews?page=${
        payload.page
      }${payload.category &&
        payload.category.value &&
        `&category=${payload.category.value}`}`,
      method: 'get'
    });
    yield put(mhReviewsFetchSuccess(response));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.errorFetchingData,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  } finally {
    yield put(mhReviewSetLoader(false));
  }
}

export function* deleteHospitalReview({ payload }) {
  try {
    yield call(request, {
      url: `/maternity-hospitals/categories/reviews/${payload.id}`,
      method: 'delete'
    });
    const reviews = yield select(makeSelectMaternityHospitalReviews());
    yield put(
      mhDeleteSuccess(reviews.filter(review => review.id !== payload.id))
    );
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.deleteReviewCategoryError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* getHospitalReviewsForUser({ payload }) {
  try {
    yield put(mhReviewGetForUserError(false));
    yield put(mhReviewSetLoader(true));
    const response = yield call(request, {
      url: ENDPOINTS.USER_REVIEWS.replace(':userId', payload.userId),
      method: 'get'
    });
    yield put(mhReviewSetForUser(response.reviews));
    yield put(mhReviewSetSingleUser(response.user));
  } catch (error) {
    yield put(mhReviewGetForUserError(true));
  } finally {
    yield put(mhReviewSetLoader(false));
  }
}

export function* reviewCommentUpdate({ payload }) {
  try {
    const response = yield call(request, {
      url:
        ENDPOINTS.SINGLE_REVIEW.replace(':id', payload.review_id) +
        '/update-comment',
      method: 'patch',
      data: payload
    });
    yield put(mhReviewUpdateCommentSet(response));
  } catch (error) {
    yield put(mhReviewUpdateCommentError(true));
  }
}

export function* reviewCommentDeletePhoto({ payload }) {
  try {
    const response = yield call(request, {
      url: ENDPOINTS.SINGLE_REVIEW.replace(':id', payload.id) + '/delete-photo',
      method: 'post',
      data: payload
    });
    yield put(mhReviewDeletePhotoSuccess(response));
  } catch (error) {
    yield put(mhReviewDeletePhotoError(true));
  }
}

export function* maternityHospitalReviewActivateDeactivate({ payload }) {
  try {
    const response = yield call(request, {
      url: ENDPOINTS.SINGLE_REVIEW.replace(':id', payload.id),
      method: 'patch',
      data: { active: payload.action.active }
    });
    yield put(
      mhReviewSetActiveDeactiveSuccess(camelCaseToSnakeCaseObject(response))
    );
  } catch (error) {
    console.log(error);
  }
}
