import { call, put } from 'redux-saga/effects';

import {
  setArticleLoader,
  setArticlesAtCurrentPage,
  setArticles,
  setDeleteArticle,
  setToggleArticle,
  setCreateArticle,
  setUpdateArticle,
  setArticleItem,
  setInitialArticleCategory
} from 'store/actions/ArticleActions';
import { ARTICLES, ARTICLES_SINGLE } from 'routes';
import request from 'utils/request';
import history from 'utils/history';
import { enqueueSnackbar } from 'store/actions/NotifierActions';
import messages from 'containers/ArticlesPage/messages';

export function* articlesGet({ payload }) {
  try {
    yield put(setArticleLoader(true));
    const [order, direction] = payload.orderBy.split('|');

    const response = yield call(request, {
      url: `${ARTICLES}?page=${payload.page}&orderBy=${order}&direction=${direction}&search=${payload.searchValue}`,
      method: 'get'
    });

    payload.reloadCurrentPage
      ? yield put(setArticlesAtCurrentPage(response))
      : yield put(setArticles(response));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.articlesGetError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  } finally {
    yield put(setArticleLoader(false));
  }
}

export function* articleDelete({ payload }) {
  try {
    const { id, page, orderBy, searchValue } = payload;
    yield call(request, {
      url: ARTICLES_SINGLE.replace(':id', id),
      method: 'delete'
    });
    yield put(setDeleteArticle(id));
    yield call(articlesGet, {
      payload: {
        page,
        orderBy,
        searchValue,
        reloadCurrentPage: true
      }
    });
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.articlesDeleteError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* articleToggle({ payload }) {
  try {
    const response = yield call(request, {
      url: ARTICLES_SINGLE.replace(':id', payload.id),
      method: 'patch',
      data: payload.action
    });
    yield put(setToggleArticle(response));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.articlesUpdateError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* articleCreate({ payload }) {
  try {
    const response = yield call(request, {
      url: ARTICLES,
      method: 'post',
      data: payload
    });
    yield put(setCreateArticle(response));
    history.push(ARTICLES);
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.articlesCreateError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* articleUpdate({ payload }) {
  try {
    const response = yield call(request, {
      url: ARTICLES_SINGLE.replace(':id', payload.id),
      method: 'put',
      data: payload
    });
    yield put(setUpdateArticle(response));
    history.push(ARTICLES);
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.articlesUpdateError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* articleItemGet({ payload }) {
  try {
    const response = yield call(request, {
      url: ARTICLES_SINGLE.replace(':id', payload),
      method: 'get'
    });
    yield put(setArticleItem(response));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.articlesGetError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* initialArticleCategorySet({ payload }) {
  yield put(setInitialArticleCategory(payload));
}
