import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import CreateQuestionForm from './CreateQuestionForm';
import {
  addMaternityHospitalQuestion,
  updateMaternityHospitalQuestion,
  getMaternityHospitalQuestion,
  getMaternityHospitalQuestionCategories
} from 'store/actions/MaternityHospitalQuestionActions';
import {
  makeSelectMaternityHospitalQuestion,
  makeSelectMaternityHospitalQuestionCategories
} from 'store/selectors/MaternityHospitalQuestionsSelector';

function CreteMaterityHospitalQuestionPage() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isUpdate = id !== 'new';
  const item = useSelector(makeSelectMaternityHospitalQuestion());
  const questionCategories = useSelector(
    makeSelectMaternityHospitalQuestionCategories()
  );

  useEffect(() => {
    dispatch(getMaternityHospitalQuestionCategories());
    if (isUpdate) dispatch(getMaternityHospitalQuestion({ id }));
  }, [dispatch, isUpdate, id]);

  const submitCreateQuestionForm = useCallback(
    data => {
      dispatch(
        isUpdate
          ? updateMaternityHospitalQuestion(data)
          : addMaternityHospitalQuestion(data)
      );
    },
    [dispatch, isUpdate]
  );

  return (
    <div>
      <Helmet>
        <title>Dodaj Pitanje</title>
      </Helmet>

      {Object.keys(questionCategories).length && (
        <CreateQuestionForm
          onSubmit={submitCreateQuestionForm}
          item={item}
          isUpdate={isUpdate}
          categories={questionCategories}
        />
      )}
    </div>
  );
}

export default CreteMaterityHospitalQuestionPage;
