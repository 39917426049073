import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { useIntl } from 'react-intl';

import {
  updateOrderReviewSubcategories,
  changeReviewSubcategoriesOrder
} from 'store/actions/ReviewCategoryActions';
import {
  makeSelectReviewSubcategoryReorderError,
  makeSelectReviewCategoriesLoader,
  makeSelectIsSubcategoryChanged
} from 'store/selectors/ReviewCategorySelector';
import messages from 'containers/MaternityHospitalsCategoriesPage/messages';
import Loader from 'components/_shared/Loader';
import globalMessages from 'messages';

const ReviewSubcategoriesReorderList = ({
  categoryId,
  reviewSubcategories,
  fetchCategory
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const reorderError = useSelector(makeSelectReviewSubcategoryReorderError());
  const isLoading = useSelector(makeSelectReviewCategoriesLoader());
  const areSubcatsChanged = useSelector(makeSelectIsSubcategoryChanged());

  const SortableItem = SortableElement(({ value }) => <li>{value}</li>);

  const SortableList = SortableContainer(() => (
    <ul className="c-review-category-reorder">
      {reviewSubcategories.map((cat, index) => (
        <SortableItem key={cat.id} index={index} value={cat.title} />
      ))}
    </ul>
  ));

  const onReorderSubmit = () => {
    dispatch(
      changeReviewSubcategoriesOrder({
        category_id: categoryId,
        subcategories: reviewSubcategories
      })
    );
  };

  const onReorderCancel = () => {
    fetchCategory();
  };

  const renderListButtons = () => (
    <div>
      <button
        className="c-btn c-btn--secondary u-gap-left"
        disabled={!areSubcatsChanged}
        onClick={onReorderCancel}
        type="button"
      >
        {formatMessage(messages.reorderCategoriesRevertBtn)}
      </button>
      <button
        className="c-btn c-btn--primary u-gap-left"
        disabled={!areSubcatsChanged}
        onClick={onReorderSubmit}
        type="button"
      >
        {formatMessage(messages.reorderCategoriesSaveBtn)}
      </button>
    </div>
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(updateOrderReviewSubcategories({ oldIndex, newIndex }));
  };

  if (reorderError) {
    return (
      <div>
        <h3>{formatMessage(messages.reorderCategoriesError)}</h3>
      </div>
    );
  }

  return reviewSubcategories ? (
    <div>
      <SortableList onSortEnd={onSortEnd} />
      {renderListButtons()}
    </div>
  ) : (
    <Loader
      active={isLoading}
      message={formatMessage(globalMessages.tableDataLoading)}
      className="u-gap-vert u-text-center"
    />
  );
};

export default ReviewSubcategoriesReorderList;
