import {
  MH_REVIEWS_FETCH_REQUEST,
  MH_REVIEWS_FETCH_SUCCESS,
  MH_REVIEWS_FETCH_FAILURE,
  MH_REVIEW_SET_LOADER,
  MH_REVIEW_DELETE_REQUEST,
  MH_REVIEW_DELETE_SUCCESS,
  MH_REVIEWS_GET_FOR_USER,
  MH_REVIEW_SET_USER_FOR_REVIEWS,
  MH_REVIEWS_SET_FOR_USER,
  MH_REVIEWS_GET_FOR_USER_ERROR_SET,
  MH_REVIEW_COMMENT_UPDATE,
  MH_REVIEW_COMMENT_UPDATE_SET,
  MH_REVIEW_COMMENT_UPDATE_ERROR,
  MH_REVIEW_COMMENT_DELETE_PHOTO,
  MH_REVIEW_COMMENT_DELETE_PHOTO_ERROR,
  MH_REVIEW_COMMENT_DELETE_PHOTO_SUCCESS,
  SET_ACTIVATE_DEACTIVATE_MH_REVIEWS,
  SET_ACTIVATE_DEACTIVATE_MH_REVIEWS_SUCCESS
} from './ActionTypes';

export const mhReviewsFetchRequest = payload => ({
  type: MH_REVIEWS_FETCH_REQUEST,
  payload
});

export const mhReviewsFetchSuccess = payload => ({
  type: MH_REVIEWS_FETCH_SUCCESS,
  payload
});

export const mhReviewsFetchFailure = payload => ({
  type: MH_REVIEWS_FETCH_FAILURE,
  payload
});

export const mhReviewSetLoader = payload => ({
  type: MH_REVIEW_SET_LOADER,
  payload
});

export const mhDeleteRequest = payload => ({
  type: MH_REVIEW_DELETE_REQUEST,
  payload
});

export const mhDeleteSuccess = payload => ({
  type: MH_REVIEW_DELETE_SUCCESS,
  payload
});

export const mhReviewGetForUser = payload => ({
  type: MH_REVIEWS_GET_FOR_USER,
  payload
});

export const mhReviewSetForUser = payload => ({
  type: MH_REVIEWS_SET_FOR_USER,
  payload
});

export const mhReviewGetForUserError = payload => ({
  type: MH_REVIEWS_GET_FOR_USER_ERROR_SET,
  payload
});

export const mhReviewUpdateComment = payload => ({
  type: MH_REVIEW_COMMENT_UPDATE,
  payload
});

export const mhReviewUpdateCommentSet = payload => ({
  type: MH_REVIEW_COMMENT_UPDATE_SET,
  payload
});

export const mhReviewUpdateCommentError = payload => ({
  type: MH_REVIEW_COMMENT_UPDATE_ERROR,
  payload
});

export const mhReviewDeletePhoto = payload => ({
  type: MH_REVIEW_COMMENT_DELETE_PHOTO,
  payload
});

export const mhReviewDeletePhotoError = payload => ({
  type: MH_REVIEW_COMMENT_DELETE_PHOTO_ERROR,
  payload
});

export const mhReviewDeletePhotoSuccess = payload => ({
  type: MH_REVIEW_COMMENT_DELETE_PHOTO_SUCCESS,
  payload
});

export const mhReviewSetSingleUser = payload => ({
  type: MH_REVIEW_SET_USER_FOR_REVIEWS,
  payload
});

export const mhReviewSetActiveDeactive = payload => ({
  type: SET_ACTIVATE_DEACTIVATE_MH_REVIEWS,
  payload
});

export const mhReviewSetActiveDeactiveSuccess = payload => ({
  type: SET_ACTIVATE_DEACTIVATE_MH_REVIEWS_SUCCESS,
  payload
});
