import Yup from 'utils/validations';
import messages from '../messages';
import { PASSWORD_MIN_LENGTH, NAME_MAX_LENGTH } from '../../../utils/constants';

export const createUserSchema = Yup.object().shape({
  first_name: Yup.string()
    .trim()
    .required()
    .max(NAME_MAX_LENGTH, messages.firstNameLength),
  last_name: Yup.string()
    .trim()
    .required()
    .max(NAME_MAX_LENGTH, messages.lastNameLength),
  email: Yup.string()
    .email()
    .required(),
  password: Yup.string().min(
    PASSWORD_MIN_LENGTH,
    messages.passwordLengthMessage
  ),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    messages.passwordMatchLabel
  )
});
