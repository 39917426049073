import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { useIntl } from 'react-intl';

import {
  changeReviewCategoriesOrder,
  updateOrderReviewCategories,
  getReviewCategoriesRequest
} from 'store/actions/ReviewCategoryActions';
import {
  makeSelectReviewCategories,
  makeSelectIsCategoryChanged
} from '../../store/selectors/ReviewCategorySelector';
import messages from '../../containers/MaternityHospitalsCategoriesPage/messages';
import { makeSelectReviewCategoryReorderError } from 'store/selectors/ReviewCategorySelector';

const ReviewCategoryReorderList = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const areCategoriesChanged = useSelector(makeSelectIsCategoryChanged());
  const reviewCategories = useSelector(makeSelectReviewCategories());
  const reviewCategoriesReorderError = useSelector(
    makeSelectReviewCategoryReorderError()
  );

  const SortableItem = SortableElement(({ value }) => <li>{value}</li>);

  const SortableList = SortableContainer(() => (
    <ul className="c-review-category-reorder">
      {reviewCategories.map((cat, index) => (
        <SortableItem key={cat.id} index={index} value={cat.title} />
      ))}
    </ul>
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(updateOrderReviewCategories({ oldIndex, newIndex }));
  };

  const onReorderSubmit = () => {
    dispatch(changeReviewCategoriesOrder({ categories: reviewCategories }));
  };

  const onReorderCancel = () => {
    dispatch(getReviewCategoriesRequest());
  };

  const renderListButtons = () => (
    <div>
      <button
        className="c-btn c-btn--secondary u-gap-left"
        disabled={!areCategoriesChanged}
        onClick={onReorderCancel}
        type="button"
      >
        {formatMessage(messages.reorderCategoriesRevertBtn)}
      </button>
      <button
        className="c-btn c-btn--primary u-gap-left"
        disabled={!areCategoriesChanged}
        onClick={onReorderSubmit}
        type="button"
      >
        {formatMessage(messages.reorderCategoriesSaveBtn)}
      </button>
    </div>
  );

  if (reviewCategoriesReorderError) {
    return (
      <div>
        <h2>{formatMessage(messages.reorderCategoriesError)}</h2>
      </div>
    );
  } else {
    return (
      reviewCategories && (
        <div>
          <SortableList onSortEnd={onSortEnd} />
          {renderListButtons()}
        </div>
      )
    );
  }
};

export default ReviewCategoryReorderList;
