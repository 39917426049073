import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from 'containers/PrivateRoute';
import PublicRoute from 'containers/PublicRoute';
import Dashboard from 'containers/Dashboard/Loadable';
import UserProfilePage from 'containers/UserProfilePage/Loadable';
import LoginPage from 'containers/LoginPage/Loadable';
import ForgotPasswordPage from 'containers/ForgotPasswordPage/Loadable';
import ResetPasswordPage from 'containers/ResetPasswordPage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import {
  WELCOME,
  DASHBOARD,
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  USER_PROFILE,
  MATERNITY_HOSPITAL_QUESTIONS,
  MATERNITY_HOSPITALS,
  MATERNITY_HOSPITALS_CREATE,
  MATERNITY_HOSPITAL_ADD_QUESTION,
  MATERNITY_HOSPITAL_UPDATE_QUESTION,
  MATERNITY_HOSPITALS_SINGLE,
  CATEGORIES,
  CATEGORY_CREATE,
  ARTICLES,
  CATEGORIES_SINGLE,
  ARTICLE_ADD,
  ARTICLES_SINGLE,
  USERS,
  USER_CREATE,
  USER,
  FINALIZE_REGISTRATION,
  ADMINS,
  MATERNITY_HOSPITALS_CATEGORIES,
  MATERNITY_HOSPITALS_CATEGORIES_CREATE,
  MATERNITY_HOSPITALS_CATEGORIES_REORDER,
  REVIEW_SUBCATEGORIES_ADD,
  MATERNITY_HOSPITALS_CATEGORIES_SINGLE,
  REVIEW_SUBCATEGORIES_UPDATE,
  MATERNITY_HOSPITAL_REVIEWS,
  MATERNITY_HOSPITAL_USER_REVIEWS
} from 'routes';
import MaternityHospitalQuestionsPage from 'containers/MaternityHospitalQuestionsPage';
import MaternityHospitalsPage from 'containers/MaternityHospitalsPage';
import CreateMaternityHospitalPage from 'containers/MaternityHospitalsPage/CreateMaternityHospitalPage';
import CreteMaterityHospitalQuestionPage from 'containers/MaternityHospitalQuestionsPage/CreateQuestion';
import CategoriesPage from 'containers/CategoriesPage';
import CreateCategoryPage from 'containers/CategoriesPage/CreateCategory';
import ArticlesPage from 'containers/ArticlesPage';
import CreateArticlePage from 'containers/ArticlesPage/CreateArticle';
import UsersPage from 'containers/UsersPage';
import AdminsPage from 'containers/AdminsPage';
import CreateUserPage from 'containers/AdminsPage/UserCreate';
import FinalizeRegistrationPage from 'containers/FinalizeRegistrationPage';
import MaternityHospitalsCategoriesPage from 'containers/MaternityHospitalsCategoriesPage';
import CreateReviewCategory from 'containers/MaternityHospitalsCategoriesPage/CreateReviewCategory';
import ReviewCategoryReorder from 'containers/MaternityHospitalsCategoriesPage/ReviewCategoryReorder';
import CreateReviewSubcategory from 'containers/MaternityHospitalsCategoriesPage/CreateReviewSubcategory';
import MaternityHospitalReviewsPage from 'containers/MaternityHospitalReviews';
import UserReviewsPage from 'containers/MaternityHospitalReviews/UserReviewsPage';

export default function Routes() {
  return (
    <Switch>
      <PublicRoute exact path={WELCOME} component={LoginPage} />
      <PrivateRoute exact path={DASHBOARD} component={Dashboard} />
      <PrivateRoute
        exact
        path={MATERNITY_HOSPITALS}
        component={MaternityHospitalsPage}
      />
      <PrivateRoute
        exact
        path={MATERNITY_HOSPITALS_CREATE}
        component={CreateMaternityHospitalPage}
      />
      <PrivateRoute exact path={ARTICLES} component={ArticlesPage} />
      <PrivateRoute exact path={ARTICLE_ADD} component={CreateArticlePage} />
      <PrivateRoute
        exact
        path={ARTICLES_SINGLE}
        component={CreateArticlePage}
      />
      <PrivateRoute
        exact
        path={MATERNITY_HOSPITALS_CATEGORIES}
        component={MaternityHospitalsCategoriesPage}
      />
      <PrivateRoute
        exact
        path={MATERNITY_HOSPITALS_CATEGORIES_REORDER}
        component={ReviewCategoryReorder}
      />
      <PrivateRoute
        exact
        path={MATERNITY_HOSPITALS_CATEGORIES_CREATE}
        component={CreateReviewCategory}
      />
      <PrivateRoute
        exact
        path={REVIEW_SUBCATEGORIES_ADD}
        component={CreateReviewSubcategory}
      />
      <PrivateRoute
        exact
        path={REVIEW_SUBCATEGORIES_UPDATE}
        component={CreateReviewSubcategory}
      />
      <PrivateRoute
        exact
        path={MATERNITY_HOSPITALS_CATEGORIES_SINGLE}
        component={CreateReviewCategory}
      />
      <PrivateRoute
        exact
        path={MATERNITY_HOSPITAL_REVIEWS}
        component={MaternityHospitalReviewsPage}
      />
      <PrivateRoute
        exact
        path={MATERNITY_HOSPITAL_USER_REVIEWS}
        component={UserReviewsPage}
      />
      <PrivateRoute
        exact
        path={MATERNITY_HOSPITALS_SINGLE}
        component={CreateMaternityHospitalPage}
      />
      <PrivateRoute
        exact
        path={MATERNITY_HOSPITAL_QUESTIONS}
        component={MaternityHospitalQuestionsPage}
      />
      <PrivateRoute
        exact
        path={MATERNITY_HOSPITAL_UPDATE_QUESTION}
        component={CreteMaterityHospitalQuestionPage}
      />
      <PrivateRoute exact path={USER_PROFILE} component={UserProfilePage} />
      <PrivateRoute
        exact
        path={MATERNITY_HOSPITAL_ADD_QUESTION}
        component={CreteMaterityHospitalQuestionPage}
      />
      <PrivateRoute exact path={CATEGORIES} component={CategoriesPage} />
      <PrivateRoute
        exact
        path={CATEGORY_CREATE}
        component={CreateCategoryPage}
      />
      <PrivateRoute
        exact
        path={CATEGORIES_SINGLE}
        component={CreateCategoryPage}
      />
      <PrivateRoute exact path={USERS} component={UsersPage} />
      <PrivateRoute exact path={ADMINS} component={AdminsPage} />
      <PrivateRoute exact path={USER_CREATE} component={CreateUserPage} />
      <PrivateRoute exact path={USER} component={CreateUserPage} />
      <PublicRoute exact path={LOGIN} component={LoginPage} />
      <PublicRoute
        exact
        path={FINALIZE_REGISTRATION}
        component={FinalizeRegistrationPage}
      />
      <PublicRoute
        exact
        path={FORGOT_PASSWORD}
        component={ForgotPasswordPage}
      />
      <PublicRoute exact path={RESET_PASSWORD} component={ResetPasswordPage} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
}
