import { call, put } from 'redux-saga/effects';
import request from 'utils/request';
import history from 'utils/history';

import {
  setUsers,
  setUser,
  setUserLoader,
  setDeleteUser,
  setUpdateUser,
  setCreateUser,
  userError,
  userUniqueEmailError,
  setAdmins
} from 'store/actions/UserActions';
import { USERS, USER, LOGIN, ADMINS } from 'routes';
import parseObjectToArray, {
  camelCaseToSnakeCaseObject
} from 'utils/parseObjectToArray';
import messages from 'containers/UserProfilePage/messages';
import { enqueueSnackbar } from 'store/actions/NotifierActions';

export function* adminsGet() {
  try {
    yield put(setUserLoader(true));
    const response = yield call(request, {
      url: ADMINS,
      method: 'get'
    });
    yield put(setAdmins(parseObjectToArray(response)));
  } catch (error) {
    yield put(userError(error));
  } finally {
    yield put(setUserLoader(false));
  }
}

export function* usersGet() {
  try {
    yield put(setUserLoader(true));
    const response = yield call(request, {
      url: USERS,
      method: 'get'
    });
    yield put(setUsers(parseObjectToArray(response)));
  } catch (error) {
    yield put(userError(error));
  } finally {
    yield put(setUserLoader(false));
  }
}

export function* userDelete({ payload }) {
  try {
    yield call(request, {
      url: USER.replace(':id', payload.id),
      method: 'delete',
      data: payload
    });
    yield put(setDeleteUser(payload.id));
    yield call(adminsGet);
  } catch (error) {
    yield put(userError(error));
  }
}

export function* userGet({ payload }) {
  try {
    const response = yield call(request, {
      url: USER.replace(':id', payload),
      method: 'get'
    });
    yield put(setUser(camelCaseToSnakeCaseObject(response)));
  } catch (error) {
    yield put(userError(error));
  }
}

export function* userUpdate({ payload }) {
  if (!payload.password) {
    delete payload.password;
  }

  try {
    const response = yield call(request, {
      url: USER.replace(':id', payload.id),
      method: 'put',
      data: payload
    });
    yield put(setUpdateUser(response));
    yield put(
      enqueueSnackbar({
        message: messages.saved,
        variant: 'info'
      })
    );
    payload.role === 'USER' ? history.push(USERS) : history.push(ADMINS);
  } catch (error) {
    yield put(userError(error));
  }
}

export function* userCreate({ payload }) {
  try {
    const response = yield call(request, {
      url: '/admin/user-create',
      method: 'post',
      data: payload
    });
    yield put(setCreateUser(response));
    yield put(userUniqueEmailError(false));
    history.push(ADMINS);
  } catch (error) {
    yield put(userUniqueEmailError(true));
  }
}

export function* userFinalizeRegistration({
  payload: { email, password, passwordConfirmation, adminToken }
}) {
  try {
    yield call(request, {
      url: '/admin/finalize-registration',
      method: 'post',
      data: {
        email,
        password,
        password_confirmation: passwordConfirmation,
        adminToken
      }
    });
    history.push(LOGIN);
  } catch (error) {
    yield put(userError(error));
  }
}
