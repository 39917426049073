import { call, put } from 'redux-saga/effects';

import request from 'utils/request';
import history from 'utils/history';

import { ORDER_BY_ORDER } from 'utils/constants';
import {
  setCategoriesLoader,
  setCategories,
  setDeleteCategory,
  setReorderCategories,
  setCreateCategory,
  setUniqueCategoryTitleError,
  setUpdateCategory,
  setCategory
} from 'store/actions/CategoryActions';
import {
  CATEGORIES,
  CATEGORIES_SINGLE,
  CATEGORIES_REORDER,
  CATEGORIES_UNIQUE
} from 'routes';
import parseObjectToArray, {
  camelCaseToSnakeCaseObject
} from 'utils/parseObjectToArray';
import { enqueueSnackbar } from 'store/actions/NotifierActions';
import messages from 'containers/CategoriesPage/messages';

export function* categoriesGet({ orderBy = ORDER_BY_ORDER, searchValue = '' }) {
  try {
    yield put(setCategoriesLoader(true));
    const [order, direction] = orderBy.split('|');
    const response = yield call(request, {
      url: `${CATEGORIES}?orderBy=${order}&direction=${direction}&search=${searchValue}`,
      method: 'get'
    });
    yield put(setCategories(parseObjectToArray(response)));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.getCategoryError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  } finally {
    yield put(setCategoriesLoader(false));
  }
}

export function* categoryDelete({ payload }) {
  try {
    yield call(request, {
      url: CATEGORIES_SINGLE.replace(':id', payload.id),
      method: 'delete',
      data: payload
    });
    yield put(setDeleteCategory(payload.id));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.deleteCategoryError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* categoriesReorder({ payload }) {
  try {
    yield put(setReorderCategories(payload));
    const response = yield call(request, {
      url: CATEGORIES_REORDER,
      method: 'post',
      data: payload
    });
    yield put(setReorderCategories(parseObjectToArray(response)));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.reorderCategoryError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* categoryCreate({ payload }) {
  try {
    const unique = yield call(request, {
      url: `${CATEGORIES_UNIQUE}?title=${payload.title}`,
      method: 'get'
    });
    if (!unique.unique && payload.oldTitle !== payload.title) {
      yield put(setUniqueCategoryTitleError(true));
      return;
    }
    const response = yield call(request, {
      url: CATEGORIES,
      method: 'post',
      data: payload
    });
    yield put(setCreateCategory(response));
    yield put(setUniqueCategoryTitleError(false));
    history.push(CATEGORIES);
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.createCategoryError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* categoryGet({ payload }) {
  try {
    const response = yield call(request, {
      url: CATEGORIES_SINGLE.replace(':id', payload),
      method: 'get'
    });
    yield put(setCategory(camelCaseToSnakeCaseObject(response)));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.getCategoryError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* categoryUpdate({ payload }) {
  try {
    const unique = yield call(request, {
      url: `${CATEGORIES_UNIQUE}?title=${payload.title}`,
      method: 'get'
    });
    if (!unique.unique && payload.oldTitle !== payload.title) {
      yield put(setUniqueCategoryTitleError(true));
      return;
    }
    const response = yield call(request, {
      url: CATEGORIES_SINGLE.replace(':id', payload.id),
      method: 'put',
      data: payload
    });
    yield put(setUpdateCategory(response));
    yield put(setUniqueCategoryTitleError(false));
    history.push(CATEGORIES);
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.updateCategoryError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}
