import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import ConfirmationModal from 'components/_shared/ConfirmationModal';
import messages from '../messages';

const Award = ({ award, onDelete }) => {
  const { formatMessage } = useIntl();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteConfirm = () => {
    onDelete(award.id);
    setIsDeleteModalOpen(false);
  };

  return (
    <div>
      <li className="u-gap-vert">
        {`${award.year} - ${award.title}`}
        <button
          className="c-btn c-btn--sm u-gap-left--sm"
          onClick={() => setIsDeleteModalOpen(true)}
          type="button"
        >
          {formatMessage(messages.awardDeleteBtn)}
        </button>
      </li>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteConfirm}
        modalText={formatMessage(messages.deleteModalText)}
      />
    </div>
  );
};

export default Award;
