import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import CreateArticleForm from './CreateArticleForm';
import { getCategories } from 'store/actions/CategoryActions';
import { makeSelectCategories } from 'store/selectors/CategorySelectors';
import { makeSelectArticlesItem } from 'store/selectors/ArticleSelectors';
import {
  getArticleItem,
  updateArticle,
  createArticle
} from 'store/actions/ArticleActions';
import { ORDER_BY_CREATED_AT_DESC } from 'utils/constants';

function CreateArticlePage() {
  const { id } = useParams();
  const isUpdate = id || false;
  const dispatch = useDispatch();
  const articleItem = useSelector(makeSelectArticlesItem());
  const categories = useSelector(makeSelectCategories());
  const submitCreateArticleForm = useCallback(
    data => dispatch(id ? updateArticle(data) : createArticle(data)),
    [dispatch, id]
  );

  useEffect(() => {
    if (id) {
      dispatch(getArticleItem(id));
    }
    dispatch(
      getCategories({
        page: 1,
        orderBy: ORDER_BY_CREATED_AT_DESC,
        searchValue: ''
      })
    );
  }, [dispatch, id]);

  return (
    <CreateArticleForm
      onSubmit={submitCreateArticleForm}
      categories={categories}
      item={articleItem}
      isUpdate={isUpdate}
    />
  );
}

export default CreateArticlePage;
