import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import CreateUserForm from './CreateUserForm';
import { updateUser, getUser, createUser } from 'store/actions/UserActions';
import { makeSelectUser } from 'store/selectors/UserSelectors';

function CreateUserPage() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isUpdate = !!id || false;
  const userItem = useSelector(makeSelectUser());
  const role = userItem.role;

  const submitCreateUserForm = useCallback(
    data => dispatch(id ? updateUser({ ...data, id, role }) : createUser(data)),
    [dispatch, id, role]
  );

  useEffect(() => {
    if (id) {
      dispatch(getUser(id));
    }
  }, [dispatch, id]);

  return (
    <div>
      <CreateUserForm
        onSubmit={submitCreateUserForm}
        item={userItem}
        isUpdate={isUpdate}
      />
      <br />
    </div>
  );
}

export default CreateUserPage;
