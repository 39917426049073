import {
  GET_CATEGORIES,
  SET_CATEGORIES,
  SET_CATEGORIES_LOADER,
  SET_CATEGORIES_ORDER_BY,
  SET_REORDER_CATEGORIES,
  DELETE_CATEGORY,
  SET_DELETE_CATEGORY,
  REORDER_CATEGORIES,
  CREATE_CATEGORY,
  SET_CREATE_CATEGORY,
  UNIQUE_CATEGORY_TITLE_ERROR,
  UPDATE_CATEGORY,
  SET_UPDATE_CATEGORY,
  GET_CATEGORY,
  SET_CATEGORY
} from '../actions/ActionTypes';

export const getCategories = ({ page, orderBy, searchValue }) => ({
  type: GET_CATEGORIES,
  page,
  orderBy,
  searchValue
});

export const setCategories = payload => ({
  type: SET_CATEGORIES,
  payload
});

export const setCategoriesLoader = payload => ({
  type: SET_CATEGORIES_LOADER,
  payload
});

export const setCategoriesOrderBy = payload => ({
  type: SET_CATEGORIES_ORDER_BY,
  payload
});

export const setReorderCategories = payload => ({
  type: SET_REORDER_CATEGORIES,
  payload
});

export const deleteCategory = payload => ({
  type: DELETE_CATEGORY,
  payload
});

export const setDeleteCategory = payload => ({
  type: SET_DELETE_CATEGORY,
  payload
});

export const reorderCategories = payload => ({
  type: REORDER_CATEGORIES,
  payload
});

export const createCategory = payload => ({
  type: CREATE_CATEGORY,
  payload
});

export const setCreateCategory = payload => ({
  type: SET_CREATE_CATEGORY,
  payload
});

export const setUniqueCategoryTitleError = payload => ({
  type: UNIQUE_CATEGORY_TITLE_ERROR,
  payload
});

export const updateCategory = payload => ({
  type: UPDATE_CATEGORY,
  payload
});

export const setUpdateCategory = payload => ({
  type: SET_UPDATE_CATEGORY,
  payload
});

export const getCategory = payload => ({
  type: GET_CATEGORY,
  payload
});

export const setCategory = payload => ({
  type: SET_CATEGORY,
  payload
});
