export const WELCOME = '/';
export const DASHBOARD = '/dashboard';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const USER_PROFILE = '/profile';
export const MATERNITY_HOSPITAL_QUESTIONS = '/maternity-hospital-questions';
export const MATERNITY_HOSPITALS = '/maternity-hospitals';
export const MATERNITY_HOSPITALS_AWARDS = '/maternity-hospitals/awards';
export const MATERNITY_HOSPITALS_PAGINATED = '/maternity-hospitals/paginated';
export const MATERNITY_HOSPITALS_CREATE = '/maternity-hospitals/new';
export const MATERNITY_HOSPITALS_SINGLE = '/maternity-hospitals/:id';
export const MATERNITY_HOSPITALS_CATEGORIES =
  '/maternity-hospitals/review-categories';
export const MATERNITY_HOSPITALS_CATEGORIES_CREATE =
  '/maternity-hospitals/review-categories/create';
export const MATERNITY_HOSPITALS_CATEGORIES_SINGLE =
  '/maternity-hospitals/review-categories/:id';
export const MATERNITY_HOSPITALS_CATEGORIES_REORDER =
  '/maternity-hospitals/review-categories/reorder';
export const MATERNITY_HOSPITAL_ADD_QUESTION =
  '/maternity-hospital-questions/new';
export const MATERNITY_HOSPITAL_UPDATE_QUESTION =
  '/maternity-hospital-questions/:id';
export const UPLOAD_PHOTO = '/photos';
export const CATEGORY_CREATE = '/categories/new';
export const CATEGORIES = '/categories';
export const CATEGORIES_SINGLE = '/categories/:id';
export const CATEGORIES_REORDER = '/categories/reorder';
export const CATEGORIES_UNIQUE = '/categories/unique';
export const ARTICLES = '/articles';
export const ARTICLE_ADD = '/articles/new';
export const ARTICLES_SINGLE = '/articles/:id';
export const ADMINS = '/admins';
export const USERS = '/users';
export const USER = '/users/:id';
export const USER_CREATE = '/users/new';
export const FINALIZE_REGISTRATION = '/finalize-registration';
export const REVIEW_CATEGORIES = '/review-categories';
export const REVIEW_SUBCATEGORIES_ADD = '/review-subcategories/new';
export const REVIEW_SUBCATEGORIES = '/review-subcategories';
export const REVIEW_SUBCATEGORIES_UPDATE = '/review-subcategories/:id';
export const LABOR_TYPES = '/labor-types';
export const MATERNITY_HOSPITAL_REVIEWS = '/maternity-hospitals/reviews';
export const MATERNITY_HOSPITAL_USER_REVIEWS =
  '/users/:userId/maternity-hospital-reviews';
export const MATERNITY_HOSPITAL_TROPHIES = '/maternity-hospitals/:id/trophies';
export const MATERNITY_HOSPITAL_SWITCH_GRADE =
  '/maternity-hospitals/:id/switchGrade';
export const MATERNITY_HOSPITAL_EDIT_GRADE =
  '/maternity-hospitals/:id/editGrade';
