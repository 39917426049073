import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { getReviewCategoriesRequest } from '../../../store/actions/ReviewCategoryActions';
import {
  makeSelectReviewCategoriesFetchError,
  makeSelectReviewCategoriesLoader
} from '../../../store/selectors/ReviewCategorySelector';
import globalMessages from 'messages';
import messages from '../messages';
import Loader from 'components/_shared/Loader';
import ReviewCategoryReorderList from 'components/MaternityHospitalsCategories/ReviewCategoryReorderList';
import { MATERNITY_HOSPITALS_CATEGORIES } from 'routes';

const ReviewCategoryReorder = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const isLoading = useSelector(makeSelectReviewCategoriesLoader());
  const error = useSelector(makeSelectReviewCategoriesFetchError());

  useEffect(() => {
    dispatch(getReviewCategoriesRequest());
  }, []);

  return (
    <div>
      <header className="c-section-header">
        <h1 className="c-section-title">
          {formatMessage(messages.reorderCategoriesBtn)}
        </h1>
        <Link
          className="c-btn c-btn--secondary u-gap-left"
          to={MATERNITY_HOSPITALS_CATEGORIES}
        >
          {formatMessage(messages.reorderGoBackBtn)}
        </Link>
      </header>
      <ReviewCategoryReorderList />
      <Loader
        active={isLoading}
        message={formatMessage(globalMessages.tableDataLoading)}
        className="u-gap-vert u-text-center"
      />
      {error && <h1>{formatMessage(globalMessages.errorFetchingData)}</h1>}
    </div>
  );
};

export default ReviewCategoryReorder;
