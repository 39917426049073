import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import messages from 'containers/CategoriesPage/messages';
import globalMessages from 'messages';
import Loader from 'components/_shared/Loader';
import CategoriesTableItem from './CategoriesTableItem';
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc';
import { reorderCategories } from 'store/actions/CategoryActions';

const CategoriesTable = ({ categories, onItemDelete, loading }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const SortableItem = SortableElement(({ category }) => (
    <CategoriesTableItem
      key={category.id}
      item={category}
      onItemDelete={onItemDelete}
    />
  ));
  const SortableList = SortableContainer(() => (
    <tbody>
      {categories.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} category={value} />
      ))}
    </tbody>
  ));

  const handleOnSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(reorderCategories(arrayMove(categories, oldIndex, newIndex)));
  };

  return (
    <div className="c-table-scroll js-maternity-hospitals">
      <table className="c-table">
        <thead>
          <tr>
            <th>{formatMessage(messages.tableHeaderCategories)}</th>
            <th className="is-short u-text-center">
              {formatMessage(messages.tableHeaderActions)}
            </th>
          </tr>
        </thead>
        <SortableList onSortEnd={handleOnSortEnd} pressDelay={200} />
      </table>
      <Loader
        active={loading}
        message={formatMessage(globalMessages.tableDataLoading)}
        className="u-gap-vert u-text-center"
      />
    </div>
  );
};

export default CategoriesTable;
