import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import CreateMaternityHospitalForm from './CreateMaternityHospitalForm';
import { makeSelectMaternityHospitalQuestions } from 'store/selectors/MaternityHospitalQuestionsSelector';
import { getMaternityHospitalQuestions } from 'store/actions/MaternityHospitalQuestionActions';
import {
  createMaternityHospitals,
  updateMaternityHospital,
  getMaternityHospital
} from 'store/actions/MaternityHospitalActions';
import { makeSelectMaternityHospital } from 'store/selectors/MaternityHospitalSelector';
import { makeSelectReviewCategories } from 'store/selectors/ReviewCategorySelector';
import { getReviewCategoriesRequest } from 'store/actions/ReviewCategoryActions';
import MaternityHospitalTrophies from './MaternityHospitalTrophies';
import MaternityHospitalGradeForm from './MaternityHospitalGradeForm';

function CreateMaternityHospitalPage({ location }) {
  const { id } = useParams();
  const isUpdate = id || false;
  const dispatch = useDispatch();
  const maternityHospital = useSelector(makeSelectMaternityHospital());
  const questions = useSelector(makeSelectMaternityHospitalQuestions());
  const reviewCategories = useSelector(makeSelectReviewCategories());

  const fetchReviewCategories = useCallback(
    () => dispatch(getReviewCategoriesRequest()),
    []
  );

  const submitCreateMaternityHospitalForm = useCallback(
    data =>
      dispatch(
        id ? updateMaternityHospital(data) : createMaternityHospitals(data)
      ),
    [id]
  );

  useEffect(() => {
    fetchReviewCategories();
  }, [fetchReviewCategories]);

  useEffect(() => {
    if (id) {
      dispatch(getMaternityHospital(id));
    }
    dispatch(getMaternityHospitalQuestions());
  }, [id]);

  const questionsData = questions ? Object.values(questions).flat() : [];

  return (
    <>
      <CreateMaternityHospitalForm
        onSubmit={submitCreateMaternityHospitalForm}
        questionsData={questionsData}
        item={maternityHospital}
        isUpdate={isUpdate}
      />
      <hr />
      <MaternityHospitalTrophies
        item={maternityHospital}
        isUpdate={!!isUpdate}
        reviewCategories={reviewCategories}
      />
      <hr />
      {maternityHospital.categoryReviews && (
        <MaternityHospitalGradeForm
          item={maternityHospital}
          isUpdate={!!isUpdate}
        />
      )}
    </>
  );
}

export default CreateMaternityHospitalPage;
