import { createSelector } from 'reselect';
import { initialState } from '../reducers/UserReducer';

const selectDomain = state => state.users || initialState;

const makeSelectUsers = () =>
  createSelector(selectDomain, substate => substate.users);

const makeSelectAdmins = () =>
  createSelector(selectDomain, substate => substate.admins);

const makeSelectUsersLoader = () =>
  createSelector(selectDomain, substate => substate.loader);

const makeSelectUser = () =>
  createSelector(selectDomain, substate => substate.user);

const makeSelectUserError = () =>
  createSelector(selectDomain, substate => substate.error);

const makeSelectUniqueEmailError = () =>
  createSelector(selectDomain, substate => substate.uniqueEmailError);

export {
  makeSelectUsers,
  makeSelectUsersLoader,
  makeSelectUser,
  makeSelectUserError,
  makeSelectUniqueEmailError,
  makeSelectAdmins
};
