import { defineMessages } from 'react-intl';

export const scope = 'admins';

export default defineMessages({
  adminsHeaderTitle: {
    id: `${scope}.header.admins`,
    defaultMessage: 'Administratori'
  },
  modalDialogText: {
    id: `${scope}.form.modal.text`,
    defaultMessage: 'Brisanje korisnika je nepovratno.'
  },
  saveUsersButton: {
    id: `${scope}.button.save`,
    defaultMessage: 'Sačuvaj'
  },
  deleteUserButton: {
    id: `${scope}.button.delete`,
    defaultMessage: 'Obriši'
  },
  createUserButton: {
    id: `${scope}.button.create`,
    defaultMessage: 'Kreiraj Administratora'
  },
  saveUserButton: {
    id: `${scope}.button.create`,
    defaultMessage: 'Sačuvaj Korisnika'
  },
  usersHeaderTitle: {
    id: `${scope}.header.title`,
    defaultMessage: 'Korisnici'
  },
  createUserHeaderTitle: {
    id: `${scope}.create.header.title`,
    defaultMessage: 'Kreiranje korisnika'
  },
  tableHeaderUsers: {
    id: `${scope}.table.header.users`,
    defaultMessage: 'Korisnik'
  },
  tableHeaderAdmins: {
    id: `${scope}.table.header.admins`,
    defaultMessage: 'Administrator'
  },
  tableHeaderActions: {
    id: `${scope}.table.header.actions`,
    defaultMessage: 'Akcije'
  },
  confirmPasswordInputLabel: {
    id: `${scope}.input_label.confirm_password`,
    defaultMessage: 'Potvrdi Lozinku'
  },
  updateUserHeaderTitle: {
    id: `${scope}.update.header.title`,
    defaultMessage: 'Izmena korisnika'
  },
  passwordMatchLabel: {
    id: `${scope}.password_match.label`,
    defaultMessage: 'Lozinke se moraju poklapati'
  },
  passwordLengthMessage: {
    id: `${scope}.password_length.message`,
    defaultMessage: 'Lozinka mora imati najmanje 6 karaktera'
  },
  uniqueEmailErrorMessage: {
    id: `${scope}.unique_email_error.message`,
    defaultMessage: 'E-mail adresa mora biti unikatna.'
  },
  commentDateAdded: {
    id: `${scope}.comments.date`,
    defaultMessage: 'Datum dodavanja'
  },
  commentText: {
    id: `${scope}.comments.text`,
    defaultMessage: 'Tekst komentara'
  },
  firstNameLength: {
    id: `${scope}.comments.firstNameLength`,
    defaultMessage: 'Ime mora biti kraće od 150 karaktera'
  },
  lastNameLength: {
    id: `${scope}.comments.lastNameLength`,
    defaultMessage: 'Prezime mora biti kraće od 150 karaktera'
  },
  dateCreatedLabel: {
    id: `${scope}.dateCreatedLabel`,
    defaultMessage: 'Datum kreiranja'
  },
  emailLabel: {
    id: `${scope}.emailLabel`,
    defaultMessage: 'E-mail'
  }
});
