import React, { useEffect, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import MaternityHospitalReviewsTable from '../../components/MaternityHospitalReviewsTable';
import ReviewCategoryDropdown from '../../components/ReviewCategoryDropdown';
import messages from './messages';
import { useSelector, useDispatch } from 'react-redux';
import { makeSelectReviewCategories } from 'store/selectors/ReviewCategorySelector';
import { getReviewCategoriesRequest } from 'store/actions/ReviewCategoryActions';
import {
  mhReviewsFetchRequest,
  mhDeleteRequest,
  mhReviewSetActiveDeactive
} from 'store/actions/MaternityHospitalReviewActions';
import {
  makeSelectMaternityHospitalReviews,
  makeSelectMaternityHospitalReviewsLoader,
  makeSelectMaternityHospitalReviewsPagination
} from 'store/selectors/MaternityHospitalReviewSelector';
import { GENERAL_IMPRESSION } from '../../utils/constants';
import ConfirmationModal from 'components/_shared/ConfirmationModal';

const MaternityHospitalReviewsPage = props => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState({});
  const [isDeleteReview, setIsDeleteReview] = useState(false);
  const [reviewToDelete, setReviewToDelete] = useState(null);
  const ctg = props.location.state.category;

  const reviewCategories = useSelector(makeSelectReviewCategories());
  const mhReviews = useSelector(makeSelectMaternityHospitalReviews());
  const pagination = useSelector(
    makeSelectMaternityHospitalReviewsPagination()
  );
  const loading = useSelector(makeSelectMaternityHospitalReviewsLoader());

  const onCategoryChange = category => {
    setSelectedCategory(category);
  };

  const onItemDelete = id => {
    setIsDeleteReview(true);
    setReviewToDelete(id);
  };

  const onConfirm = () => {
    dispatch(
      mhDeleteRequest({
        id: reviewToDelete,
        page: pagination.currentPage,
        category: selectedCategory
      })
    );
    setIsDeleteReview(false);
  };

  const fetchReviewCategories = useCallback(
    () => dispatch(getReviewCategoriesRequest()),
    [dispatch]
  );

  const fetchHospitalReviews = useCallback(
    payload => dispatch(mhReviewsFetchRequest(payload)),
    [dispatch]
  );

  const onCheck = useCallback(
    (id, active) => {
      dispatch(mhReviewSetActiveDeactive({ id, action: active }));
    },
    [dispatch]
  );

  useEffect(() => {
    fetchReviewCategories();
  }, [fetchReviewCategories]);

  useEffect(() => {
    if (!isEmpty(ctg)) {
      setSelectedCategory({
        value: ctg.value,
        label: ctg.label
      });
      return;
    }
    if (!isEmpty(reviewCategories)) {
      const [category] = reviewCategories.filter(
        category => category.title === GENERAL_IMPRESSION
      );
      setSelectedCategory({
        value: category?.id || reviewCategories[0].id,
        label: category?.title || reviewCategories[0].title
      });
    }
  }, [reviewCategories]);

  useEffect(() => {
    if (selectedCategory.value) {
      fetchHospitalReviews({
        page: 1,
        category: selectedCategory
      });
    }
  }, [fetchHospitalReviews, selectedCategory]);

  const onLoadMore = () =>
    fetchHospitalReviews({
      page: pagination.currentPage + 1,
      category: selectedCategory
    });

  return (
    <section>
      <header className="c-section-header">
        <h1 className="c-section-title">
          {formatMessage(messages.headerTitle)}
        </h1>
        <div style={{ width: '300px' }}>
          <ReviewCategoryDropdown
            categories={reviewCategories}
            selectedCategory={selectedCategory}
            onCategoryChange={onCategoryChange}
          />
        </div>
      </header>
      <MaternityHospitalReviewsTable
        reviews={mhReviews}
        onLoadMore={onLoadMore}
        hasMore={pagination.hasMore}
        loading={loading}
        onItemDelete={onItemDelete}
        selectedCategory={selectedCategory}
        onCheck={onCheck}
      />
      <ConfirmationModal
        isOpen={isDeleteReview}
        onRequestClose={() => setIsDeleteReview(false)}
        onConfirm={onConfirm}
      />
    </section>
  );
};

export default MaternityHospitalReviewsPage;
