import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import messages from 'containers/MaternityHospitalsPage/messages';
import { MATERNITY_HOSPITALS_SINGLE } from 'routes';
import SwitchField from 'components/_shared/SwitchField';

const MaternityHospitalsTableItem = ({ item, onCheck, onItemDelete }) => {
  const { formatMessage } = useIntl();
  const [isActive, setIsActive] = useState(item.active);

  const handleCheck = () => {
    onCheck(item.id, !isActive);
    setIsActive(!isActive);
  };

  return (
    <tr>
      <td>
        <Link
          to={{
            pathname: MATERNITY_HOSPITALS_SINGLE.replace(':id', item.id)
          }}
        >
          {item.name}
        </Link>
      </td>
      <td>
        <SwitchField id={item.id} checked={isActive} onChange={handleCheck} />
      </td>
      <td className="u-text-center">
        <button
          className="c-btn c-btn--sm"
          onClick={() => onItemDelete(item.id)}
        >
          {formatMessage(messages.deleteMaternityHospitalButton)}
        </button>
      </td>
    </tr>
  );
};
export default MaternityHospitalsTableItem;
