import produce from 'immer';

import {
  MH_REVIEWS_FETCH_SUCCESS,
  MH_REVIEWS_FETCH_FAILURE,
  MH_REVIEW_SET_LOADER,
  MH_REVIEW_DELETE_SUCCESS,
  MH_REVIEWS_SET_FOR_USER,
  MH_REVIEWS_GET_FOR_USER_ERROR_SET,
  MH_REVIEW_COMMENT_UPDATE_SET,
  MH_REVIEW_COMMENT_DELETE_PHOTO_SUCCESS,
  MH_REVIEW_COMMENT_DELETE_PHOTO_ERROR,
  MH_REVIEW_COMMENT_UPDATE_ERROR,
  MH_REVIEW_SET_USER_FOR_REVIEWS,
  SET_ACTIVATE_DEACTIVATE_MH_REVIEWS_SUCCESS
} from '../actions/ActionTypes';

export const initialState = {
  mhReviews: [],
  pagination: {
    hasMore: false,
    currentPage: 0,
    total: 0
  },
  isLoading: false,
  error: false,
  userReviews: [],
  userReviewsUser: null,
  userReviewsError: false,
  reviewCommentUpdateError: false,
  reviewPhotoDeleteError: false
};

/* eslint-disable default-case */
const mhReviewReducer = (state = initialState, action) =>
  produce(state, draft => {
    const { type, payload } = action;
    switch (type) {
      case MH_REVIEWS_FETCH_SUCCESS:
        payload.currentPage === 1
          ? (draft.mhReviews = payload.data)
          : (draft.mhReviews = [...draft.mhReviews, ...payload.data]);
        draft.pagination.hasMore = !!payload.nextPageUrl;
        draft.pagination.currentPage = payload.currentPage;
        draft.pagination.total = payload.total;
        break;
      case MH_REVIEWS_FETCH_FAILURE:
        draft.error = payload;
        break;
      case MH_REVIEW_SET_LOADER:
        draft.isLoading = payload;
        break;
      case MH_REVIEW_DELETE_SUCCESS:
        draft.mhReviews = payload;
        break;
      case MH_REVIEWS_SET_FOR_USER:
        draft.userReviews = payload;
        break;
      case MH_REVIEW_SET_USER_FOR_REVIEWS:
        draft.userReviewsUser = payload;
        break;
      case MH_REVIEWS_GET_FOR_USER_ERROR_SET:
        draft.userReviewsError = payload;
        break;
      case MH_REVIEW_COMMENT_UPDATE_SET:
        const reviewId = draft.userReviews.findIndex(
          review => review.id === payload.categoryId
        );
        draft.userReviews[reviewId].comment.content = payload.comment;
        break;
      case MH_REVIEW_COMMENT_DELETE_PHOTO_SUCCESS:
        const revId = draft.userReviews.findIndex(
          review => review.id === payload.reviewId
        );
        draft.userReviews[revId].photo = null;
        break;
      case MH_REVIEW_COMMENT_DELETE_PHOTO_ERROR:
        draft.reviewPhotoDeleteError = true;
        break;
      case MH_REVIEW_COMMENT_UPDATE_ERROR:
        draft.reviewCommentUpdateError = true;
        break;
      case SET_ACTIVATE_DEACTIVATE_MH_REVIEWS_SUCCESS:
        draft.mhReviews = draft.mhReviews.map(item =>
          item.id !== payload.id ? item : payload
        );
        break;
    }
  });

export default mhReviewReducer;
