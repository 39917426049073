import Yup from 'utils/validations';
import { NAME_MAX_LENGTH } from '../../../utils/constants';
import messages from '../messages';

export const createReviewSubcategorySchema = Yup.object().shape({
  title: Yup.string()
    .required()
    .trim()
    .max(NAME_MAX_LENGTH, messages.titleCharacterLimitLabel),
  labor_types: Yup.mixed().required()
});
