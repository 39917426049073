import produce from 'immer';
import {
  SET_MATERNITY_HOSPITALS,
  SET_CREATE_MATERNITY_HOSPITALS,
  SET_DELETE_MATERNITY_HOSPITAL,
  SET_MATERNITY_HOSPITALS_ORDER_BY,
  SET_ACTIVATE_DEACTIVATE_MATERNITY_HOSPITAL,
  SET_UPDATE_MATERNITY_HOSPITAL,
  SET_MATERNITY_HOSPITAL,
  SET_MATERNITY_HOSPITALS_BASIC,
  SET_MATERNITY_HOSPITALS_AT_CURRENT_PAGE,
  SET_MATERNITY_HOSPITALS_LOADER,
  MATERNITY_HOSPITAL_ADD_AWARD_SUCCESS,
  MATERNITY_HOSPITAL_ADD_AWARD_ERROR,
  MATERNITY_HOSPITAL_ADD_AWARD_LOADING,
  MATERNITY_HOSPITAL_REMOVE_AWARD_SUCCESS,
  MATERNITY_HOSPITAL_REMOVE_AWARD_ERROR,
  MATERNITY_HOSPITAL_REMOVE_AWARD_LOADING,
  MH_TROPHY_UPDATE_SUCCESS,
  MATERNITY_HOSPITAL_SWITCH_GRADE_SUCCESS,
  MATERNITY_HOSPITAL_EDIT_GRADE_SUCCESS
} from '../actions/ActionTypes';
import { ORDER_BY_NAME, ITEMS_PER_PAGE } from 'utils/constants';

export const initialState = {
  all: [],
  single: {
    questions: [],
    photos: [],
    trophies: []
  },
  pagination: {
    hasMore: false,
    currentPage: 0,
    total: 0
  },
  orderBy: ORDER_BY_NAME,
  allBasic: [],
  loader: false,
  awards: {
    error: false,
    loading: false,
    removeError: false,
    removeLoading: false
  }
};

/* eslint-disable default-case */
const maternityHospitalReducer = (state = initialState, action) =>
  produce(state, draft => {
    const { type, payload } = action;
    switch (type) {
      case SET_MATERNITY_HOSPITALS:
        payload.currentPage === 1
          ? (draft.all = payload.data)
          : draft.all.push(...payload.data);

        draft.pagination.hasMore = !!payload.nextPageUrl;
        draft.pagination.currentPage = payload.currentPage;
        draft.pagination.total = payload.total;
        break;
      case SET_MATERNITY_HOSPITALS_AT_CURRENT_PAGE:
        payload.data.length === ITEMS_PER_PAGE
          ? draft.all.push(payload.data[payload.data.length - 1])
          : (draft.pagination.hasMore = false);
        break;
      case SET_MATERNITY_HOSPITAL:
        draft.single = payload;
        break;
      case SET_CREATE_MATERNITY_HOSPITALS:
        draft.all.unshift(payload);
        ++draft.pagination.total;
        break;
      case SET_DELETE_MATERNITY_HOSPITAL:
        draft.all = draft.all.filter(item => item.id !== payload);
        --draft.pagination.total;
        break;
      case SET_ACTIVATE_DEACTIVATE_MATERNITY_HOSPITAL:
        draft.all = draft.all.map(item =>
          item.id !== payload.id ? item : payload
        );
        break;
      case SET_MATERNITY_HOSPITALS_ORDER_BY:
        draft.orderBy = payload;
        draft.pagination.currentPage = 0;
        draft.pagination.total = payload.total;
        break;
      case SET_UPDATE_MATERNITY_HOSPITAL:
        draft.all = draft.all.map(item =>
          item.id === payload.id ? payload : item
        );
        break;
      case SET_MATERNITY_HOSPITALS_BASIC:
        draft.allBasic = payload;
        break;
      case SET_MATERNITY_HOSPITALS_LOADER:
        draft.loader = payload;
        break;
      case MATERNITY_HOSPITAL_ADD_AWARD_ERROR:
        draft.awards.error = payload;
        break;
      case MATERNITY_HOSPITAL_ADD_AWARD_LOADING:
        draft.awards.loading = payload;
        break;
      case MATERNITY_HOSPITAL_ADD_AWARD_SUCCESS:
        draft.single.awards.push(payload);
        break;
      case MATERNITY_HOSPITAL_REMOVE_AWARD_SUCCESS:
        draft.single.awards = draft.single.awards.filter(
          award => award.id !== payload.id
        );
        break;
      case MATERNITY_HOSPITAL_REMOVE_AWARD_ERROR:
        draft.awards.removeError = payload;
        break;
      case MATERNITY_HOSPITAL_REMOVE_AWARD_LOADING:
        draft.awards.removeLoading = payload;
        break;
      case MH_TROPHY_UPDATE_SUCCESS:
        draft.single.trophies = payload.trophies;
        break;
      case MATERNITY_HOSPITAL_SWITCH_GRADE_SUCCESS:
        draft.single.showFakeGrade = payload;
        break;
      case MATERNITY_HOSPITAL_EDIT_GRADE_SUCCESS:
        draft.single.fakeGrade = payload;
        break;
    }
  });
export default maternityHospitalReducer;
