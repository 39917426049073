import React, { useEffect, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../messages';
import {
  createReviewCategory,
  getSingleReviewCategory,
  editSingleReviewCategory
} from 'store/actions/ReviewCategoryActions';
import {
  makeSelectReviewCategoriesCreateError,
  makeSelectSingleReviewCategory
} from 'store/selectors/ReviewCategorySelector';
import CreateOrUpdateCategoryForm from 'components/MaternityHospitalsCategories/CreateOrUpdateCategoryForm';
import CreateReviewSubcategory from '../CreateReviewSubcategory';
import ReviewSubcategoriesReorderList from 'components/MaternityHospitalsCategories/ReviewSubcategoriesReorderList';

const CreateReviewCategory = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { id } = useParams();
  const isEditPage = id || false;
  const [
    isAddSubcategoryFormVisible,
    setIsAddSubcategoryFormVisible
  ] = useState(false);

  const error = useSelector(makeSelectReviewCategoriesCreateError());
  const category = useSelector(makeSelectSingleReviewCategory());

  const toggleAddSubcategoryForm = () =>
    setIsAddSubcategoryFormVisible(!isAddSubcategoryFormVisible);

  const onCreateOrEditCategorySubmit = useCallback(values => {
    if (isEditPage) {
      const payload = {
        id: category.id,
        values
      };
      dispatch(editSingleReviewCategory(payload));
      return;
    }
    dispatch(createReviewCategory(values));
  });

  const fetchCategory = useCallback(() =>
    dispatch(getSingleReviewCategory(id))
  );

  useEffect(() => {
    if (isEditPage) {
      dispatch(getSingleReviewCategory(id));
    }
  }, []);

  return (
    <div>
      <header className="c-section-header">
        <h1 className="c-section-title">
          {isEditPage
            ? formatMessage(messages.editReviewCategoryHeaderTitle)
            : formatMessage(messages.newReviewCategoryHeaderTitle)}
        </h1>
      </header>
      <CreateOrUpdateCategoryForm
        category={category}
        isEditPage={isEditPage}
        onSubmit={onCreateOrEditCategorySubmit}
      />
      <hr />
      {isEditPage && category.reviewSubcategories && (
        <div>
          <div>
            <h1>{formatMessage(messages.subcategoriesTitle)}</h1>
            <ReviewSubcategoriesReorderList
              categoryId={category.id}
              reviewSubcategories={category.reviewSubcategories}
              fetchCategory={fetchCategory}
            />
          </div>
          <hr />
          {isAddSubcategoryFormVisible ? (
            <CreateReviewSubcategory
              isCategoryUpdate={isEditPage}
              categoryId={category.id}
              hideForm={toggleAddSubcategoryForm}
            />
          ) : (
            <button
              type="button"
              className="c-btn c-btn--primary"
              onClick={toggleAddSubcategoryForm}
            >
              {formatMessage(messages.addSubcategory)}
            </button>
          )}
        </div>
      )}
      {error.active && <div>{error.message}</div>}
    </div>
  );
};

export default CreateReviewCategory;
