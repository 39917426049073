import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  makeSelectAdmins,
  makeSelectUsersLoader
} from 'store/selectors/UserSelectors';
import { getAdmins, deleteUser } from 'store/actions/UserActions';
import messages from './messages';
import ConfirmationModal from 'components/_shared/ConfirmationModal';
import UsersTable from 'components/Users';
import { USER_CREATE } from 'routes';

const AdminsPage = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const users = useSelector(makeSelectAdmins());
  const loading = useSelector(makeSelectUsersLoader());

  const onConfirm = () => {
    dispatch(deleteUser({ id: selectedId }));
    setIsDeleteModalOpen(false);
  };

  const onItemDelete = id => {
    setIsDeleteModalOpen(true);
    setSelectedId(id);
  };

  const fetchUsers = () => dispatch(getAdmins());

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section>
      <header className="c-section-header">
        <h1 className="c-section-title">
          {formatMessage(messages.adminsHeaderTitle)}
        </h1>
        <div className="c-section-actions">
          <Link
            className="c-btn c-btn--primary u-gap-left"
            to={{
              pathname: USER_CREATE
            }}
          >
            {formatMessage(messages.createUserButton)}
          </Link>
        </div>
      </header>
      <UsersTable users={users} onItemDelete={onItemDelete} loading={loading} />
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        onConfirm={onConfirm}
        modalText={formatMessage(messages.modalDialogText)}
      />
    </section>
  );
};

export default AdminsPage;
