import React from 'react';
import PropTypes, { object } from 'prop-types';
import { useIntl } from 'react-intl';
import { ErrorMessage } from 'formik';
import GoogleComponent from './GoogleComponent';

export default function GoogleInputField({
  field,
  form,
  formatmessagemame,
  addCity,
  isUpdate,
  existingCity
}) {
  const { formatMessage } = useIntl();

  return (
    <div className="c-form-group">
      <label className="c-control-label" htmlFor={field.name}>
        {formatMessage(formatmessagemame)}
      </label>
      <div>
        <GoogleComponent
          language={'sr'}
          country={'rs'}
          types={'(cities)'}
          placeholder={' '}
          coordinates={true}
          isUpdate={isUpdate}
          existingCity={existingCity}
          onChange={e => {
            if (!!e.coordinates && e.coordinates !== '') {
              addCity(e);
            }
          }}
          locationBoxStyle={'c-form-control'}
          locationListStyle={'custom-style-list'}
        />
      </div>
      {form.errors[field.name] && (
        <p className="u-text-error">
          <ErrorMessage name={field.name}>
            {msg =>
              formatMessage(msg, {
                label: formatMessage(formatmessagemame)
              })
            }
          </ErrorMessage>
        </p>
      )}
    </div>
  );
}

GoogleInputField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  formatmessagemame: object,
  addCity: PropTypes.func,
  isUpdate: PropTypes.bool,
  existingCity: PropTypes.string
};
