import { call, put } from 'redux-saga/effects';
import { removeItem } from 'utils/localStorage';
import request from 'utils/request';
import {
  fetchAuthenticatedUserSuccess,
  logoutSuccess
} from '../actions/AppActions';
import { enqueueSnackbar } from 'store/actions/NotifierActions';
import messages from 'containers/LoginPage/messages';

export function* fetchUser() {
  try {
    const user = yield call(request, {
      url: '/auth/me',
      method: 'get'
    });
    yield put(fetchAuthenticatedUserSuccess(user));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.authUserError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* logout() {
  try {
    yield call(request, {
      url: '/auth/logout',
      method: 'post'
    });
    yield put(logoutSuccess());
    yield call(removeItem, 'token');
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.logoutError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}
