import { defineMessages } from 'react-intl';

export const scope = 'users';

export default defineMessages({
  saveUsersButton: {
    id: `${scope}.button.save`,
    defaultMessage: 'Sačuvaj'
  },
  deleteUserButton: {
    id: `${scope}.button.delete`,
    defaultMessage: 'Obriši'
  },
  createUserButton: {
    id: `${scope}.button.create`,
    defaultMessage: 'Kreiraj Korisnika'
  },
  saveUserButton: {
    id: `${scope}.button.create`,
    defaultMessage: 'Sačuvaj Korisnika'
  },
  usersHeaderTitle: {
    id: `${scope}.header.title`,
    defaultMessage: 'Korisnici'
  },
  createUserHeaderTitle: {
    id: `${scope}.create.header.title`,
    defaultMessage: 'Kreiranje korisnika'
  },
  tableHeaderUsers: {
    id: `${scope}.table.header.users`,
    defaultMessage: 'Korisnik'
  },
  tableHeaderActions: {
    id: `${scope}.table.header.actions`,
    defaultMessage: 'Akcije'
  },
  modalDialogText: {
    id: `${scope}.form.modal.text`,
    defaultMessage: 'Brisanje korisnika je nepovratno.'
  },
  confirmPasswordInputLabel: {
    id: `${scope}.input_label.confirm_password`,
    defaultMessage: 'Potvrdi Lozinku'
  },
  updateUserHeaderTitle: {
    id: `${scope}.update.header.title`,
    defaultMessage: 'Izmena korisnika'
  },
  passwordMatchLabel: {
    id: `${scope}.password_match.label`,
    defaultMessage: 'Lozinke se moraju poklapati'
  },
  passwordLengthMessage: {
    id: `${scope}.password_length.message`,
    defaultMessage: 'Lozinka mora imati najmanje 6 karaktera'
  },
  uniqueEmailErrorMessage: {
    id: `${scope}.unique_email_error.message`,
    defaultMessage: 'E-mail adresa mora biti unikatna.'
  },
  mailingListBtnLabel: {
    id: `${scope}.mailingListBtnLabel`,
    defaultMessage: 'Lista s mejlovima'
  },
  userActionsBtnLabel: {
    id: `${scope}.userActionsBtnLabel`,
    defaultMessage: 'Lista s akcijama'
  },
  exportCsvBtnLabel: {
    id: `${scope}.exportCsvBtnLabel`,
    defaultMessage: 'Izvezi mejlove kao CSV'
  }
});
