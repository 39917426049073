import { call, put } from 'redux-saga/effects';

import request from 'utils/request';
import {
  setMaternityHospitals,
  setCreateMaternityHospitals,
  setActivateDeactivateMaternityHospitals,
  setUpdateMaternityHospital,
  setDeleteMaternityHospital,
  setMaternityHospital,
  setMaternityHospitalsBasic,
  setMaternityHospitalsAtCurrentPage,
  setMaternityHospitalsLoader,
  mhAddAwardError,
  mhAddAwardLoading,
  mhAddAwardSuccess,
  mhRemoveAwardError,
  mhRemoveAwardLoading,
  mhRemoveAwardSuccess,
  mhTrophyUpdateSuccess,
  maternityHospitalSwitchGradeSuccess,
  maternityHospitalEditGradeSuccess
} from 'store/actions/MaternityHospitalActions';
import { camelCaseToSnakeCaseObject } from 'utils/parseObjectToArray';
import {
  MATERNITY_HOSPITALS,
  UPLOAD_PHOTO,
  MATERNITY_HOSPITALS_SINGLE,
  MATERNITY_HOSPITALS_PAGINATED,
  MATERNITY_HOSPITALS_AWARDS,
  MATERNITY_HOSPITAL_TROPHIES,
  MATERNITY_HOSPITAL_SWITCH_GRADE,
  MATERNITY_HOSPITAL_EDIT_GRADE
} from 'routes';
import history from 'utils/history';
import { ORDER_BY_NAME } from 'utils/constants';
import { enqueueSnackbar } from 'store/actions/NotifierActions';
import messages from '../../containers/MaternityHospitalsPage/messages';

export function* maternityHospitalsGet({
  page = 1,
  orderBy = ORDER_BY_NAME,
  searchValue = '',
  reloadCurrentPage
}) {
  try {
    yield put(setMaternityHospitalsLoader(true));
    const [order, direction] = orderBy.split('|');

    const response = yield call(request, {
      url:
        MATERNITY_HOSPITALS_PAGINATED +
        '?page=' +
        page +
        '&orderBy=' +
        order +
        '&direction=' +
        direction +
        '&search=' +
        searchValue +
        '&withQuestions=all',
      method: 'get'
    });

    reloadCurrentPage
      ? yield put(setMaternityHospitalsAtCurrentPage(response))
      : yield put(setMaternityHospitals(response));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.hospitalGetError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  } finally {
    yield put(setMaternityHospitalsLoader(false));
  }
}

export function* maternityHospitalsBasicGet() {
  try {
    const response = yield call(request, {
      url: MATERNITY_HOSPITALS,
      method: 'get'
    });
    yield put(
      setMaternityHospitalsBasic(
        Object.values(camelCaseToSnakeCaseObject(response))
      )
    );
  } catch (error) {
    console.log(error);
  }
}

export function* maternityHospitalGet({ payload }) {
  try {
    const response = yield call(request, {
      url:
        MATERNITY_HOSPITALS_SINGLE.replace(':id', payload) +
        '?withQuestions=all',
      method: 'get'
    });
    yield put(setMaternityHospital(response));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.hospitalGetError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* maternityHospitalsCreate({ payload }) {
  try {
    const response = yield call(request, {
      url: MATERNITY_HOSPITALS,
      method: 'post',
      data: payload
    });
    yield put(setCreateMaternityHospitals(response));
    history.push(MATERNITY_HOSPITALS);
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.hospitalCreateError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* maternityHospitalDelete({ payload }) {
  try {
    const { id, page, orderBy, search } = payload;
    yield call(request, {
      url: MATERNITY_HOSPITALS + '/' + id,
      method: 'delete'
    });
    yield put(setDeleteMaternityHospital(payload.id));
    yield call(maternityHospitalsGet, {
      page,
      orderBy,
      search,
      reloadCurrentPage: true
    });
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.hospitalDeleteError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* maternityHospitalActivateDeactivate({ payload }) {
  try {
    const response = yield call(request, {
      url: MATERNITY_HOSPITALS + '/' + payload.id,
      method: 'patch',
      data: { active: payload.active }
    });
    yield put(
      setActivateDeactivateMaternityHospitals(
        camelCaseToSnakeCaseObject(response)
      )
    );
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.hospitalUpdateError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* maternityHospitalUploadPhoto({ payload }) {
  try {
    const data = new FormData();
    Array.prototype.forEach.call(payload.photos, photo => {
      data.append(photo.name, photo);
    });
    const { url } = yield call(request, {
      url: UPLOAD_PHOTO,
      method: 'post',
      data,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    payload.callback(url);
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.hospitalUpdateError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}
export function* maternityHospitalUpdate({ payload }) {
  try {
    const response = yield call(request, {
      url: MATERNITY_HOSPITALS + '/' + payload.id,
      method: 'put',
      data: payload
    });
    yield put(setUpdateMaternityHospital(camelCaseToSnakeCaseObject(response)));
    history.push(MATERNITY_HOSPITALS);
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.hospitalUpdateError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* addNewAward({ payload }) {
  try {
    yield put(mhAddAwardLoading(true));
    const response = yield call(request, {
      url: MATERNITY_HOSPITALS_AWARDS,
      method: 'post',
      data: payload
    });
    yield put(mhAddAwardSuccess(response));
    yield put(mhAddAwardError(false));
  } catch (error) {
    yield put(mhAddAwardError(true));
  } finally {
    yield put(mhAddAwardLoading(false));
  }
}

export function* removeAward({ payload }) {
  try {
    yield put(mhRemoveAwardLoading(true));
    const response = yield call(request, {
      url: `${MATERNITY_HOSPITALS_AWARDS}/${payload.id}`,
      method: 'delete'
    });
    yield put(mhRemoveAwardSuccess(response));
    yield put(mhRemoveAwardError(false));
  } catch (error) {
    yield put(mhRemoveAwardError(true));
  } finally {
    yield put(mhRemoveAwardLoading(false));
  }
}

export function* updateHospitalTrophies({ payload }) {
  try {
    const response = yield call(request, {
      url: MATERNITY_HOSPITAL_TROPHIES.replace(':id', payload.id),
      method: 'post',
      data: payload
    });
    yield put(mhTrophyUpdateSuccess(response));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.trophiesError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* switchHospitalGrade({ payload }) {
  try {
    const response = yield call(request, {
      url: MATERNITY_HOSPITAL_SWITCH_GRADE.replace(':id', payload.id),
      method: 'put',
      data: payload
    });
    yield put(maternityHospitalSwitchGradeSuccess(response.showFakeGrade));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.gradeSwitchError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}

export function* editHospitalGrade({ payload }) {
  try {
    const response = yield call(request, {
      url: MATERNITY_HOSPITAL_EDIT_GRADE.replace(':id', payload.id),
      method: 'put',
      data: payload
    });
    yield put(maternityHospitalEditGradeSuccess(response.fakeGrade));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: messages.gradeError,
        options: {
          persist: false,
          preventDuplicate: true
        },
        variant: 'error'
      })
    );
  }
}
