import { createSelector } from 'reselect';
import { initialState } from '../reducers/MaternityHospitalQuestionReducer';

const selectDomain = state => state.maternityHospitalQuestions || initialState;

const makeSelectMaternityHospitalQuestions = () =>
  createSelector(selectDomain, substate => substate.questions);

const makeSelectMaternityHospitalQuestion = () =>
  createSelector(selectDomain, substate => substate.singleQuestion);

const makeSelectMaternityHospitalQuestionCategories = () =>
  createSelector(selectDomain, substate => substate.questionCategories);

export {
  makeSelectMaternityHospitalQuestions,
  makeSelectMaternityHospitalQuestion,
  makeSelectMaternityHospitalQuestionCategories
};
