import React from 'react';
import { useIntl } from 'react-intl';
import Modal from 'react-modal';

import globalMessages from 'messages';

const ConfirmationModal = ({
  isOpen,
  onRequestClose,
  onConfirm,
  modalText
}) => {
  const { formatMessage } = useIntl();
  return (
    <Modal
      className="c-modal c-modal--prompt"
      appElement={document.getElementById('root')}
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(false)}
    >
      <div className="c-modal__header">
        <h5 className="c-modal__title">
          {modalText || formatMessage(globalMessages.confirmationModalText)}
        </h5>
      </div>
      <div className="c-modal__content">
        <button className="c-btn" onClick={() => onRequestClose(false)}>
          {formatMessage(globalMessages.confirmationModalClose)}
        </button>
        {!!onConfirm && (
          <button
            className="c-btn c-btn--primary u-gap-left"
            onClick={onConfirm}
          >
            {formatMessage(globalMessages.confirmationModalConfirm)}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
