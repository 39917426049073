import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { IoIosCloseCircle } from 'react-icons/io';

import messages from 'messages';
import { MAX_HOSPITAL_PHOTOS } from 'utils/constants';
import config from 'config';

const PhotoUpload = ({
  photoUrls = [],
  limit,
  action,
  onDelete,
  addPhotoUrl,
  setIsPendingPhoto
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const handleSubmitPhoto = photos => {
    if (photos.length > MAX_HOSPITAL_PHOTOS - photoUrls.length) {
      alert(formatMessage(messages.tooManyPhotosSelected));
      document.getElementById('file').value = '';
      return;
    }
    setIsPendingPhoto(true);
    dispatch(action({ photos, callback: addPhotoUrl }));
  };

  const handleDelete = photo => {
    setIsPendingPhoto(true);
    onDelete(photo);
  };

  return (
    <ul className="c-image-list">
      {photoUrls.map(photo => (
        <li key={photo} className="c-image-item">
          <img src={config.api.publicUrl + photo} alt="#" />
          <div className="c-actions">
            <button
              className="c-btn c-btn--sm c-btn--link"
              onClick={() => handleDelete(photo)}
            >
              <span>
                <IoIosCloseCircle />
              </span>
            </button>
          </div>
        </li>
      ))}
      {photoUrls.length < limit && (
        <li className="c-image-item c-image-item--add">
          <input
            id="file"
            name="file"
            type="file"
            className="c-form-control"
            onChange={({ currentTarget }) =>
              handleSubmitPhoto(currentTarget.files)
            }
            multiple
            accept=".jpg,.jpeg,.png"
          />
        </li>
      )}
    </ul>
  );
};
export default PhotoUpload;
