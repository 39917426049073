import { createSelector } from 'reselect';
import { initialState } from '../reducers/MaternityHospitalReviewReducer';

const selectDomain = state => state.maternityHospitalReviews || initialState;

const makeSelectMaternityHospitalReviews = () =>
  createSelector(selectDomain, substate => substate.mhReviews);

const makeSelectMaternityHospitalReviewsPagination = () =>
  createSelector(selectDomain, substate => substate.pagination);

const makeSelectMaternityHospitalReviewsError = () =>
  createSelector(selectDomain, substate => substate.error);

const makeSelectMaternityHospitalReviewsLoader = () =>
  createSelector(selectDomain, substate => substate.isLoading);

const makeSelectMaternityHospitalUserReviews = () =>
  createSelector(selectDomain, substate => substate.userReviews);

const makeSelectMaternityHospitalUserReviewsUser = () =>
  createSelector(selectDomain, substate => substate.userReviewsUser);

const makeSelectMaternityHospitalUserReviewsError = () =>
  createSelector(selectDomain, substate => substate.userReviewsError);

const makeSelectUserReviewsCommentUpdateError = () =>
  createSelector(selectDomain, substate => substate.reviewCommentUpdateError);

const makeSelectUserReviewsPhotoDeleteError = () =>
  createSelector(selectDomain, substate => substate.reviewPhotoDeleteError);

export {
  makeSelectMaternityHospitalReviews,
  makeSelectMaternityHospitalReviewsError,
  makeSelectMaternityHospitalReviewsLoader,
  makeSelectMaternityHospitalReviewsPagination,
  makeSelectMaternityHospitalUserReviews,
  makeSelectMaternityHospitalUserReviewsError,
  makeSelectUserReviewsCommentUpdateError,
  makeSelectUserReviewsPhotoDeleteError,
  makeSelectMaternityHospitalUserReviewsUser
};
