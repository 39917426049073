import { defineMessages } from 'react-intl';

export const scope = 'create_question_page';

export default defineMessages({
  questionButtonAdd: {
    id: `${scope}.button.question`,
    defaultMessage: 'Dodaj'
  },
  questionButtonSave: {
    id: `${scope}.button.question`,
    defaultMessage: 'Sačuvaj'
  },
  questionTitleInputLabel: {
    id: `${scope}.input_label.question`,
    defaultMessage: 'Tekst pitanja'
  },
  filterQuestionTitleInputLabel: {
    id: `${scope}.input_label.question`,
    defaultMessage: 'Tekst filtera'
  },
  answerTypeInputLabel: {
    id: `${scope}.answer_type_label.question`,
    defaultMessage: 'Tip odgovora'
  },
  selectOptionString: {
    id: `${scope}.select_option.string`,
    defaultMessage: 'Tekstualni unos'
  },
  selectOptionBoolean: {
    id: `${scope}.select_option.boolean`,
    defaultMessage: 'DA/NE'
  },
  selectOpitonNumber: {
    id: `${scope}.select_option.number`,
    defaultMessage: 'Broj'
  },
  questionCategoryInputLabel: {
    id: `${scope}.input_label.category`,
    defaultMessage: 'Kategorija pitanja'
  },
  createQuestion: {
    id: `${scope}.createQuestion`,
    defaultMessage: 'Dodavanje pitanja'
  },
  updateQuestion: {
    id: `${scope}.updateQuestion`,
    defaultMessage: 'Izmena pitanja'
  }
});
