import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import config from 'config';

import messages from 'containers/MaternityHospitalReviews/messages';
import { formatDate } from 'utils/formatDate';
import GradesList from './Grades/GradesList';
import ReviewComment from './ReviewComment';
import {
  mhReviewUpdateComment,
  mhReviewDeletePhoto
} from 'store/actions/MaternityHospitalReviewActions';
import ConfirmationModal from 'components/_shared/ConfirmationModal';

const ReviewDetails = ({ review }) => {
  const { subcategory_reviews } = review;
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [isEditCommentToggled, setIsEditCommentToggled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onCommentSubmit = useCallback(data => {
    data.review_id = review.id;
    data.comment_id = review.comment.id;
    dispatch(mhReviewUpdateComment(data));
    setIsEditCommentToggled(false);
  });

  const renderCommentSection = () =>
    isEditCommentToggled ? (
      <ReviewComment
        onCommentSubmit={onCommentSubmit}
        reviewComment={review.comment.content}
      />
    ) : (
      <li>
        <b>{formatMessage(messages.reviewCommentTitle)}</b>{' '}
        {review.comment.content}
        {renderCommentChangeBtn()}
      </li>
    );

  const renderCommentChangeBtn = () => (
    <button
      className="c-btn c-btn--primary u-gap-left u-gap-top u-btn--block-right"
      type="button"
      onClick={onCommentChangeClick}
    >
      {formatMessage(messages.changeCommentBtnTitle)}
    </button>
  );

  const renderImgSection = () => (
    <div>
      <img
        src={config.api.publicUrl + review.photo.url}
        alt="#"
        style={{ maxWidth: '150px' }}
      />
      <button
        className="c-btn"
        type="button"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {formatMessage(messages.deletePhotoBtnTitle)}
      </button>
    </div>
  );

  const onCommentChangeClick = () => setIsEditCommentToggled(true);
  const onDeletePhotoClick = useCallback(() => {
    dispatch(mhReviewDeletePhoto({ id: review.id }));
    setIsOpen(false);
  }, [dispatch]);

  return (
    <div className="c-review-detail">
      <ConfirmationModal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        onConfirm={onDeletePhotoClick}
        modalText={formatMessage(messages.deletePhotoConfirm)}
      />
      <ul className="c-cagegories-list">
        <li>
          <b>{formatMessage(messages.reviewCategoryHospital)}</b>
          {review.maternity_hospital.name}
        </li>
        <li>
          <b>{formatMessage(messages.reviewCategoryTitle)}</b>
          {review.review_category.title}
        </li>
        <li>
          <b>{formatMessage(messages.reviewDateTimeTitle)}</b>{' '}
          {formatDate(review.createdAt)}
        </li>
      </ul>
      {!!review.comment ? (
        <ul className="c-cagegories-list">
          {renderCommentSection()}
          <li>
            <b>{formatMessage(messages.reviewLikesTitle)}</b>
            {review.comment.likes}
          </li>
          <li>
            <b>{formatMessage(messages.reviewDislikesTitle)}</b>
            {review.comment.dislikes}
          </li>
        </ul>
      ) : (
        <h3>
          <b>{formatMessage(messages.reviewNoCommentTitle)}</b>
        </h3>
      )}
      <ul className="c-cagegories-list">
        <li>
          <b>{formatMessage(messages.reviewPhotoTitle)}</b>
        </li>
        {review.photo ? (
          renderImgSection()
        ) : (
          <li>
            <i>{formatMessage(messages.reviewNoPhotoTitle)}</i>
          </li>
        )}
      </ul>
      <GradesList subcategoryReviews={subcategory_reviews} />
    </div>
  );
};

export default ReviewDetails;
