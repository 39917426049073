import produce from 'immer';
import {
  SET_MATERNITY_HOSPITAL_QUESTIONS,
  SET_ADD_MATERNITY_HOSPITAL_QUESTION,
  SET_DELETE_MATERNITY_HOSPITAL_QUESTION,
  SET_UPDATE_MATERNITY_HOSPITAL_QUESTION,
  SET_MATERNITY_HOSPITAL_QUESTION,
  MATERNITY_HOSPITAL_QUESTION_CATEGORIES_SET,
  SET_UPDATE_MATERNITY_HOSPITAL_QUESTION_ACTIVE,
  SET_UPDATE_MATERNITY_HOSPITAL_QUESTION_FILTER
} from '../actions/ActionTypes';

export const initialState = {
  questions: null,
  singleQuestion: {},
  questionCategories: {}
};

/* eslint-disable default-case */
const maternityHospitalQuestionReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_MATERNITY_HOSPITAL_QUESTIONS:
        draft.questions = action.payload;
        break;
      case SET_ADD_MATERNITY_HOSPITAL_QUESTION:
        draft.questions = [...draft.questions, action.payload];
        break;
      case SET_DELETE_MATERNITY_HOSPITAL_QUESTION:
        draft.questions[action.payload.category] = draft.questions[
          action.payload.category
        ].filter(question => question.id !== action.payload.id);
        break;
      case SET_UPDATE_MATERNITY_HOSPITAL_QUESTION:
        draft.questions[action.payload.category] = draft.questions[
          action.payload.category
        ].map(question =>
          question.id === action.payload.id ? action.payload.item : question
        );
        break;
      case SET_MATERNITY_HOSPITAL_QUESTION:
        draft.singleQuestion = action.payload;
        break;
      case MATERNITY_HOSPITAL_QUESTION_CATEGORIES_SET:
        draft.questionCategories = action.payload;
        break;
      case SET_UPDATE_MATERNITY_HOSPITAL_QUESTION_ACTIVE:
        draft.questions[action.payload.category] = draft.questions[
          action.payload.category
        ].map(question =>
          question.id === action.payload.id
            ? { ...question, active: action.payload.active }
            : question
        );
        break;
      case SET_UPDATE_MATERNITY_HOSPITAL_QUESTION_FILTER:
        draft.questions[action.payload.category] = draft.questions[
          action.payload.category
        ].map(question =>
          question.id === action.payload.id
            ? { ...question, is_filter: action.payload.is_filter }
            : question
        );
        break;
    }
  });
export default maternityHospitalQuestionReducer;
