import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Formik, Form, Field } from 'formik';
import isEmpty from 'lodash/isEmpty';
import config from 'config';
import { createMaternityHospitalSchema } from './validators';
import messages from '../messages';
import globalMessages from 'messages';
import TextInputField from 'components/_shared/TextInputField';
import TextAreaField from 'components/_shared/TextAreaField';
import PhotoUpload from 'components/_shared/PhotoUpload';
import {
  MAX_HOSPITAL_PHOTOS,
  BIRTH_COUNT_QUESTION,
  HOSPITAL_SMALL,
  HOSPITAL_LARGE,
  MOTHER_TOTAL_QUESTION,
  MOTHER_TOTAL_QUESTION_FROM_TO
} from 'utils/constants';
import { uploadMaternityHospitalPhoto } from 'store/actions/MaternityHospitalActions';
import initializeQuestionsForm from './init';
import QuestionField from '../QuestionField';
import history from 'utils/history';
import AwardsFormAndList from './AwardsFormAndList';

import GoogleInputField from 'components/_shared/GoogleInputField';

export default function CreateMaternityHospitalForm({
  onSubmit,
  questionsData,
  item,
  isUpdate
}) {
  const googleExternalScriptSrc = `https://maps.googleapis.com/maps/api/js?key=${config.googleAPI.key}&libraries=places`;

  const { formatMessage } = useIntl();
  const [isPendingPhoto, setIsPendingPhoto] = useState(false);
  const [hospitalSize, setHospitalSize] = useState(null);
  const [hospitalSizeHint, setHospitalSizeHint] = useState('');
  const [existingCity, setExistingCity] = useState('');

  const addPhotoUrl = (values, setFieldValue) => url => {
    setFieldValue('photoArray', [...values.photoArray, ...url]);
    setIsPendingPhoto(false);
    document.getElementById('file').value = '';
  };
  const addCity = setFieldValue => city => {
    setFieldValue('city', city.place);
    setFieldValue('latitude', city.coordinates.lat());
    setFieldValue('longitude', city.coordinates.lng());
  };
  const onDelete = (values, setFieldValue) => url => {
    setFieldValue(
      'photoArray',
      values.photoArray.filter(photo => photo !== url)
    );
    setIsPendingPhoto(false);
    if (values.photoArray.length < MAX_HOSPITAL_PHOTOS)
      document.getElementById('file').value = '';
  };

  const disableSubmit = isValid => isPendingPhoto || !isValid;

  const renderHeader = (errors, values) => (
    <header className="c-section-header is-fixed">
      <h1 className="c-section-title">
        {isUpdate
          ? formatMessage(messages.updateMaternityHospitalHeaderTitle)
          : formatMessage(messages.createMaternityHospitalHeaderTitle)}
      </h1>

      <div className="c-section-actions">
        <button
          type="button"
          className="c-btn c-btn--primary u-gap-right"
          onClick={history.goBack}
        >
          {formatMessage(globalMessages.backButton)}
        </button>
        <button
          className="c-btn c-btn--primary"
          type="submit"
          disabled={disableSubmit(errors, values)}
        >
          {formatMessage(messages.saveMaternityHospitalButton)}
        </button>
      </div>
    </header>
  );

  const renderQuestions = () =>
    questionsData.map(
      (item, index) =>
        !!item.active && (
          <div key={`question-${index}`}>
            <Field
              key={index}
              name={`questions[${index}].answer`}
              component={QuestionField}
              type={item.answer_type}
              label={item.question}
              placeholder={
                item.question === MOTHER_TOTAL_QUESTION
                  ? MOTHER_TOTAL_QUESTION_FROM_TO
                  : ''
              }
              changeHandle={
                isHospitalSizeQuestion(item.question) && {
                  onKeyUp: ({ target: { value } }) => setHospitalSize(value)
                }
              }
            />
            {isHospitalSizeQuestion(item.question) && (
              <p className="u-text-right u-text-success u-gap-bottom-sm">
                {hospitalSizeHint}
              </p>
            )}
          </div>
        )
    );

  const isHospitalSizeQuestion = question => question === BIRTH_COUNT_QUESTION;

  const handleHospitalSizeHint = size => {
    if (isEmpty(size)) {
      setHospitalSizeHint('');
      return;
    }
    if (Number(size) < HOSPITAL_SMALL) {
      setHospitalSizeHint(formatMessage(messages.hospitalSizeSmall));
      return;
    }
    if (Number(size) >= HOSPITAL_SMALL && Number(size) <= HOSPITAL_LARGE) {
      setHospitalSizeHint(formatMessage(messages.hospitalSizeMedium));
      return;
    }
    if (Number(size) > HOSPITAL_LARGE) {
      setHospitalSizeHint(formatMessage(messages.hospitalSizeLarge));
      return;
    }
  };

  useEffect(() => {
    handleHospitalSizeHint(hospitalSize);
  }, [hospitalSize]);

  useEffect(() => {
    setExistingCity(item.city);
  }, [item.city]);

  useEffect(() => {
    let googleScript = document.getElementById('google-api');
    if (!googleScript) {
      googleScript = document.createElement('script');
      googleScript.setAttribute('src', googleExternalScriptSrc);
      googleScript.setAttribute('async', '');
      googleScript.setAttribute('id', 'google-api');
      document.head.append(googleScript);
    }
  }, []);

  return questionsData.length ? (
    <div>
      <Formik
        initialValues={initializeQuestionsForm(item, questionsData, isUpdate)}
        validationSchema={createMaternityHospitalSchema}
        onSubmit={values => {
          onSubmit(values);
        }}
        enableReinitialize
        isInitialValid={false}
      >
        {({ isValid, values, setFieldValue }) => (
          <Form className="c-form">
            {renderHeader(isValid)}
            <section>
              <header>
                <h2 className="c-section-subtitle">
                  {formatMessage(messages.infoTitle)}
                </h2>
              </header>
              <div className="c-grid c-grid--content-aside">
                <div>
                  <div className="c-form-group required-field__wrap">
                    <span>*</span>
                    <Field
                      name="name"
                      type="text"
                      required
                      component={TextInputField}
                      formatmessagemame={messages.nameLabel}
                    />
                  </div>
                  <div className="c-grid c-grid--thirds">
                    <Field
                      name="address"
                      type="text"
                      component={TextInputField}
                      formatmessagemame={messages.addressLabel}
                    />
                    <Field
                      name="zip"
                      type="text"
                      component={TextInputField}
                      formatmessagemame={messages.zipLabel}
                    />
                    <Field
                      type="text"
                      component={GoogleInputField}
                      formatmessagemame={messages.cityLabel}
                      addCity={addCity(setFieldValue)}
                      isUpdate={isUpdate ? true : false}
                      existingCity={existingCity}
                    />
                  </div>
                  <Field
                    name="website"
                    type="text"
                    component={TextInputField}
                    formatmessagemame={messages.websiteLabel}
                  />
                  <div className="c-grid c-grid--thirds">
                    <Field
                      name="email"
                      type="email"
                      component={TextInputField}
                      formatmessagemame={messages.emailLabel}
                    />
                    <Field
                      name="phone_number"
                      type="text"
                      component={TextInputField}
                      formatmessagemame={messages.phoneNumberLabel}
                    />
                    <Field
                      name="number_of_pictures"
                      type="number"
                      min="0"
                      component={TextInputField}
                      formatmessagemame={messages.numberOfPictures}
                    />
                  </div>
                  <div className="c-grid c-grid--halves">
                    <Field
                      name="mother_and_baby_info_contact_number"
                      type="number"
                      min="0"
                      component={TextInputField}
                      formatmessagemame={
                        messages.motherAndBabyInfoContactNumber
                      }
                    />
                    <Field
                      name="data_validity_contact_person"
                      type="text"
                      component={TextInputField}
                      formatmessagemame={messages.dataValidityContactPerson}
                    />
                  </div>
                  <div className="c-grid c-grid--halves">
                    <Field
                      name="director"
                      type="text"
                      component={TextInputField}
                      formatmessagemame={messages.director}
                    />
                    <Field
                      name="complaint_email"
                      type="email"
                      component={TextInputField}
                      formatmessagemame={messages.complaintEmail}
                    />
                  </div>
                </div>

                <Field
                  name="about"
                  component={TextAreaField}
                  rows="15"
                  formatmessagemame={messages.aboutLabel}
                />
              </div>
            </section>

            <section>
              <header>
                <h2 className="c-section-subtitle">
                  {formatMessage(globalMessages.photos)} (
                  {values.photoArray.length}/{MAX_HOSPITAL_PHOTOS})
                </h2>
              </header>
              <PhotoUpload
                photos={values.photoArray}
                limit={MAX_HOSPITAL_PHOTOS}
                action={uploadMaternityHospitalPhoto}
                onDelete={onDelete(values, setFieldValue)}
                photoUrls={values.photoArray}
                addPhotoUrl={addPhotoUrl(values, setFieldValue)}
                setIsPendingPhoto={setIsPendingPhoto}
              />
            </section>

            <section className="c-question-list">
              <header>
                <h2 className="c-section-subtitle">
                  {formatMessage(messages.questionsTitle)}
                </h2>
              </header>

              {renderQuestions()}
            </section>
          </Form>
        )}
      </Formik>
      {isUpdate && (
        <section>
          <AwardsFormAndList item={item} />
        </section>
      )}
    </div>
  ) : (
    formatMessage(globalMessages.loadingData)
  );
}

CreateMaternityHospitalForm.propTypes = {
  onSubmit: PropTypes.func,
  isPending: PropTypes.bool,
  questionsData: PropTypes.array,
  item: PropTypes.object,
  isUpdate: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};
