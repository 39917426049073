import React, { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { useIntl } from 'react-intl';

import TextAreaField from 'components/_shared/TextAreaField';
import { reviewCommentValidation } from './ReviewCommentValidation';
import messages from 'containers/MaternityHospitalReviews/messages';

const ReviewComment = ({ reviewComment, onCommentSubmit }) => {
  const { formatMessage } = useIntl();

  return (
    <Formik
      initialValues={{ content: reviewComment || '' }}
      enableReinitialize
      onSubmit={onCommentSubmit}
      validationSchema={reviewCommentValidation}
    >
      <Form className="c-review-comment-form">
        <Field
          name="content"
          formatmessagemame={messages.reviewCommentTitle}
          component={TextAreaField}
        />
        <button
          className="c-btn c-btn--primary u-btn--block-right"
          type="submit"
        >
          {formatMessage(messages.reviewCommentApplyBtn)}
        </button>
      </Form>
    </Formik>
  );
};

export default ReviewComment;
