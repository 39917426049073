import React from 'react';

const FilterSelect = ({ orderBy, handleOrderBy, options = [{}] }) => {
  return (
    <select className="c-form-control" value={orderBy} onChange={handleOrderBy}>
      {options.map(({ value, text }) => (
        <option key={'select_' + value} value={value}>
          {text}
        </option>
      ))}
    </select>
  );
};
export default FilterSelect;
