import { createSelector } from 'reselect';
import { initialState } from '../reducers/ReviewCategoryReducer';

const selectDomain = state => state.reviewCategories || initialState;

const makeSelectReviewCategories = () =>
  createSelector(selectDomain, substate => substate.reviewCategories);

const makeSelectReviewCategoriesLoader = () =>
  createSelector(selectDomain, substate => substate.loader);

const makeSelectReviewCategoriesFetchError = () =>
  createSelector(selectDomain, substate => substate.reviewCategoriesError);

const makeSelectReviewCategoryDeleteError = () =>
  createSelector(selectDomain, substate => substate.errorDeleteCategory);

const makeSelectReviewSubcategoryDeleteError = () =>
  createSelector(selectDomain, substate => substate.errorDeleteSubcategory);

const makeSelectReviewCategoriesCreateError = () =>
  createSelector(selectDomain, substate => substate.reviewCategoryCreateError);

const makeSelectLaborTypes = () =>
  createSelector(selectDomain, substate => substate.laborTypes);

const makeSelectReviewSubcategoriesCreateError = () =>
  createSelector(
    selectDomain,
    substate => substate.reviewSubcategoryCreateError
  );

const makeSelectSingleReviewCategory = () =>
  createSelector(selectDomain, substate => substate.singleReviewCategory);

const makeSelectReviewCategoryReorderError = () =>
  createSelector(selectDomain, substate => substate.orderChangeError);

const makeSelectReviewSubcategoryReorderError = () =>
  createSelector(
    selectDomain,
    substate => substate.subcategoryOrderChangeError
  );

const makeSelectSingleReviewSubcategory = () =>
  createSelector(selectDomain, substate => substate.singleReviewSubcategory);

const makeSelectReviewSubcategoryUpdateError = () =>
  createSelector(
    selectDomain,
    substate => substate.reviewSubcategoryUpdateError
  );

const makeSelectIsSubcategoryChanged = () =>
  createSelector(selectDomain, substate => substate.isSubcategoryChanged);

const makeSelectIsCategoryChanged = () =>
  createSelector(selectDomain, substate => substate.isCategoryChanged);

export {
  makeSelectReviewCategories,
  makeSelectReviewCategoriesFetchError,
  makeSelectReviewCategoriesLoader,
  makeSelectReviewCategoryDeleteError,
  makeSelectReviewSubcategoryDeleteError,
  makeSelectReviewCategoriesCreateError,
  makeSelectReviewCategoryReorderError,
  makeSelectLaborTypes,
  makeSelectReviewSubcategoriesCreateError,
  makeSelectSingleReviewCategory,
  makeSelectReviewSubcategoryReorderError,
  makeSelectSingleReviewSubcategory,
  makeSelectReviewSubcategoryUpdateError,
  makeSelectIsSubcategoryChanged,
  makeSelectIsCategoryChanged
};
