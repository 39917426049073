import { createSelector } from 'reselect';
import { initialState } from '../reducers/MaternityHospitalReducer';

const selectDomain = state => state.maternityHospitals || initialState;

const makeSelectMaternityHospitals = () =>
  createSelector(selectDomain, substate => substate.all);

const makeSelectMaternityHospitalsBasic = () =>
  createSelector(selectDomain, substate => substate.allBasic);

const makeSelectMaternityHospital = () =>
  createSelector(selectDomain, substate => substate.single);

const makeSelectMaternityHospitalsPagination = () =>
  createSelector(selectDomain, substate => substate.pagination);

const makeSelectMaternityHospitalsOrderBy = () =>
  createSelector(selectDomain, substate => substate.orderBy);

const makeSelectMaternityHospitalsLoader = () =>
  createSelector(selectDomain, substate => substate.loader);

const makeSelectMaternityHospitalAwards = () =>
  createSelector(selectDomain, substate => substate.awards);

export {
  makeSelectMaternityHospitals,
  makeSelectMaternityHospitalsBasic,
  makeSelectMaternityHospital,
  makeSelectMaternityHospitalsPagination,
  makeSelectMaternityHospitalsOrderBy,
  makeSelectMaternityHospitalsLoader,
  makeSelectMaternityHospitalAwards
};
