import React from 'react';

import ReviewDetails from 'components/MaternityHospitalReviewsTable/UserReviews/ReviewDetails';

const ReviewsList = ({ reviews }) => {
  const renderList = () =>
    reviews.map(review => <ReviewDetails key={review.id} review={review} />);

  return <div>{renderList()}</div>;
};

export default ReviewsList;
