export const calculateAverage = numbers =>
  (numbers.reduce((acc, cur) => acc + cur) / numbers.length).toFixed(1);

export const calculateHospitalOverallGrade = allReviews => {
  const collectedAvgGrades = [];

  allReviews.forEach(review => {
    if (review.subcategory_reviews && review.subcategory_reviews.length) {
      const subcategoryGrades = review.subcategory_reviews.map(
        ({ grade }) => grade
      );
      collectedAvgGrades.push(parseFloat(calculateAverage(subcategoryGrades)));
    }
  });

  if (collectedAvgGrades.length) {
    return calculateAverage(collectedAvgGrades);
  }
  return '0';
};
