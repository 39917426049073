import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Field, Form, Formik } from 'formik';
import Select from 'react-select';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import messages from '../messages';
import globalMessages from 'messages';
import PhotoUpload from 'components/_shared/PhotoUpload';
import history from 'utils/history';
import TextAreaField from 'components/_shared/TextAreaField';
import { MAX_ARTICLE_PHOTOS } from 'utils/constants';
import TextInputField from 'components/_shared/TextInputField';
import { uploadMaternityHospitalPhoto } from 'store/actions/MaternityHospitalActions';
import initializeForm from './init';
import { createArticleSchema } from './validators';
import { makeSelectArticlesInitialCategory } from 'store/selectors/ArticleSelectors';
import { dispatchSetInitialArticleCategory } from 'store/actions/ArticleActions';

const CreateArticleForm = ({ onSubmit, categories, item, isUpdate }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [isPendingPhoto, setIsPendingPhoto] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const initialSelectedCategory = useSelector(
    makeSelectArticlesInitialCategory()
  );

  const addPhotoUrl = (values, setFieldValue) => url => {
    setFieldValue('photoArray', [...values.photoArray, ...url]);
    setIsPendingPhoto(false);
    const fileElem = document.getElementById('file')
    if (fileElem) {
      fileElem.value = ''
    }
  };

  const onDelete = (values, setFieldValue) => url => {
    setFieldValue(
      'photoArray',
      values.photoArray.filter(photo => photo !== url)
    );
    setIsPendingPhoto(false);
    if (values.photoArray.length < MAX_ARTICLE_PHOTOS)
      document.getElementById('file').value = '';
  };

  const handleValues = values => {
    values.category_id = selectedCategory.value;
    onSubmit(values);
  };

  const disableSubmit = isValid => {
    if (isPendingPhoto || !isValid || isEmpty(selectedCategory)) {
      if (initialSelectedCategory.value === selectedCategory.value) {
        return true;
      }
    }
    return false;
  };

  const renderHeader = isValid => (
    <header className="c-section-header is-sticky">
      <h1 className="c-section-title">
        {isUpdate
          ? formatMessage(messages.updateInfoTitle)
          : formatMessage(messages.infoTitle)}
      </h1>

      <div className="c-section-actions">
        <button
          className="c-btn c-btn--primary"
          type="submit"
          disabled={disableSubmit(isValid)}
        >
          {formatMessage(messages.saveArticleButton)}
        </button>
      </div>
    </header>
  );

  useEffect(() => {
    if (item.category.id && item.category.title && isUpdate) {
      const initialCategory = {
        label: item.category.title,
        value: item.category.id
      };
      setSelectedCategory(initialCategory);
      dispatch(dispatchSetInitialArticleCategory(initialCategory));
    } else {
      setSelectedCategory({});
    }
  }, [isUpdate, item.category.id, item.category.title]);

  return (
    <Formik
      initialValues={initializeForm(item, isUpdate)}
      validationSchema={createArticleSchema}
      onSubmit={values => handleValues(values)}
      enableReinitialize
      isInitialValid={false}
    >
      {({ isValid, values, setFieldValue }) => (
        <Form>
          {renderHeader(isValid)}
          <div className="required-field__wrap-2 u-gap-bottom">
            <span>*</span>
            <Field
              name="url"
              type="url"
              component={TextInputField}
              formatmessagemame={messages.urlLabel}
            />
          </div>
          <section className="c-grid c-grid--content-aside">
            <div className="c-category-required__field-2">
              <span>*</span>
              <Field
                name="title"
                type="text"
                component={TextInputField}
                formatmessagemame={messages.titleLabel}
              />
            </div>
            <div>
              <div className="c-form-group">
                <div>
                  <label className="c-control-label">
                    {formatMessage(messages.categorySelectLabel)}
                  </label>
                  <span>*</span>
                </div>
                <Select
                  value={selectedCategory}
                  options={categories.map(({ title, id }) => ({
                    label: title,
                    value: id
                  }))}
                  onChange={setSelectedCategory}
                  placeholder={formatMessage(
                    messages.categorySelectPlaceholder
                  )}
                />
              </div>
            </div>
          </section>
          <div className="c-form-group--required-1 u-gap-top">
            <span>*</span>
            <Field
              name="excerpt"
              component={TextAreaField}
              rows="5"
              formatmessagemame={messages.excerptLabel}
            />
          </div>
          <section className="u-gap-bottom">
            <header>
              <h2 className="c-section-subtitle">
                {formatMessage(globalMessages.photos)} (
                {values.photoArray.length}/{MAX_ARTICLE_PHOTOS})
              </h2>
            </header>
            <PhotoUpload
              photos={values.photoArray}
              limit={MAX_ARTICLE_PHOTOS}
              action={uploadMaternityHospitalPhoto}
              onDelete={onDelete(values, setFieldValue)}
              photoUrls={values.photoArray}
              addPhotoUrl={addPhotoUrl(values, setFieldValue)}
              setIsPendingPhoto={setIsPendingPhoto}
            />
          </section>

          <div className="c-form-group u-text-right">
            <button
              type="button"
              className="c-btn c-btn--primary c-btn--wide u-gap-right"
              onClick={history.goBack}
            >
              {formatMessage(globalMessages.backButton)}
            </button>
            <button
              className="c-btn c-btn--primary c-btn--wide"
              disabled={disableSubmit(isValid)}
              type="submit"
            >
              {formatMessage(messages.saveArticleButton)}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateArticleForm;

CreateArticleForm.propTypes = {
  onSubmit: PropTypes.func,
  item: PropTypes.object,
  categories: PropTypes.array
};
