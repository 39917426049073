import React from 'react';
import { Formik, Form, Field } from 'formik';
import { useIntl } from 'react-intl';
import history from 'utils/history';
import PropTypes from 'prop-types';

import { createUserSchema } from './validations';
import registerMessages from '../../RegisterPage/messages';
import globalMessages from 'messages';
import messages from '../messages';
import initializeForm from './init';
import TextInputField from 'components/_shared/TextInputField';
import { useSelector } from 'react-redux';
import { makeSelectUniqueEmailError } from 'store/selectors/UserSelectors';

export default function CreateUserForm({ onSubmit, item, isUpdate }) {
  const { formatMessage } = useIntl();
  const emailError = useSelector(makeSelectUniqueEmailError());

  return (
    <Formik
      initialValues={initializeForm(item, isUpdate)}
      enableReinitialize
      validationSchema={createUserSchema}
      onSubmit={onSubmit}
      isInitialValid={false}
    >
      {({ isValid }) => (
        <Form>
          <header className="c-section-header">
            <h2 className="c-section-title">
              {isUpdate
                ? formatMessage(messages.updateUserHeaderTitle)
                : formatMessage(messages.createUserButton)}
            </h2>
          </header>
          <div className="c-form-group c-category-required__field-6">
            <span>*</span>
            <Field
              className="c-form-control"
              type="text"
              name="first_name"
              required
              autoFocus
              component={TextInputField}
              formatmessagemame={registerMessages.firstNameInputLabel}
            />
          </div>
          <div className="c-form-group c-category-required__field-5">
            <span>*</span>
            <Field
              className="c-form-control"
              type="text"
              name="last_name"
              required
              component={TextInputField}
              formatmessagemame={registerMessages.lastNameInputField}
            />
          </div>
          <div className="c-form-group c-form-group--required">
            <span>*</span>
            <Field
              className="c-form-control"
              type="email"
              name="email"
              required
              component={TextInputField}
              formatmessagemame={registerMessages.emailInputLabel}
              autoComplete={!isUpdate ? 'false' : 'true'}
            />
            {emailError && (
              <p className="u-text-error">
                {formatMessage(messages.uniqueEmailErrorMessage)}
              </p>
            )}
          </div>
          <div className="c-form-group c-category-required__field-2">
            <Field
              className="c-form-control"
              type="password"
              name="password"
              component={TextInputField}
              formatmessagemame={registerMessages.passwordInputLabel}
              autoComplete="new-password"
            />
          </div>
          {isUpdate && (
            <div className="c-form-group c-category-required__field-7">
              <Field
                className="c-form-control"
                type="password"
                name="passwordConfirmation"
                component={TextInputField}
                formatmessagemame={messages.confirmPasswordInputLabel}
                autoComplete="new-password"
              />
            </div>
          )}
          <div className="c-form-group c-category-required__field-2">
            <Field
              className="c-form-control"
              type="text"
              name="delivery_date"
              component={TextInputField}
              formatmessagemame={registerMessages.deliveryDateInputLabel}
              placeholder="YYYY-MM-DD"
              autoComplete={!isUpdate ? 'false' : 'true'}
            />
          </div>
          <div className="c-form-group u-text-right">
            <button
              type="button"
              className="c-btn c-btn--primary c-btn--wide u-gap-right"
              onClick={history.goBack}
            >
              {formatMessage(globalMessages.backButton)}
            </button>
            <button
              className="c-btn c-btn--primary c-btn--wide"
              disabled={!isValid}
              type="submit"
            >
              {isUpdate
                ? formatMessage(messages.saveUserButton)
                : formatMessage(messages.saveUsersButton)}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

CreateUserForm.propTypes = {
  onSubmit: PropTypes.func,
  item: PropTypes.object,
  isUpdate: PropTypes.bool
};
