import { defineMessages } from 'react-intl';

export const scope = 'global';

export default defineMessages({
  email: {
    id: `${scope}.validations.email`,
    defaultMessage: '{label} must be a valid email'
  },
  required: {
    id: `${scope}.validations.required`,
    defaultMessage: '{label} is required'
  },
  url: {
    id: `${scope}.validations.url`,
    defaultMessage: '{label} must be valid URL'
  },
  oneOf: {
    id: `${scope}.validations.one_of`,
    defaultMessage: '{label} must match with {value}'
  },
  sessionExpired: {
    id: `${scope}.session_expired`,
    defaultMessage: 'Your session has expired'
  },
  confirmationModalConfirm: {
    id: `${scope}.confirmation_modal.confirm`,
    defaultMessage: 'Potvrdi'
  },
  confirmationModalClose: {
    id: `${scope}.confirmation_modal.close`,
    defaultMessage: 'Zatvori'
  },
  confirmationModalText: {
    id: `${scope}.confirmation_modal.text`,
    defaultMessage: 'Potvrdi akciju brisanja'
  },
  tableDataLoading: {
    id: `${scope}.table.loading`,
    defaultMessage: 'Učitavanje podataka...'
  },
  tableDataNoMore: {
    id: `${scope}.table.loading`,
    defaultMessage: 'Sva porodilišta su učitana'
  },
  loadingData: {
    id: `${scope}.loadingData`,
    defaultMessage: 'Učitavanje podataka...'
  },
  photos: {
    id: `${scope}.photos`,
    defaultMessage: 'Fotografije'
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Da'
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'Ne'
  },
  tooManyPhotosSelected: {
    id: `${scope}.too_many_photos`,
    defaultMessage: 'Selektovali ste veći broj fotografija od dozvoljenog'
  },
  backButton: {
    id: `${scope}.back_button`,
    defaultMessage: 'Nazad'
  },
  sortByCreatedAt: {
    id: `${scope}.sort.created_at`,
    defaultMessage: '↑ Datum dodavanja'
  },
  sortByCreatedAtDesc: {
    id: `${scope}.sort.created_at_desc`,
    defaultMessage: '↓ Datum dodavanja'
  },
  sortByMaternityHospital: {
    id: `${scope}.sort.maternity_hospital`,
    defaultMessage: '↑ Naziv'
  },
  sortByMaternityHospitalDesc: {
    id: `${scope}.sort.maternity_hospital_desc`,
    defaultMessage: '↓ Naziv'
  },
  sortByVisible: {
    id: `${scope}.sort.visible`,
    defaultMessage: 'Prvo neaktivna'
  },
  sortByVisibleDesc: {
    id: `${scope}.sort.visible_desc`,
    defaultMessage: 'Prvo aktivna'
  },
  totalLabel: {
    id: `${scope}.label.total`,
    defaultMessage: 'Ukupno: {total}'
  },
  questionHeaderTitle: {
    id: `${scope}.header.question.title`,
    defaultMessage: 'Pitanja'
  },
  hospitalHeaderTitle: {
    id: `${scope}.header.hospital.title`,
    defaultMessage: 'Porodilišta'
  },
  categoriesHeaderTitle: {
    id: `${scope}.header.categories.title`,
    defaultMessage: 'Kategorije'
  },
  articlesHeaderTitle: {
    id: `${scope}.header.articles.title`,
    defaultMessage: 'Članci'
  },
  usersHeaderTitle: {
    id: `${scope}.header.users.title`,
    defaultMessage: 'Korisnici'
  },
  adminsHeaderTitle: {
    id: `${scope}.header.admins.title`,
    defaultMessage: 'Administratori'
  },
  maternityHospitalsCategoryTitle: {
    id: `${scope}.header.maternityHospitalsCategory.title`,
    defaultMessage: 'Kategorije i potkategorije recenzija porodilišta'
  },
  errorFetchingData: {
    id: `${scope}.errorFetchingData`,
    defaultMessage: 'Došlo je do greške u dobavljanju podataka'
  },
  maternityHospitalsReviewsTitle: {
    id: `${scope}.header.maternityHosspitalReviews.title`,
    defaultMessage: 'Recenzije porodilišta'
  },
  sideBarShowBtw: {
    id: `${scope}.sidebar.showBtw`,
    defaultMessage: '>>'
  },
  sideBarHideBtw: {
    id: `${scope}.sidebar.hideBtn`,
    defaultMessage: '<<'
  },
  sideBarLogoutBtn: {
    id: `${scope}.sidebar.logoutBtn`,
    defaultMessage: 'Odjavi se'
  }
});
