import Yup from 'utils/validations';

export const createMaternityHospitalQuestionSchema = Yup.object().shape({
  question: Yup.string()
    .trim()
    .required(),
  filter_question: Yup.string().trim(),
  answer_type: Yup.string().required(),
  question_category_id: Yup.number().required()
});
