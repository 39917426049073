import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import messages from 'containers/ArticlesPage/messages';
import { ARTICLES_SINGLE } from 'routes';
import SwitchField from 'components/_shared/SwitchField';

const ArticlesTableItem = ({ item, onCheck, onItemDelete }) => {
  const { formatMessage } = useIntl();
  const [isActive, setIsActive] = useState(item.active);

  const handleActivateDeactivate = () => {
    onCheck(item.id, { active: !isActive });
    setIsActive(!isActive);
  };

  return (
    <tr>
      <td>
        <Link
          to={{
            pathname: ARTICLES_SINGLE.replace(':id', item.id)
          }}
        >
          {item.title}
        </Link>
      </td>
      <td className="u-text-center">{item.category.title}</td>
      <td>
        <SwitchField
          id={'article_' + item.id + '_active'}
          checked={isActive}
          onChange={handleActivateDeactivate}
        />
      </td>
      <td className="u-text-center">
        <button
          className="c-btn c-btn--sm"
          onClick={() => onItemDelete(item.id)}
        >
          {formatMessage(messages.deleteArticleButton)}
        </button>
      </td>
    </tr>
  );
};
export default ArticlesTableItem;
