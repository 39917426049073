import React from 'react';
import { useIntl } from 'react-intl';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';

import MaternityHospitalReviewTableItem from './MHReviewTableItem';
import messages from '../../containers/MaternityHospitalReviews/messages';
import globalMessages from 'messages';

import Loader from 'components/_shared/Loader';

const MaternityHospitalReviewsTable = ({
  reviews,
  onLoadMore,
  hasMore,
  loading,
  onItemDelete,
  selectedCategory,
  onCheck
}) => {
  const { formatMessage } = useIntl();

  const renderReviews = () =>
    reviews.map(
      review =>
        review &&
        review.user && (
          <MaternityHospitalReviewTableItem
            key={review.id}
            item={review}
            onItemDelete={onItemDelete}
            selectedCategory={selectedCategory}
            onCheck={onCheck}
          />
        )
    );

  const infiniteRef = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore,
    scrollContainer: 'parent'
  });

  return (
    <div className="c-table-scroll js-maternity-hospitals">
      <table className="c-table" ref={infiniteRef}>
        <thead>
          <tr>
            <th className="u-text-center">
              {formatMessage(messages.headerAuthor)}
            </th>
            <th className="u-text-center">
              {formatMessage(messages.headerHospital)}
            </th>
            <th className="u-text-center">
              {formatMessage(messages.headerDate)}
            </th>
            <th className="is-short u-text-center">
              {formatMessage(messages.headerActive)}
            </th>
            <th className="is-short u-text-center">
              {formatMessage(messages.actionsReviewCategory)}
            </th>
          </tr>
        </thead>
        <tbody>{renderReviews()}</tbody>
      </table>
      <Loader
        active={loading}
        message={formatMessage(globalMessages.tableDataLoading)}
        className="u-gap-vert u-text-center"
      />
    </div>
  );
};

export default MaternityHospitalReviewsTable;
