import {
  GET_MATERNITY_HOSPITALS,
  SET_MATERNITY_HOSPITALS,
  CREATE_MATERNITY_HOSPITALS,
  SET_CREATE_MATERNITY_HOSPITALS,
  SET_DELETE_MATERNITY_HOSPITAL,
  DELETE_MATERNITY_HOSPITAL,
  ACTIVATE_DEACTIVATE_MATERNITY_HOSPITAL,
  SET_ACTIVATE_DEACTIVATE_MATERNITY_HOSPITAL,
  MATERNITY_HOSPITAL_UPLOAD_PHOTO,
  MATERNITY_HOSPITAL_ADD_UPLOADED_PHOTO,
  SET_MATERNITY_HOSPITALS_ORDER_BY,
  UPDATE_MATERNITY_HOSPITAL,
  SET_UPDATE_MATERNITY_HOSPITAL,
  GET_MATERNITY_HOSPITAL,
  SET_MATERNITY_HOSPITAL,
  SET_MATERNITY_HOSPITALS_BASIC,
  GET_MATERNITY_HOSPITALS_BASIC,
  SET_MATERNITY_HOSPITALS_AT_CURRENT_PAGE,
  SET_MATERNITY_HOSPITALS_LOADER,
  MATERNITY_HOSPITAL_ADD_AWARD,
  MATERNITY_HOSPITAL_ADD_AWARD_ERROR,
  MATERNITY_HOSPITAL_ADD_AWARD_LOADING,
  MATERNITY_HOSPITAL_ADD_AWARD_SUCCESS,
  MATERNITY_HOSPITAL_REMOVE_AWARD,
  MATERNITY_HOSPITAL_REMOVE_AWARD_SUCCESS,
  MATERNITY_HOSPITAL_REMOVE_AWARD_ERROR,
  MATERNITY_HOSPITAL_REMOVE_AWARD_LOADING,
  MH_TROPHY_UPDATE_REQUEST,
  MH_TROPHY_UPDATE_SUCCESS,
  MATERNITY_HOSPITAL_SWITCH_GRADE_REQUEST,
  MATERNITY_HOSPITAL_SWITCH_GRADE_SUCCESS,
  MATERNITY_HOSPITAL_EDIT_GRADE_REQUEST,
  MATERNITY_HOSPITAL_EDIT_GRADE_SUCCESS
} from './ActionTypes';

export const getMaternityHospitals = ({ page, orderBy, searchValue }) => ({
  type: GET_MATERNITY_HOSPITALS,
  page,
  orderBy,
  searchValue
});

export const setMaternityHospitals = payload => ({
  type: SET_MATERNITY_HOSPITALS,
  payload
});

export const setMaternityHospitalsAtCurrentPage = payload => ({
  type: SET_MATERNITY_HOSPITALS_AT_CURRENT_PAGE,
  payload
});

export const getMaternityHospital = payload => ({
  type: GET_MATERNITY_HOSPITAL,
  payload
});

export const setMaternityHospital = payload => ({
  type: SET_MATERNITY_HOSPITAL,
  payload
});

export const getMaternityHospitalsBasic = payload => ({
  type: GET_MATERNITY_HOSPITALS_BASIC,
  payload
});

export const setMaternityHospitalsBasic = payload => ({
  type: SET_MATERNITY_HOSPITALS_BASIC,
  payload
});

export const createMaternityHospitals = payload => ({
  type: CREATE_MATERNITY_HOSPITALS,
  payload
});

export const setCreateMaternityHospitals = payload => ({
  type: SET_CREATE_MATERNITY_HOSPITALS,
  payload
});

export const deleteMaternityHospital = payload => ({
  type: DELETE_MATERNITY_HOSPITAL,
  payload
});

export const setDeleteMaternityHospital = payload => ({
  type: SET_DELETE_MATERNITY_HOSPITAL,
  payload
});

export const activateDeactivateMaternityHospitals = payload => ({
  type: ACTIVATE_DEACTIVATE_MATERNITY_HOSPITAL,
  payload
});

export const setActivateDeactivateMaternityHospitals = payload => ({
  type: SET_ACTIVATE_DEACTIVATE_MATERNITY_HOSPITAL,
  payload
});

export const uploadMaternityHospitalPhoto = payload => ({
  type: MATERNITY_HOSPITAL_UPLOAD_PHOTO,
  payload
});

export const addMaternityHospitalUploadedPhoto = payload => ({
  type: MATERNITY_HOSPITAL_ADD_UPLOADED_PHOTO,
  payload
});

export const setMaternityHospitalsOrderBy = payload => ({
  type: SET_MATERNITY_HOSPITALS_ORDER_BY,
  payload
});

export const updateMaternityHospital = payload => ({
  type: UPDATE_MATERNITY_HOSPITAL,
  payload
});

export const setUpdateMaternityHospital = payload => ({
  type: SET_UPDATE_MATERNITY_HOSPITAL,
  payload
});

export const setMaternityHospitalsLoader = payload => ({
  type: SET_MATERNITY_HOSPITALS_LOADER,
  payload
});

export const mhAddAward = payload => ({
  type: MATERNITY_HOSPITAL_ADD_AWARD,
  payload
});

export const mhAddAwardSuccess = payload => ({
  type: MATERNITY_HOSPITAL_ADD_AWARD_SUCCESS,
  payload
});

export const mhAddAwardError = payload => ({
  type: MATERNITY_HOSPITAL_ADD_AWARD_ERROR,
  payload
});

export const mhAddAwardLoading = payload => ({
  type: MATERNITY_HOSPITAL_ADD_AWARD_LOADING,
  payload
});

export const mhRemoveAward = payload => ({
  type: MATERNITY_HOSPITAL_REMOVE_AWARD,
  payload
});

export const mhRemoveAwardSuccess = payload => ({
  type: MATERNITY_HOSPITAL_REMOVE_AWARD_SUCCESS,
  payload
});

export const mhRemoveAwardError = payload => ({
  type: MATERNITY_HOSPITAL_REMOVE_AWARD_ERROR,
  payload
});

export const mhRemoveAwardLoading = payload => ({
  type: MATERNITY_HOSPITAL_REMOVE_AWARD_LOADING,
  payload
});

export const mhTrophyUpdateRequest = payload => ({
  type: MH_TROPHY_UPDATE_REQUEST,
  payload
});

export const mhTrophyUpdateSuccess = payload => ({
  type: MH_TROPHY_UPDATE_SUCCESS,
  payload
});

export const maternityHospitalSwitchGradeRequest = payload => ({
  type: MATERNITY_HOSPITAL_SWITCH_GRADE_REQUEST,
  payload
});

export const maternityHospitalSwitchGradeSuccess = payload => ({
  type: MATERNITY_HOSPITAL_SWITCH_GRADE_SUCCESS,
  payload
});

export const maternityHospitalEditGradeRequest = payload => ({
  type: MATERNITY_HOSPITAL_EDIT_GRADE_REQUEST,
  payload
});

export const maternityHospitalEditGradeSuccess = payload => ({
  type: MATERNITY_HOSPITAL_EDIT_GRADE_SUCCESS,
  payload
});
