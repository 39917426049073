import { defineMessages } from 'react-intl';

export const scope = 'categories';

export default defineMessages({
  saveCategoriesButton: {
    id: `${scope}.button.save`,
    defaultMessage: 'Sačuvaj'
  },
  deleteCategoryButton: {
    id: `${scope}.button.delete`,
    defaultMessage: 'Obriši'
  },
  createCategoryButton: {
    id: `${scope}.button.create`,
    defaultMessage: 'Kreiraj Kategoriju'
  },
  categoriesHeaderTitle: {
    id: `${scope}.header.title`,
    defaultMessage: 'Kategorije'
  },
  createCategoriesHeaderTitle: {
    id: `${scope}.create.header.title`,
    defaultMessage: 'Kreiranje kategorije'
  },
  tableHeaderCategories: {
    id: `${scope}.table.header.categories`,
    defaultMessage: 'Kategorija'
  },
  tableHeaderActions: {
    id: `${scope}.table.header.actions`,
    defaultMessage: 'Akcije'
  },
  searchCategoriesButton: {
    id: `${scope}.button.search`,
    defaultMessage: 'Pretraži'
  },
  sortByTitle: {
    id: `${scope}.sort.category`,
    defaultMessage: '↑ Naziv'
  },
  sortByTitleDesc: {
    id: `${scope}.sort.category_desc`,
    defaultMessage: '↓ Naziv'
  },
  sortByOrder: {
    id: `${scope}.sort.order`,
    defaultMessage: '↑ Redosled'
  },
  sortByOrderDesc: {
    id: `${scope}.sort.order_desc`,
    defaultMessage: '↓ Redosled'
  },
  titleLabel: {
    id: `${scope}.form.label.title`,
    defaultMessage: 'Naziv kategorije'
  },
  descriptionLabel: {
    id: `${scope}.form.label.about`,
    defaultMessage: 'Opis kategorije'
  },
  modalDialogText: {
    id: `${scope}.form.modal.text`,
    defaultMessage:
      'Brisanjem kategorije obrisaćete i sve članke koji pripadaju toj kategoriji.'
  },
  uniqueCategoryTitleError: {
    id: `${scope}.form.label.title`,
    defaultMessage: 'Kategorija sa tim nazivom već postoji'
  },
  updateCategory: {
    id: `${scope}.form.label.category.update`,
    defaultMessage: 'Izmeni kategoriju'
  },
  titleLengthLimitLabel: {
    id: `${scope}.form.label.titleLength`,
    defaultMessage: 'Naslov kategorije može imati najviše 150 karaktera'
  },
  descriptionLengthLimitLabel: {
    id: `${scope}.form.label.descriptionLength`,
    defaultMessage: 'Opis kategorije može imati najviše 150 karaktera'
  },
  getCategoryError: {
    id: `${scope}.error.category.get`,
    defaultMessage: 'Došlo je do greške u dobavljanju podataka'
  },
  createCategoryError: {
    id: `${scope}.error.category.create`,
    defaultMessage: 'Došlo je do greške prilikom kreiranja kategorije'
  },
  updateCategoryError: {
    id: `${scope}.error.category.update`,
    defaultMessage: 'Došlo je do greške prilikom izmene kategorije'
  },
  deleteCategoryError: {
    id: `${scope}.error.category.delete`,
    defaultMessage: 'Došlo je do greške prilikom brisanja kategorije'
  },
  reorderCategoryError: {
    id: `${scope}.error.category.reorder`,
    defaultMessage: 'Došlo je do greške prilikom izmene redosleda kategorija'
  }
});
