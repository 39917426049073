import {
  ORDER_BY_NAME,
  ORDER_BY_NAME_DESC,
  ORDER_BY_TITLE,
  ORDER_BY_TITLE_DESC,
  ORDER_BY_CREATED_AT,
  ORDER_BY_CREATED_AT_DESC,
  ORDER_BY_ACTIVE_DESC,
  ORDER_BY_ACTIVE
} from './constants';
import globalMessages from 'messages';

export const NEWS_OPTIONS = [
  {
    value: ORDER_BY_TITLE,
    text: globalMessages.sortByMaternityHospital.defaultMessage
  },
  {
    value: ORDER_BY_TITLE_DESC,
    text: globalMessages.sortByMaternityHospitalDesc.defaultMessage
  },
  {
    value: ORDER_BY_ACTIVE,
    text: globalMessages.sortByVisible.defaultMessage
  },
  {
    value: ORDER_BY_ACTIVE_DESC,
    text: globalMessages.sortByVisibleDesc.defaultMessage
  },
  {
    value: ORDER_BY_CREATED_AT,
    text: globalMessages.sortByCreatedAt.defaultMessage
  },
  {
    value: ORDER_BY_CREATED_AT_DESC,
    text: globalMessages.sortByCreatedAtDesc.defaultMessage
  }
];

export const MATERNITY_HOSPITAL_OPTIONS = [
  {
    value: ORDER_BY_NAME,
    text: globalMessages.sortByMaternityHospital.defaultMessage
  },
  {
    value: ORDER_BY_NAME_DESC,
    text: globalMessages.sortByMaternityHospitalDesc.defaultMessage
  },
  {
    value: ORDER_BY_ACTIVE,
    text: globalMessages.sortByVisible.defaultMessage
  },
  {
    value: ORDER_BY_ACTIVE_DESC,
    text: globalMessages.sortByVisibleDesc.defaultMessage
  }
];
