import produce from 'immer';
import arrayMove from 'array-move';

import { arraysAreEqual } from '../helpers';
import {
  REVIEW_CATEGORIES_GET_FAILURE,
  REVIEW_CATEGORIES_SET,
  REVIEW_CATEGORIES_LOADER,
  REVIEW_CATEGORIES_SET_AFTER_DELETE,
  REVIEW_SUBCATEGORIES_SET_AFTER_DELETE,
  REVIEW_CATEGORIES_DELETE_FAILURE,
  REVIEW_SUBCATEGORIES_DELETE_FAILURE,
  REVIEW_CATEGORY_CREATE_ERROR_SET,
  REVIEW_CATEGORIES_UPDATE_ORDER,
  REVIEW_CATEGORIES_CHANGE_ORDER_ERROR_SET,
  REVIEW_SUBCATEGORIES_CHANGE_ORDER_ERROR_SET,
  LABOR_TYPES_SET,
  REVIEW_SUBCATEGORY_CREATE_FAILURE,
  REVIEW_SUBCATEGORY_CREATE_SUCCESS,
  REVIEW_CATEGORIES_SET_SINGLE,
  REVIEW_SUBCATEGORIES_UPDATE_ORDER_SET,
  REVIEW_SUBCATEGORY_SINGLE_SET,
  REVIEW_SUBCATEGORY_SINGLE_FAILURE,
  REVIEW_SUBCATEGORY_UPDATE_SUCCESS,
  REVIEW_SUBCATEGORY_UPDATE_FAILURE,
  SET_IS_SUBCATEGORIES_CHANGED,
  SET_IS_CATEGORIES_CHANGED
} from '../actions/ActionTypes';
import { deleteRequestReviewCategoryFailure } from 'store/actions/ReviewCategoryActions';

export const initialState = {
  reviewCategories: [],
  reviewCategoriesError: false,
  loader: false,
  errorDeleteCategory: false,
  errorDeleteSubcategory: false,
  reviewCategoryCreateError: { active: false, message: '' },
  singleReviewCategory: {},
  orderChangeError: false,
  laborTypes: [],
  reviewSubcategoryCreateError: false,
  subcategoryOrderChangeError: false,
  singleReviewSubcategory: {},
  singleReviewSubcategoryFetchError: false,
  reviewSubcategoryUpdateError: false,
  isSubcategoryChanged: false,
  isCategoryChanged: false
};

/* eslint-disable default-case */
const reviewCategoriesReducer = (state = initialState, action) =>
  produce(state, draft => {
    const { type, payload } = action;
    switch (type) {
      case REVIEW_CATEGORIES_SET_SINGLE:
        draft.singleReviewCategory = payload;
        draft.isSubcategoryChanged = false;
        break;
      case SET_IS_CATEGORIES_CHANGED:
        draft.isCategoryChanged = payload;
        break;
      case SET_IS_SUBCATEGORIES_CHANGED:
        draft.isSubcategoryChanged = payload;
        break;
      case REVIEW_CATEGORIES_UPDATE_ORDER:
        const { oldIndex, newIndex } = payload;
        const tempCats = [...draft.reviewCategories];
        draft.reviewCategories = arrayMove(
          draft.reviewCategories,
          oldIndex,
          newIndex
        );
        !arraysAreEqual(tempCats, draft.reviewCategories) &&
          (draft.isCategoryChanged = true);
        break;
      case REVIEW_SUBCATEGORIES_UPDATE_ORDER_SET:
        const tempSubcats = [...draft.singleReviewCategory.reviewSubcategories];
        draft.singleReviewCategory.reviewSubcategories = arrayMove(
          draft.singleReviewCategory.reviewSubcategories,
          payload.oldIndex,
          payload.newIndex
        );
        !arraysAreEqual(
          tempSubcats,
          draft.singleReviewCategory.reviewSubcategories
        ) && (draft.isSubcategoryChanged = true);
        break;
      case REVIEW_CATEGORIES_CHANGE_ORDER_ERROR_SET:
        draft.orderChangeError = payload;
        break;
      case REVIEW_SUBCATEGORIES_CHANGE_ORDER_ERROR_SET:
        draft.subcategoryOrderChangeError = payload;
        break;
      case REVIEW_CATEGORIES_SET:
      case REVIEW_CATEGORIES_SET_AFTER_DELETE:
      case REVIEW_SUBCATEGORIES_SET_AFTER_DELETE:
      case REVIEW_SUBCATEGORY_CREATE_SUCCESS:
        draft.reviewCategories = payload.sort((a, b) =>
          a.order > b.order ? 1 : -1
        );
        draft.isCategoryChanged = false;
        break;
      case REVIEW_CATEGORIES_LOADER:
        draft.loader = payload;
        break;
      case REVIEW_CATEGORIES_GET_FAILURE:
        draft.reviewCategoriesError = payload;
        break;
      case REVIEW_CATEGORIES_DELETE_FAILURE:
        draft.errorDeleteCategory = payload;
        break;
      case REVIEW_SUBCATEGORIES_DELETE_FAILURE:
        deleteRequestReviewCategoryFailure.errorDeleteSubcategory = payload;
        break;
      case REVIEW_CATEGORY_CREATE_ERROR_SET:
        draft.reviewCategoryCreateError = payload;
        break;
      case LABOR_TYPES_SET:
        draft.laborTypes = payload;
        break;
      case REVIEW_SUBCATEGORY_CREATE_FAILURE:
        draft.reviewSubcategoryCreateError = payload;
        break;
      case REVIEW_SUBCATEGORY_SINGLE_SET:
        draft.singleReviewSubcategory = payload;
        break;
      case REVIEW_SUBCATEGORY_SINGLE_FAILURE:
        draft.singleReviewSubcategoryFetchError = payload;
        break;
      case REVIEW_SUBCATEGORY_UPDATE_FAILURE:
        draft.reviewSubcategoryUpdateError = payload;
        break;
      case REVIEW_SUBCATEGORY_UPDATE_SUCCESS:
        draft.singleReviewSubcategory = payload;
        break;
    }
  });
export default reviewCategoriesReducer;
