import React from 'react';
import { useIntl } from 'react-intl';

import TableItem from './QuestionTableItem';
import messages from 'containers/MaternityHospitalQuestionsPage/messages';
import globalMessages from 'messages';

const MaternityHospitalQuestionsTable = ({ questions, onItemDelete }) => {
  const { formatMessage } = useIntl();

  const renderItems = () =>
    questions.map(question => (
      <TableItem
        key={question.id}
        item={question}
        onItemDelete={onItemDelete}
      ></TableItem>
    ));

  return (
    <div className="c-table-scroll">
      <table className="c-table">
        <thead>
          <tr>
            <th>
              {formatMessage(messages.tableHeaderQuestion)} (
              {formatMessage(globalMessages.totalLabel, {
                total: questions.length
              })}
              )
            </th>
            <th className="is-short u-text-center">
              {formatMessage(messages.tableHeaderActive)}
            </th>
            <th className="is-short u-text-center">
              {formatMessage(messages.tableHeaderActions)}
            </th>
            <th className="is-short u-text-center">
              {formatMessage(messages.tableHeaderFilter)}
            </th>
          </tr>
        </thead>

        <tbody>{renderItems()}</tbody>
      </table>
    </div>
  );
};

export default MaternityHospitalQuestionsTable;
