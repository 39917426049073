import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import request from 'utils/request';
import { setItem } from 'utils/localStorage';
import { enqueueSnackbar } from 'store/actions/NotifierActions';
import { MATERNITY_HOSPITALS } from 'routes';
import { setToken } from 'store/actions/AppActions';
import { loginSuccess, loginError } from '../actions/LoginActions';
import messages from 'containers/LoginPage/messages';

export function* authorize({ email, password }) {
  try {
    const { accessToken: token } = yield call(request, {
      url: '/auth/login',
      method: 'post',
      data: { email, password }
    });
    yield put(loginSuccess());
    yield call(setItem, 'token', token);
    yield put(setToken(token));
    yield put(push(MATERNITY_HOSPITALS));
  } catch (error) {
    if (error.status === 401) {
      yield put(
        enqueueSnackbar({
          message: messages.unauthorized,
          options: {
            persist: false,
            preventDuplicate: true
          },
          variant: 'error'
        })
      );
    }
    yield put(loginError());
  }
}
