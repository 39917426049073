import Yup from 'utils/validations';
import messages from './messages';

export const finalizeRegistrationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(),
  password: Yup.string().required(),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    messages.passwordMatchLabel
  )
});
