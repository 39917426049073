import { parseQuestionTypeToDefaultValue } from './parseQuestionTypeToInputType';
import { BOOLEAN } from './constants';

const formatQuestionObject = (allQuestions, answeredQuestions) =>
  allQuestions.map(question => {
    const foundQuestion = answeredQuestions.find(
      item => item.id === question.id
    );
    return foundQuestion !== undefined
      ? {
          question_id: foundQuestion.id,
          answer: castQuestionAnswerByType(
            foundQuestion.answer_type,
            foundQuestion.pivot.answer
          )
        }
      : {
          question_id: question.id,
          answer: parseQuestionTypeToDefaultValue(question.answer_type)
        };
  });

const castQuestionAnswerByType = (type, answer) =>
  type === BOOLEAN ? answer !== '0' : answer || '';

export default formatQuestionObject;
