import { defineMessages } from 'react-intl';

export const scope = 'maternity_hospitals_page';

export default defineMessages({
  saveMaternityHospitalButton: {
    id: `${scope}.button.save`,
    defaultMessage: 'Sačuvaj'
  },
  deleteMaternityHospitalButton: {
    id: `${scope}.button.delete`,
    defaultMessage: 'Obriši'
  },
  createMaternityHospitalButton: {
    id: `${scope}.button.create`,
    defaultMessage: 'Kreiraj Porodilište'
  },
  maternityHospitalHeaderTitle: {
    id: `${scope}.header.title`,
    defaultMessage: 'Porodilišta'
  },
  createMaternityHospitalHeaderTitle: {
    id: `${scope}.create.header.title`,
    defaultMessage: 'Kreiranje porodilišta'
  },
  updateMaternityHospitalHeaderTitle: {
    id: `${scope}.update.header.title`,
    defaultMessage: 'Izmena porodilišta'
  },
  tableHeaderMaternityHospital: {
    id: `${scope}.table.header.maternity_hospital`,
    defaultMessage: 'Porodilište'
  },
  tableHeaderVisible: {
    id: `${scope}.table.header.visible`,
    defaultMessage: 'Prikazati'
  },
  tableHeaderActions: {
    id: `${scope}.table.header.actions`,
    defaultMessage: 'Akcije'
  },
  searchMaternityHospitalButton: {
    id: `${scope}.button.search`,
    defaultMessage: 'Pretraži'
  },
  sortByCreatedAt: {
    id: `${scope}.sort.default`,
    defaultMessage: '↑ Datum dodavanja'
  },
  sortByMaternityHospital: {
    id: `${scope}.sort.maternity_hospital`,
    defaultMessage: '↑ Naziv'
  },
  sortByMaternityHospitalDesc: {
    id: `${scope}.sort.maternity_hospital_desc`,
    defaultMessage: '↓ Naziv'
  },
  sortByVisible: {
    id: `${scope}.sort.visible`,
    defaultMessage: 'Prvo neaktivna'
  },
  sortByVisibleDesc: {
    id: `${scope}.sort.visible_desc`,
    defaultMessage: 'Prvo aktivna'
  },
  nameLabel: {
    id: `${scope}.form.label.name`,
    defaultMessage: 'Naziv porodilišta'
  },
  nameCharacterLimitLabel: {
    id: `${scope}.form.error.nameLength`,
    defaultMessage: 'Naziv porodilišta mora biti kraći od 150 karaktera'
  },
  addressLabel: {
    id: `${scope}.form.label.address`,
    defaultMessage: 'Adresa'
  },
  zipLabel: {
    id: `${scope}.form.label.zip`,
    defaultMessage: 'Poštanski broj'
  },
  zipError: {
    id: `${scope}.form.error.zip`,
    defaultMessage: 'Poštanski broj mora biti pozitivan i petocifren'
  },
  cityLabel: {
    id: `${scope}.form.label.city`,
    defaultMessage: 'Grad'
  },
  websiteLabel: {
    id: `${scope}.form.label.website`,
    defaultMessage: 'Website'
  },
  emailLabel: {
    id: `${scope}.form.label.email`,
    defaultMessage: 'Email'
  },
  phoneNumberLabel: {
    id: `${scope}.form.label.phone_number`,
    defaultMessage: 'Telefon'
  },
  motherAndBabyInfoContactNumber: {
    id: `${scope}.form.label.motherAndBabyInfoContactNumber`,
    defaultMessage: 'Kontakt telefon za informacije o porodilji i bebi'
  },
  dataValidityContactPerson: {
    id: `${scope}.form.label.dataValidityContactPerson`,
    defaultMessage: 'Osoba odgovorna za ispravnost podataka'
  },
  director: {
    id: `${scope}.form.label.director`,
    defaultMessage: 'Ime direktora'
  },
  complaintEmail: {
    id: `${scope}.form.label.complaintEmail`,
    defaultMessage: 'Email za pritužbe'
  },
  aboutLabel: {
    id: `${scope}.form.label.about`,
    defaultMessage: 'O porodilištu'
  },
  loadingQuestions: {
    id: `${scope}.form.questions.loading`,
    defaultMessage: 'Učitavanje pitanja u toku...'
  },
  infoTitle: {
    id: `${scope}.form.info.title`,
    defaultMessage: 'Osnovni podaci'
  },
  questionsTitle: {
    id: `${scope}.form.questions.title`,
    defaultMessage: 'Pitanja'
  },
  awardTitle: {
    id: `${scope}.newAward.title`,
    defaultMessage: 'Naziv nagrade'
  },
  awardHeadTitle: {
    id: `${scope}.newAward.headTitle`,
    defaultMessage: 'Nagrade'
  },
  newAwardSubmitBtn: {
    id: `${scope}.newAward.submitBtn`,
    defaultMessage: 'Dodaj'
  },
  newAwardCancelBtn: {
    id: `${scope}.newAward.cancelBtn`,
    defaultMessage: 'Otkaži'
  },
  awardYear: {
    id: `${scope}.newAward.year`,
    defaultMessage: 'Godina'
  },
  awardTitleMin: {
    id: `${scope}.newAward.title.minLength`,
    defaultMessage: 'Naziv nagrade mora imati minimum 5 karaktera.'
  },
  awardTitleMax: {
    id: `${scope}.newAward.title.maxLength`,
    defaultMessage: 'Naziv nagrade može sadržati maksimalno 50 karaktera.'
  },
  awardYearValid: {
    id: `${scope}.newAward.year.maxLength`,
    defaultMessage: 'Unesite godinu.'
  },
  addNewAwardTitle: {
    id: `${scope}.newAward.title.btn`,
    defaultMessage: 'Nova nagrada'
  },
  awardLoadingTitle: {
    id: `${scope}.awards.loading`,
    defaultMessage: 'Učitavanje'
  },
  awardsError: {
    id: `${scope}.awards.error`,
    defaultMessage: 'Došlo je do greške.'
  },
  awardDeleteBtn: {
    id: `${scope}.awards.deleteBtn`,
    defaultMessage: 'Obriši'
  },
  deleteModalText: {
    id: `${scope}.awards.deleteModal`,
    defaultMessage: 'Da li ste sigurni da želite da obrišete ovu nagradu?'
  },
  trophiesTitle: {
    id: `${scope}.form.trophies.title`,
    defaultMessage: 'Kategorije za koje su osvojeni trofeji'
  },
  trophiesSave: {
    id: `${scope}.button.trophies.title`,
    defaultMessage: 'Sačuvaj trofeje'
  },
  trophiesError: {
    id: `${scope}.form.trophies.error`,
    defaultMessage:
      'Došlo je do greške prilikom izmena. Pokušajte ponovo kasnije.'
  },
  numberOfPictures: {
    id: `${scope}.form.maternityHospital.numberOfPictures`,
    defaultMessage: 'Broj slika u galeriji'
  },
  fakeGradeLabel: {
    id: `${scope}.form.grade.label`,
    defaultMessage: 'Ocena koja će biti prikazana: '
  },
  mhGradeHeader: {
    id: `${scope}.header.grade`,
    defaultMessage: 'Prosečna ocena porodilišta: '
  },
  showDiffGrade: {
    id: `${scope}.header.showDiffGrade`,
    defaultMessage: 'Prikaži drugu ocenu: '
  },
  saveFakeGrade: {
    id: `${scope}.button.saveGrade`,
    defaultMessage: 'Sačuvaj ocenu'
  },
  minGrade: {
    id: `${scope}.grade.min`,
    defaultMessage: 'Najmanja moguća ocena je 1'
  },
  maxGrade: {
    id: `${scope}.grade.max`,
    defaultMessage: 'Najveća moguća ocena je 5'
  },
  enterGrade: {
    id: `${scope}.header.enterGrade`,
    defaultMessage: 'Unesi novu ocenu: '
  },
  gradeError: {
    id: `${scope}.form.grade.error`,
    defaultMessage:
      'Došlo je do greške prilikom izmene ocene. Pokušajte ponovo kasnije.'
  },
  gradeSwitchError: {
    id: `${scope}.form.gradeSwitch.error`,
    defaultMessage: 'Došlo je do greške. Pokušajte ponovo kasnije.'
  },
  hospitalGetError: {
    id: `${scope}.fetch.errorMessage`,
    defaultMessage: 'Došlo je do greške u dobavljanju podataka'
  },
  hospitalDeleteError: {
    id: `${scope}.delete.errorMessage`,
    defaultMessage: 'Došlo je do greške prilikom brisanja porodilišta'
  },
  hospitalCreateError: {
    id: `${scope}.create.errorMessage`,
    defaultMessage: 'Došlo je do greške prilikom kreiranja porodilišta'
  },
  hospitalUpdateError: {
    id: `${scope}.fetch.errorMessage`,
    defaultMessage: 'Došlo je do greške prilikom izmene porodilišta'
  },
  hospitalSizeSmall: {
    id: `${scope}.questions.hospitalSmall`,
    defaultMessage: 'Malo Porodilište'
  },
  hospitalSizeMedium: {
    id: `${scope}.questions.hospitalMedium`,
    defaultMessage: 'Srednje Porodilište'
  },
  hospitalSizeLarge: {
    id: `${scope}.questions.hospitalLarge`,
    defaultMessage: 'Veliko Porodilište'
  }
});
