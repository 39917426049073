import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import { useIntl } from 'react-intl';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';

import Notifier from 'containers/Notifier';
import Routes from './Routes';
import { fetchAuthenticatedUser, logout } from 'store/actions/AppActions';
import { makeSelectToken, makeSelectUser } from 'store/selectors/AppSelectors';
import AppBar from 'components/AppBar';
import 'styles/main.scss';
import AppSideBar from 'components/AppSideBar';
import messages from './messages';
import globalMessages from 'messages';
import Loader from 'components/_shared/Loader';
import {
  MATERNITY_HOSPITALS,
  CATEGORIES,
  ARTICLES,
  USERS,
  ADMINS
} from '../../routes';

function App() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const token = useSelector(makeSelectToken());
  const user = useSelector(makeSelectUser());
  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);

  useEffect(() => {
    if (token) {
      dispatch(fetchAuthenticatedUser());
    }
  }, [token, dispatch]);

  const renderLoadingIndicator = (
    <Loader
      active={true}
      message={formatMessage(globalMessages.tableDataLoading)}
      className="u-gap-vert u-text-center"
    />
  );

  const location = useLocation();

  useEffect(() => {
    const match = matchPath(location.pathname, {
      path: [
        MATERNITY_HOSPITALS,
        CATEGORIES,
        ARTICLES,
        USERS,
        ADMINS
      ],
      exact: true,
      strict: false
    });

    document.body.classList.remove('u-overflow-hidden');
    document.body.classList.remove('u-position-fixed');
    if (match) {
      document.body.classList.add('u-overflow-hidden');
      document.body.classList.add('u-position-fixed');
    }
  }, [location]);

  return (
    <HelmetProvider>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Helmet>
          <title>{formatMessage(messages.title)}</title>
        </Helmet>
        {token && !user ? (
          renderLoadingIndicator
        ) : (
          <>
            {user && (
              <>
                <AppBar />
                <AppSideBar onLogout={handleLogout} />
              </>
            )}
            <main className="l-app-content">
              <div className={user && 'u-container'}>
                <Routes />
              </div>
            </main>
          </>
        )}
        <Notifier />
      </SnackbarProvider>
    </HelmetProvider>
  );
}

export default App;
