import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Formik, Form, Field } from 'formik';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import SwitchField from 'components/_shared/SwitchField';
import TextInputField from 'components/_shared/TextInputField';
import messages from '../messages';
import { calculateHospitalOverallGrade } from '../../../utils/calculationHelpers';
import {
  maternityHospitalSwitchGradeRequest,
  maternityHospitalEditGradeRequest
} from 'store/actions/MaternityHospitalActions';
import { changeGradeSchema } from './validators';

const MaternityHospitalGradeForm = ({ item, isUpdate }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [useFakeGrade, setUseFakeGrade] = useState(false);

  const overallGrade = calculateHospitalOverallGrade(item.categoryReviews);

  const handleCheck = () => {
    dispatch(
      maternityHospitalSwitchGradeRequest({
        id: item.id,
        show_fake_grade: !useFakeGrade
      })
    );
    setUseFakeGrade(!useFakeGrade);
  };

  const updateHospitalGrade = ({ fake_grade }) => {
    dispatch(
      maternityHospitalEditGradeRequest({
        id: item.id,
        fake_grade
      })
    );
  };

  useEffect(() => setUseFakeGrade(item.showFakeGrade), [item]);

  return (
    <div>
      {!!isUpdate && !isEmpty(item) && (
        <div>
          <h2 className="c-section-subtitle">
            {formatMessage(messages.mhGradeHeader)}
            <span>{!!overallGrade && overallGrade}</span>
          </h2>
          <div>
            <p>{formatMessage(messages.showDiffGrade)}</p>
            <SwitchField
              id={item.id}
              checked={useFakeGrade}
              onChange={handleCheck}
            />
          </div>
          {!!useFakeGrade && (
            <Formik
              initialValues={{ fake_grade: item.fakeGrade || '' }}
              onSubmit={updateHospitalGrade}
              enableReinitialize
              isInitialValid={false}
              validationSchema={changeGradeSchema}
            >
              {({ isValid, isSubmitting, dirty }) => (
                <Form>
                  <Field
                    name="fake_grade"
                    type="number"
                    component={TextInputField}
                    formatmessagemame={messages.fakeGradeLabel}
                  />

                  <span></span>
                  <div className="c-form-group u-text-right">
                    <button
                      type="submit"
                      className="c-btn c-btn--primary"
                      disabled={!isValid || !dirty || isSubmitting}
                    >
                      {formatMessage(messages.saveFakeGrade)}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      )}
    </div>
  );
};

export default MaternityHospitalGradeForm;
