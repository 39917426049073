import formatQuestionObject from 'utils/formatQuestionObject';
import { parseQuestionTypeToDefaultValue } from 'utils/parseQuestionTypeToInputType';
import { DEFAULT_NUMBER_OF_PICTURES } from '../../../utils/constants';

const initializeQuestionsForm = (item, questionsData, isUpdate) =>
  isUpdate
    ? {
        id: item.id || '',
        name: item.name || '',
        address: item.address || '',
        zip: item.zip || '',
        city: item.city || '',
        phone_number: item.phoneNumber || '',
        website: item.website || '',
        email: item.email || '',
        number_of_pictures: item.numberOfPictures || DEFAULT_NUMBER_OF_PICTURES,
        mother_and_baby_info_contact_number:
          item.motherAndBabyInfoContactNumber || '',
        data_validity_contact_person: item.dataValidityContactPerson || '',
        director: item.director || '',
        complaint_email: item.complaintEmail || '',
        questions: formatQuestionObject(questionsData, item.questions),
        about: item.about || '',
        photoArray: (item.photos && item.photos.map(({ url }) => url)) || []
      }
    : {
        name: '',
        address: '',
        zip: '',
        city: '',
        phone_number: '',
        website: '',
        email: '',
        number_of_pictures: DEFAULT_NUMBER_OF_PICTURES,
        mother_and_baby_info_contact_number:
          item.motherAndBabyInfoContactNumber || '',
        data_validity_contact_person: item.dataValidityContactPerson || '',
        director: item.director || '',
        complaint_email: item.complaintEmail || '',
        questions: questionsArray(questionsData),
        about: '',
        photoArray: []
      };

const questionsArray = questionsData =>
  questionsData.map(item => ({
    question_id: item.id,
    answer: parseQuestionTypeToDefaultValue(item.answer_type)
  }));

export default initializeQuestionsForm;
